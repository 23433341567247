/*eslint-disable no-unused-vars*/
import React, { useMemo, useContext, useState } from "react";

// Components
import { Table } from "components/core/table";
import { TutorsContextProvider, TutorsContext } from "contexts/tutors"
import { SectionHeading } from "components/core/typo";
import { Skeleton } from "components/core/skeleton";
import { SummaryStatsList, SummaryStat } from "components/follow-up/meetings/summary";
import { SearchContextProvider, SearchContext } from 'contexts/search';
import { ListSearch } from "components/core/inputs";
import { Spinner } from "components/core/spinner";
import { Paginate } from "components/core/paginate";
import { FiCheck, FiTrash, FiX } from 'react-icons/fi';
import { SelectMenu } from "components/tools/select-menu";
import { Assignation } from "components/recruting/inscriptions/assignation";
import { Button } from "components/core/button";
import { Pill } from "components/core/pill";
import { Tooltip } from 'components/core/tooltip'

// Icons
import { MagnifyingGlassIcon, CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { FiMail as MailIcon } from "react-icons/fi";

// Utils
import classNames from "classnames";
import { getCurrentPeriod, getFullCurrentPeriod } from "utils/period";

// Hooks
import { useTranslation } from "react-i18next";
import { timeDay } from "d3";
import { dateParse, locale } from "utils/locale";

export const findActiveInscription = (activePeriods) => {
    const currPeriod = getCurrentPeriod();
    const inscriptions = activePeriods.filter(p => p?.slug === currPeriod);
    return inscriptions.length > 0 ? inscriptions[0] : null;
}

export function hasValue(value) {
    if (value == null) return false;
    if (value===false) return false;
    if (typeof value === 'string') return value.trim().length > 0;
    if (Array.isArray(value)) return value.length > 0;
    return true;
}

const requiredFields = [
    "contrat_engagement_document_received",
    "cyber_security_policy_document_received",
    "code_ethique_document_received",
    "antecedent_judiciaire_document_received",
    "antecedent_judiciaire_document_conform",
    "schedule_availabilities",
    "facebook_account",
    "formation_completed",
    "schools",
    "subjects"
];

const groupedFields = {
    'tutoring_type': ['online_preference', 'on_site_preference', 'hybrid_preference'],
};

export function getMissingInscriptionFields(tutor) {
    const missingFields = [];
    console.log(tutor)

    // Check grouped fields
    Object.keys(groupedFields).forEach(key => {
        console.log(key, groupedFields[key])
        if (groupedFields[key].every(field => !hasValue(tutor[field]))) {
            missingFields.push(key);
        }
    });

    // Check single fields
    requiredFields.forEach(field => {
        if (!hasValue(tutor[field])) {
            missingFields.push(field);
        }
    });

    return missingFields;
}

export function getCompletedFields(tutor) {
    const missingFields = [];

    // Check grouped fields
    Object.keys(groupedFields).forEach(key => {
        if (groupedFields[key].every(field => hasValue(tutor[field]))) {
            missingFields.push(key);
        }
    });

    // Check single fields
    requiredFields.forEach(field => {
        if (hasValue(tutor[field])) {
            missingFields.push(field);
        }
    });

    return missingFields;
}

export function isCompleted(tutor) {
    return getMissingInscriptionFields(tutor).length === 0;
}

export function InscriptionsWithContext() {
    const { t } = useTranslation("common");
    const { loading, paging, setPage, tutors, reload } = useContext(TutorsContext)
    const { filters, dispatchFilters } = useContext(SearchContext);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState([]);
    const isSelected = (tutor) => {
        if (typeof tutor === 'string') {
            return selected.map(s => s.ni).includes(tutor);
        }
        return selected.map(s => s.ni).includes(tutor.ni);
    };
    const hasAssignation = (tutor) => tutor.assigned_user !== null;
    const handleSelect = (tutor) => {
        setSelected((selected) => {
            if (isSelected(tutor)) {
                return selected.filter((s) => s.ni !== tutor.ni);
            }
            return [...selected, tutor];
        });
    };

    const headers = useMemo(() => {
        const items = [
            {
                field: (d) => d,
                headerId: "selected",
                className: "w-10",
                title: "",
                FormatComponent: (d) => <div className=''>
                    {isSelected(d) && <CheckIcon className={classNames('w-5 h-5 text-orange-500')} />}
                </div>,
                itemClassName: "!pr-0",
            },
            {
                field: (d) => d,
                title: t("name"),
                className: "",
                active: false,
                itemClassName: "py-3 !pl-0",
                FormatComponent: (d) => <div>
                    <Button color="hiddenLink" href={`/tutors/${d?.ni}`} onClick={(e)=>e?.stopPropagation()}>{d?.name}</Button>
                </div>
            },
            {
                field: (d) => d,
                title: t("completed"),
                className: "",
                active: false,
                itemClassName: "!pl-0 justify-center",
                FormatComponent: (d) => <InscriptionStatus tutor={d} />
            },
            {
                field: (d) => d,
                title: t("assigned-to"),
                className: "",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <div>
                    <span className={""}>{d?.assigned_user?.name}</span>
                </div>
            },
            {
                field: (d) => d,
                title: t("source"),
                className: "",
                active: false,
                itemClassName: "!pl-0",
                FormatComponent: (d) => <div>
                    <InscriptionSource activePeriods={d?.active_periods || []} />
                </div>
            },
            {
                field: (d) => d,
                title: t("inscription-date"),
                className: "",
                active: false,
                itemClassName: "!pl-0 ",
                FormatComponent: (d) => {
                    const inscription = findActiveInscription(d?.active_periods || []);
                    const formattedDate = inscription && new Date(inscription?.created_at).toLocaleDateString();
                    return <div>
                        <span className={"tabular-nums"}>{formattedDate}</span>
                    </div>
                }
                
            },
        ]
        return items;
    }, [tutors, selected]);
    return <>
        {/* <SectionHeading title={t("tutors")} >
            <InscriptionsStats />
        </SectionHeading> */}
        <div className="flex items-center justify-between p-3 border-b gap-2">
            <div className="flex items-center gap-2">
                <Button color="gray" size="sm" onClick={() => setSelected(tutors)}>
                    {t("search-tutors.select-all")}
                </Button>
                <Button color="delete" disabled={!selected || selected.length === 0} size="toolbarIcon" onClick={() => setSelected([])}>
                    <FiTrash />
                </Button>
            </div>
            
            <div className="flex gap-2 items-center">
                <div className="">
                    <Button color="gray" disabled={!selected || selected.length === 0} size="sm" onClick={() => setOpen(true)}>
                        {t("assignation.title")} ({selected.length})
                    </Button>
                    <Assignation 
                        values={selected} 
                        setValues={setSelected} 
                        open={open} 
                        setOpen={setOpen} 
                        onAssign={()=>reload?.()}
                    />
                </div>
                <FilterIsAssigned value={filters.assigned} setValue={(value) => dispatchFilters({ type: 'assigned', value })} />
            </div>
        </div>

        <div className="border-b relative">
            {
                loading ?
                    <Spinner className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400" /> :
                    <MagnifyingGlassIcon
                        className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
            }
            <ListSearch color="none"
                noIcons
                loading={loading}
                search={true}
                className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                placeholder={t("search-by-name")}
                onChange={(value) => dispatchFilters({ type: 'search', value })}
            />
        </div>
        <div className="@container mb-10">
            <Table
                onRowClick={handleSelect}
                rowClassName={(d)=>classNames("hover:bg-orange-50 hover:border-orange-100 group", isSelected(d) && "!bg-orange-100 border-orange-200 hover:border-orange-200", hasAssignation(d) && !isSelected(d) && "bg-gray-50 text-gray-600/50")}
                data={loading ? [] : tutors}
                headers={headers}
                indexingKey={(d) => d?.ni}
            />
            {
                loading && <div className="p-2">
                    <Skeleton className="w-full h-32" />
                </div>
            }
            {
                !loading && tutors?.length === 0 && <div className="p-2 flex justify-center text-gray-500">
                    {t("no-tutors")}
                </div>
            }
            <div className="px-4">
                {paging && <Paginate setPage={setPage} {...paging} />}
            </div>
        </div>
    </>;

}

function FilterIsAssigned({ value, setValue}) {
    const { t } = useTranslation("common");
    const values = [
        { name: t("assigned"), value: true }, 
        { name: t("not-assigned"), value: false },
        { name: t("all"), value: null },
    ];
    const findDefault = values.find(v => v.value === value);
    return <SelectMenu
        buttonClassName="!min-w-[10rem]"
        values={values}
        color="whiteOutline"
        value={value || findDefault}
        setValue={setValue}
        indexKey="name"
    />
}

function InscriptionSource({ activePeriods }) {
    const { t } = useTranslation("common");
    const inscription = findActiveInscription(activePeriods);
    const source = inscription?.source;
    // reinscription-portal
    // inscription-form
    // reinscription-form
    // system-open
    return <>
        {source && t(`inscription-sources.${source}`)}
    </>
}

export function InscriptionsStats() {
    return <>
        <SummaryStatsList>
            <SummaryStat title="Inscriptions">32</SummaryStat>
            <SummaryStat title="7 derniers jours">9</SummaryStat>
            <SummaryStat title="Non assignés">3</SummaryStat>
            <SummaryStat title="Complétés">29</SummaryStat>
        </SummaryStatsList>
    </>
}

export function InscriptionStatus({ tutor }) {
    const { t } = useTranslation("common");
    const [closest, setClosest] = useState('bottom');

    const getClosestBoundary = (e) => {
        const rect = e.target.getBoundingClientRect();
        const parentRect = e.target.parentElement?.closest('.parent-boundary')?.getBoundingClientRect();
        if (!parentRect) return;
        const top = rect.top - parentRect.top;
        const bottom = parentRect.height - top - rect.height;
        const closest = top < bottom ? 'top' : 'bottom'
        setClosest(closest);
        return closest
    }
    const missingFields = getMissingInscriptionFields(tutor || {});
    const completedFields = getCompletedFields(tutor || {});
    const missing = <>
            <div className="font-medium p-0.5 pb-1">{t("missing-info")}</div>
            <div className="p-0.5 py-0">
                {
                    missingFields.map((field, index) => { 
                        return <div key={index} className="flex items-center justify-between gap-3">
                                <span>{t(`tutor-inscription-fields.${field}`)}</span> 
                                <FiX className="text-red-500"/></div>
                    })
                }
            </div>
            <div className="font-medium p-0.5 pt-3 pb-1">{t("completed-info")}</div>
            <div className="p-0.5 py-0">
                {
                    completedFields.map((field, index) => { 
                        return <div key={index} className="flex items-center justify-between gap-3">
                        <span>{t(`tutor-inscription-fields.${field}`)}</span> 
                        <FiCheck className="text-green-500"/></div>
                    })
                }
            </div>
        </>
    return <>
        {
            missingFields.length > 0 ? <>
                <Tooltip
                    content={
                        missing
                    }
                    position="top"
                    color="light"
                    delay={0}
                    className={classNames("", closest === "top" ? "":"!-translate-y-full !-top-1" )}>
                    <div onMouseEnter={getClosestBoundary} className='relative w-fit'>
                        <BooleanPill value={missingFields?.length === 0} />
                    </div>
                </Tooltip>
            </> :
                <>
                    <BooleanPill value={missingFields?.length === 0} />
                </>
        }
    </>
}

export function BooleanPill({ value, ...props }) {
    const { t } = useTranslation("common");
    return <Pill.Empty color={value ? value==="waiting"?"yellow": "green" : "red"} {...props}>
        {
            value ? 
            value==="no"?
            <div className="flex items-center gap-0.5 min-w-11"> 
                <XMarkIcon className="w-4 h-4 shrink-0" />{t("no")}
            </div>:
            value==="waiting"?
            <div className="flex items-center gap-0.5 min-w-11 whitespace-nowrap">
                <CheckIcon className="w-4 h-4 shrink-0  " />{t("waiting")}
            </div> :
            <div className="flex items-center gap-0.5 min-w-11">
                <CheckIcon className="w-4 h-4 shrink-0" />{t("yes")}
            </div> 
            : 
            <div className="flex items-center gap-0.5 min-w-11"> 
                <XMarkIcon className="w-4 h-4 shrink-0" />{t("no")}
            </div>
        }
    </Pill.Empty>
}

export function Mails({mails}){
    const { t } = useTranslation("common");
    const [closest, setClosest] = useState('bottom');
    const getClosestBoundary = (e) => {
        const rect = e.target.getBoundingClientRect();
        const parentRect = e.target.parentElement?.closest('.parent-boundary')?.getBoundingClientRect();
        if (!parentRect) return;
        const top = rect.top - parentRect.top;
        const bottom = parentRect.height - top - rect.height;
        const closest = top < bottom ? 'top' : 'bottom'
        setClosest(closest);
        return closest
    }
    
    const { start, end } = getFullCurrentPeriod();
    
    // Filter mails based on the date range
    const filteredMails = mails.filter(mail => {
        const date = mail?.sent_at && new Date(mail?.sent_at) || new Date();
        return date >= start && date <= end;
    });
    const formatDate = (date) => date.toISOString().split('T')[0];
    // Group mails by day and type
    const groupedMails = filteredMails.reduce((acc, mail) => {
        const date = new Date(mail.sent_at);
        const day = formatDate(date);
        const typeSlug = mail.mail_type.slug;
        
        if (!acc[day]) {
            acc[day] = {};
        }
        
        if (!acc[day][typeSlug]) {
            acc[day][typeSlug] = mail;
        } else {
            const existingMailDate = new Date(acc[day][typeSlug].sent_at);
            const currentMailDate = new Date(mail.sent_at);
            if (currentMailDate > existingMailDate) {
                acc[day][typeSlug] = mail;
            }
        }
        
        return acc;
    }, {});
    const latestMailsByDay = Object.entries(groupedMails).reduce((acc, [day, types]) => {
        acc[day] = Object.values(types);
        return acc;
    }, {});
    const days = Object.keys(latestMailsByDay).reverse();
    const hasEntries = days.length > 0;
    const button = <>
        <Pill.Empty  className={classNames(!hasEntries && "opacity-40")}>
            <MailIcon className="size-5 text-gray-500 shrink-0" /> <span className={classNames("ml-1")}>{mails.length}</span>
        </Pill.Empty>
    </>
    return <>
        {
            !days.length ? <div className="flex gap-1.5 items-center"> 
                {button}
            </div> :<>
                <Tooltip
                    content={
                        <div className="p-0.5  overflow-auto">
                            <span className="text-lg pb-3 font-medium">{t("emails")}</span>
                            {
                                days.map((day, index) => {
                                    return <div key={index} className="pb-3">
                                        <div className="flex items-center justify-between gap-3">
                                                <span className="font-medium">{locale.format("%d %B %Y")(dateParse(day))} </span>
                                                <span className="ml-auto text-gray-500">{t("num-day", {count: timeDay.count(dateParse(day), new Date())})}</span>
                                        </div>
                                        {
                                            latestMailsByDay[day].map((mail, index) => {
                                                return <div key={index} className="flex flex-col  ml-1">
                                                    <div className="text-gray-500 ml-2">{mail.mail_type.name}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                })
                            }
                        </div>
                    }
                    position="top"
                    color="light"
                    delay={0}
                    className={classNames("", closest === "top" ? "":"!-translate-y-full !-top-1" )}>
                    <div onMouseEnter={getClosestBoundary} className='relative w-fit text-sm flex items-center gap-1.5'>
                        {button}
                    </div>
                </Tooltip>
            </>
        }
    </>
}

export function Inscriptions() {
    const period = getCurrentPeriod();
    return <SearchContextProvider doNotStore defaultState={{ assigned: false, orderBy: "assigned_user", orderDirection:"desc", period}}>
        <TutorsContextProvider 
            fields={[
                "assigned_user", 
                "active_periods", 
                "active_since",
                "contrat_engagement_document_received",
                "cyber_security_policy_document_received",
                "code_ethique_document_received",
                "antecedent_judiciaire_document_received",
                "schedule_availabilities",
                "online_preference",
                "on_site_preference",
                "hybrid_preference",
                "facebook_account",
                "formation_completed",
                "schools",
                "subjects",
            ]}>
            <InscriptionsWithContext />
        </TutorsContextProvider>
    </SearchContextProvider>
}
