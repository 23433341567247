import { fetchCall } from 'api';

/*
  Update preset
*/  
export const updatePreset = ({id, name, user, core}) => {
  const options = {
    method: "POST", 
    endpoint: `/presets/${id}`,
    body: {name, user: user && user.email, core}
  }
  return fetchCall(options)
};

/*
  Retrieve preset
*/  
export const retrievePreset = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/presets/${id}`
  }
  return fetchCall(options)
};


/*
  Delete preset
*/  
export const deletePreset = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/presets/${id}`
  }
  return fetchCall(options)
};


/*
  Create preset
*/  
export const createPreset = ({name, user, teams}) => {
  const options = {
    method: "POST", 
    endpoint: `/presets`,
    body: {name, user: user && user.email, teams: teams && teams.map(d=>d.slug)}
  }
  return fetchCall(options)
};

/*
  Add/remove team from preset
*/  
export const toggleTeamFromPreset = ({id, team}) => {
  const options = {
    method: "POST", 
    endpoint: `/presets/${id}/toggle-team`,
    body: {team: team && team.slug}
  }
  return fetchCall(options)
};

/*
  Search presets
*/  
export const searchPresets = ({search, user, page, limit, orderBy, orderDirection}) => {
  const options = {
    method: "GET", 
    endpoint: "/presets",
    query: {search, target:user, page, limit, orderBy: orderBy || "name", orderDirection: orderDirection || "desc"}
  }
  return fetchCall(options)
};