import React, { useCallback } from 'react';

// Components
import { Button } from 'components/core/button';
import { SearchTeams } from 'components/tools/search_teams';
import { ModifiableSelectField, SingleLineField } from 'components/tools/modifiable_fields';
import { SelectDateInterval, SelectDate } from 'components/tools/select_date';
import { SelectEventType } from 'components/tools/select_event_type';
import { Error, InfoSectionHeading, RawTextInfo } from 'components/core/typo'

// API
import { createEvent } from 'api/events';

// Hooks
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { timeMonday, timeDay } from 'd3-time';
import { locale } from 'utils/locale';
import { addOrRemoveItem } from 'utils/array';
import { CardContainer } from 'components/core/container';
import { CardSectionHeading } from 'components/core/typo';
import { Info } from 'components/core/info';
import { stringIsInteger } from 'utils/var_check';
import { FiCheck, FiEyeOff } from 'react-icons/fi';
import classnames from 'classnames';

export function SelectExcludedDates({value, setValue, evaluationPeriod, disabled}){
  if (!evaluationPeriod || !evaluationPeriod.fromDate || !evaluationPeriod.toDate){
    // Show empty
    return <div></div> 
  }
  return <div className="grid grid-cols-2 gap-3">
          {timeMonday.range(timeMonday.floor(evaluationPeriod.fromDate), evaluationPeriod.toDate).map(d=>{
            const active = (value || []).includes(locale.format("%x")(d));
            return <Button color={active? (disabled? "contrastWhiteGrayInactive": "contrastWhiteGrayInactive"): "active"}
              onClick={disabled? null: ()=>setValue(addOrRemoveItem(value, locale.format("%x")(d)))}
              size="md"
              key={locale.format("%x")(d)}>
                {active? <FiEyeOff className='mr-2'/>:<FiCheck className='mr-2'/>}
                {locale.format("%d %B")(d)} au {locale.format("%d %B")(timeDay.offset(d, 6))}
            </Button>}
          )}
        </div>
}

export function NewEvent({defaultTeam, disabled, setValue}){
  const { t } = useTranslation('common');
  const [team, setTeam] = useState(defaultTeam);
  const [date, setDate] = useState();
  const [numMatches, setNumMatches] = useState(1); //

  const [visitingTeam, setVisitingTeam] = useState();
  const [receivingTeam, setReceivingTeam] = useState();
  const [notes, setNotes] = useState();
  const [eventType, setEventType] = useState();
  const [evaluationPeriod, setEvaluationPeriod] = useState();
  const [excludedDates, setExcludedDates] = useState([]);

  const params = {team: team && team.slug, 
                  date,
                  numMatches,
                  evaluationPeriod,
                  excludedDates,
                  visitingTeam,
                  receivingTeam,
                  notes,
                  eventType: eventType && eventType.slug}
  const [result, {setResult, loading, error, execute}] = useAPI(createEvent, params, {immediate: false});
  useEffect(()=>{
    if (result && setValue){
      setValue(result);
    }
  }, [result]);

  const reset = useCallback(()=>{
    setTeam(defaultTeam);
    setDate();
    setNumMatches(1);
    setVisitingTeam();
    setReceivingTeam();
    setNotes();
    setEventType();
    setEvaluationPeriod();
    setExcludedDates([]);
    setResult();
  }, [defaultTeam]);

  if (result){
    return <RawTextInfo>{t("event-added")}
    <br/>
    <Button onClick={reset} size="md" color="info">{t("add-another-event")}</Button>
    </RawTextInfo>
  }
  return <> 

          <ModifiableSelectField 
                  Field={SearchTeams} 
                  disabledClear
                  label={t("team")} 
                  modify={true} 
                  value={team}
                  disabled={defaultTeam}
                  setValue={setTeam} />
            <ModifiableSelectField 
              Field={SelectDate} 
              label={t("date")} 
              includeTime={true}
              modify={true} 
              value={date} 
              setValue={setDate}/>
            <ModifiableSelectField 
              Field={SelectEventType} 
              label={t("event-type")} 
              modify={true} 
              noBorder={true}
              className="!grid-cols-1"
              value={eventType} 
              setValue={(value)=>{setEventType(value); setNumMatches(1) }}/>

            {eventType && eventType.slug==="tournois" &&
            <Info.Container modify={false} className={"p-3"}>
            <SingleLineField
                label={t("num-matches.label")}
                description={t("num-matches.help")}
                modify={true}
                inputProps={{type: "number", max:"20", min:"1", step:"1", validateUpdate: stringIsInteger}}
                showSavingState={false}
                value={numMatches}
                setValue={setNumMatches}/>
            </Info.Container>
            }

            <Info.Container modify={true} className={"pb-8"}>
            <InfoSectionHeading mediumTitle={t("play-time")} className={"mt-8 "}/>
            <ModifiableSelectField 
              Field={SelectDateInterval} 
              events={[{date, color: "#de2d26"}]}
              label={t("evaluation-period")} 
              modify={true} 
              value={evaluationPeriod} 
              setValue={setEvaluationPeriod}/>

          <ModifiableSelectField 
              Field={SelectExcludedDates} 
              events={[{date, color: "#de2d26"}]}
              label={t("excluded-dates")} 
              subLabel={t("excluded-dates-description")}
              evaluationPeriod={evaluationPeriod}
              modify={true} 
              block
              value={excludedDates} 
              setValue={setExcludedDates}/>
            </Info.Container>
          
            <InfoSectionHeading mediumTitle={t("note-on-event")} className={"mt-8"}/>
          <Info.Container className={classnames("my-6 space-y-2")} modify={true}>
            <SingleLineField 
                        label={true&& t("visiting-team")} 
                        modify={true} 
                        value={visitingTeam} 
                      showSavingState={false}
                      setValue={setVisitingTeam}>
                {(d)=><Info.Field value={d} noValueLabel={t("no-visiting-team")} label={t("visiting-team")}/>}
            </SingleLineField>
            <SingleLineField 
                        label={true&& t("receiving-team")} 
                        modify={true} 
                      showSavingState={false}
                      value={receivingTeam} 
                        setValue={setReceivingTeam}>
              {d=><Info.Field value={d} noValueLabel={t("no-receiving-team")} label={t("receiving-team")}/>}
            </SingleLineField>
          <SingleLineField 
                      label={true&& t("comments")} 
                      modify={true} 
                      value={notes} 
                      setValue={setNotes}
                      showSavingState={false}
                      multiLine>
                {(d)=><Info.Field value={d} noValueLabel={t("no-comments")} label={t("comments")}/>}
            </SingleLineField>

          </Info.Container>

          <Button disabled={disabled || !team || !date || !eventType || !evaluationPeriod || !evaluationPeriod.fromDate || !evaluationPeriod.toDate } color="active" size="lg" block loading={loading} onClick={execute}>
            {t('add-event')}
          </Button>
          {error? <Error.Text {...error}/>: null}
         </>
}

export function NewEventSlideOver(props){
  const { t } = useTranslation('common');

  return <div className="flex-1 h-full overflow-hidden flex flex-col">
          <CardContainer className="mb-6">
            <CardSectionHeading 
                title={<span className="flex items-center"> 
                        <div>{t("add-event")}</div></span>} />
          </CardContainer>
          <CardContainer className="mb-6">
            <NewEvent {...props}/>
          </CardContainer>
        </div>
}
