import React from 'react';
import classnames from 'classnames';
import { Button } from 'components/core/button';
import { FiLock, FiEyeOff } from 'react-icons/fi';
import { useState } from 'react'
import { useTranslation } from 'react-i18next';

export function InfoField({ value, noValueLabel, label, description, to, children }){
  return <div className={classnames("py-2.5 px-3 break-all", !value&& "text-gray-400")}>
              {value?
                to?
                <Button color="inlineLink" href={to}>{value}</Button> :
                value
                : noValueLabel}
              {value&&label&&<p className="text-xs text-gray-600">{label}</p>}
              {description&&<p className=" text-gray-600">{description}</p>}
              {children}
        </div>
}

export function InfoContainer({label, largeTitle, description, children, modify, sideHeader, containerClassName, className, blurred}){
  const {t} = useTranslation('common');
  const [blur, setBlur] = useState(blurred);

  return  <div className={classnames(containerClassName)}>
            <div className="flex items-center justify-between">
              <div>
                {largeTitle && <h1 className="text-2xl mx-2 font-semibold    text-gray-800">{largeTitle}</h1>}
                {label && <label className="mx-2 text-sm font-medium text-gray-800">{label}</label>}
                {description && <p className="mx-2 text-sm text-gray-500">{description}</p>}
              </div>
              {blurred && !blur &&
                <Button color="default" size="xs" onClick={()=>setBlur(true)}>
                  <FiEyeOff className="flex-shrink-0 mr-2"/> {t("hide-blurred-content")}
                </Button>
              }
              {sideHeader
              }
            </div>
            <div className={classnames("mt-1 rounded-lg", modify?"space-y-4 ":" bg-gray-100 divide-y divide-white", className)}>
              {blur?
                <Button color="default" size="lg" block onClick={()=>setBlur(false)}>
                  <FiLock className="mr-2"/>{t("show-blurred-content")}
                </Button>:
                children
              }
            </div>
          </div>
}

export const Info = { Field: InfoField, Container: InfoContainer};