import React from "react";

// Components
import { Dropdown } from "components/core/dropdown";
import { Button } from "components/core/button";

// Icons
import { FiBookOpen as BookIcon } from "react-icons/fi";

export function ClassFailed({student, loading}) {
    const classFailed = student?.classFailed || [];
    const nbFailed = classFailed?.length || 0;
    if (loading) return <></>
    if (!nbFailed) return <div className="px-2.5 text-gray-300">0</div>
    return <>
        <Dropdown vOrientation="up" menuItemsClassName="!-translate-y-[32px] translate-x-[30px] bottom-0 right-full" itemClassName="w-[200px]" iconClassName="!p-0.5 !px-2.5 !text-base !rounded-md" onlyIcon={<div>{nbFailed}</div>} >
            <Dropdown.Info className="!px-1">
                <Button color="cardNav" href={`/students/${student?.ni}/settings/class-failed`} className={"p-2 w-full rounded"}>
                    <div className="flex gap-1 text-gray-500">
                        <BookIcon className='mt-1 shrink-0'/>
                            <div className="text-sm flex gap-1">{classFailed.map(failed => failed?.schoolClass?.name).join(", ")}</div>
                    </div>
                </Button>
            </Dropdown.Info>
        </Dropdown>
    </>
}