import { fetchCall } from 'api';
import { isBool, isString } from 'utils/var_check';

/*
  Create a draft pairing
*/
export const draftPairing = ({ student, tutor }) => {
    const options = {
        method: "POST",
        endpoint: "/draft-pairing",
        body: {
            student,
            tutor
        }
    }
    return fetchCall(options)
};


/*
  Create a pairing
*/
export function createPairing({ student,
    tutor,
    studentConfirmed,
    tutorConfirmed,
    closeCurrentTutorPairing,
    closeCurrentStudentPairing,
    startedAt,
    endedAt,
    subjects,
    preferedType,
    period }) {
    const options = {
        method: "POST",
        endpoint: "/pairings",
        body: {
            student: student && (isString(student) ? student : student.ni),
            tutor: tutor && (isString(tutor) ? tutor : tutor.ni),
            studentConfirmed,
            tutorConfirmed,
            startedAt,
            endedAt,
            subjects: subjects && subjects.map(d => d.slug),
            preferedType: preferedType && (isString(preferedType) ? preferedType : preferedType.code),
            period,
            closeCurrentTutorPairing,
            closeCurrentStudentPairing
        }
    }
    return fetchCall(options)
}


/*
  Delete a pairing
*/
export const deletePairing = ({ id }) => {
    const options = {
        method: "DELETE",
        endpoint: `/pairings/${id}`,
    }
    return fetchCall(options)
};

/*
  Update a pairing
*/
export const updatePairing = ({ id, period, confirmed, subjects, preferedType, reasonForEnd, flag, startedAt, endedAt, doNotPairAgain, studentConfirmed, tutorConfirmed, assignedUser }) => {
    const options = {
        method: "POST",
        endpoint: `/pairings/${id}`,
        body: {
            period, preferedType, startedAt, endedAt, reasonForEnd, flag, confirmed,
            subjects: subjects && subjects.map(d => d.slug),
            studentConfirmed, tutorConfirmed, doNotPairAgain,
            assignedUser: typeof assignedUser === "string" ? assignedUser : assignedUser?.email
        }
    }
    return fetchCall(options)
};

/*
  Retrieve a pairing
*/
export const retrievePairing = ({ id }) => {
    const options = {
        method: "GET",
        endpoint: `/pairings/${id}`,
    }
    return fetchCall(options)
};


/*
  Search pairings
*/
export const searchPairings = ({ search, period, preset, confirmed, team, school, serviceIsEnded, student, fields, triade, tutor, page, active, activeSinceFrom, activeSinceTo, assignedTo, limit, orderBy, orderDirection }) => {
    const options = {
        method: "GET",
        endpoint: `/pairings`,
        query: {
            search,
            student,
            tutor,
            fields,
            confirmed: confirmed && (isBool(confirmed) ? confirmed : confirmed.value),
            active: active && (isBool(active) ? active : active.value),
            activePeriod: period && (isString(period) ? period : period.slug),
            serviceIsEnded: serviceIsEnded && (isBool(serviceIsEnded) ? serviceIsEnded : serviceIsEnded.value),
            activeSinceFrom: activeSinceFrom && activeSinceFrom.toISOString(),
            activeSinceTo: activeSinceTo && activeSinceTo.toISOString(),
            assignedTo: assignedTo && typeof assignedTo === "string" ? assignedTo : assignedTo?.email,
            preset: preset && preset.id,
            school: typeof school === "string" ? school : school?.slug,
            team: team && team.slug,
            triade,
            page,
            limit,
            orderBy,
            orderDirection
        }
    }
    return fetchCall(options)
};

/*
  Close all active pairings
*/
export const closeAllActivePairings = ({ endedAt, reason }) => {
    const options = {
        method: "POST",
        endpoint: "/close-all-pairings",
        body: { endedAt: endedAt.toISOString().slice(0, 10), reason }
    }
    return fetchCall(options)
};

/*
    Create a pairing group
*/
export const createPairingGroup = ({ pairings }) => {

    const options = {
        method: "POST",
        endpoint: "/pairing-groups",
        body: {
            pairings: pairings.map(d => typeof d === "string" ? d : d.id)
        }
    }
    return fetchCall(options)
}

/*
    Retrieve a pairing group
*/
export const retrievePairingGroup = ({ groupId, fields }) => {

    const options = {
        method: "GET",
        endpoint: `/pairing-groups/${groupId}`,
        query: {
            fields
        }
    }
    return fetchCall(options)
}

/*
    Retrieve the group of a specific pairing
*/
export const retrievePairingGroupWithPairingId = ({ pairingId, fields }) => {

    const options = {
        method: "GET",
        endpoint: `/pairings/${pairingId}/group`,
        query: {
            fields
        }
    }
    return fetchCall(options)
}

/*
    Delete a pairing group
*/
export const deletePairingGroup = ({ groupId }) => {

    const options = {
        method: "DELETE",
        endpoint: `/pairing-groups/${groupId}`
    }
    return fetchCall(options)
}

/*
    Update a pairing group
*/
export const updatePairingGroup = ({ group, pairingId }) => {
    const options = {
        method: "POST",
        endpoint: `/update-pairing-group/${pairingId}`,
        body: {
            group
        }
    }
    return fetchCall(options)
}
