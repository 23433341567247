import React from 'react';

// Components
import { Fragment, useContext } from 'react'
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react'
import { FiCheck, FiChevronDown } from 'react-icons/fi';

import classnames from 'classnames';

import { ModalIsOpenContext, ModalIsOpenContextProvider } from 'contexts/modalIsOpen';
import { Tooltip } from './tooltip';

const colors = {
  danger: 'text-red-600 hover:text-white hover:bg-red-500 ',
  warning: 'text-yellow-600',
  default: 'text-gray-700',
  white: 'bg-white',
  lightGray: 'bg-gray-50',
  active: 'bg-gray-100 text-gray-900',
}

const styles = {
  inactive: 'bg-gray-100 text-gray-500',
  selected: 'bg-gray-100 text-gray-900',
  white: 'bg-white hover:bg-gray-100 text-gray-600 hover:text-gray-800 border',
  dark: 'bg-gray-800 text-gray-300 hover:text-gray-200 hover:bg-gray-700',
  default: 'bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200',
  defaultDarker: 'bg-gray-200 text-gray-600 hover:text-gray-800 hover:bg-gray-300',
  activeColor: 'bg-orange-100 text-orange-900  hover:text-gray-800 hover:bg-gray-200'
}
function ExternalLink(props){
  return <a href={props.to} {...props}/>
}
export function DropdownItem({ name, description, color='default', className, externalLink, href, icon, checked, onClick, disabled }){
  const LinkComponent = href && (externalLink? ExternalLink: Link);
  return <Menu.Item>
            {({ active }) => (
              href?
              <LinkComponent
                to={href}
                className={classnames(
                  disabled&& 'bg-gray-50 text-gray-500 cursor-not-allowed', active && colors.active, colors[color],
                  'block mx-1 px-2 rounded-md my-1 py-2 text-sm flex items-center ',
                  className
                )}
              >
                {icon? icon: null}
                <div className={icon? "pl-3":""}>
                  {name}
                  <span className="block text-sm text-gray-400">{description}</span>
                </div>
              </LinkComponent>:
              <button
                onClick={disabled? (()=>{}): onClick? onClick: (()=>{})}
                className={classnames(
                  disabled && 'bg-gray-50 text-gray-500 cursor-not-allowed',  active && colors.active, colors[color],
                  'px-3 rounded-md my-1 py-2 text-sm flex items-center w-full text-left ',
                  className
                )}
              >
                {icon? icon: checked? <FiCheck/>: null}
                <div className={icon? "pl-3":""}>
                  {name}
                  <span className="block text-sm opacity-80">{description}</span>
                </div>
              </button>
            )}
          </Menu.Item>
}

export function DropdownBorder({ className }){
  return <Menu.Item className={className}>
            <hr/>
          </Menu.Item>
}
const sizes = {
  "sm": "text-sm",
  "md": "text-md",
  "lg": "text-lg",
}

export function DropdownInfo({title, description, size="sm", children, className}){
  return <Menu.Item className={classnames("px-4 py-2", sizes[size], className)}>
            <div>
              <div>{title}</div>
              {description?<div className="text-gray-500 text-sm">{description}</div>:null}
              {children}
            </div>
          </Menu.Item>
}

export function DropdownWithoutContext({ children,
                                          label,
                                        onlyIcon,
                                        customButton,
                                        customButtonClassName,
                                        iconClassName,
                                        withChevron,
                                        disabled,
                                        orientation,
                                        tooltip,
                                        itemClassName,
                                        labelWithIcon,
                                        itemStyle,
                                        color='default',
                                        vOrientation="down",
                                        menuClassName,
                                        menuItemsClassName,
                                        menuButtonClassName}) {
  const context = useContext(ModalIsOpenContext);

  return (
    <Menu as="div" className={classnames("relative text-left",  menuClassName)}>
      {({ open }) => (
        <>
          <div>
            {customButton?
              <Menu.Button className={customButtonClassName}>
                {customButton}
                {withChevron? <FiChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" /> : null}
              </Menu.Button>:
              label?
              <Menu.Button className={classnames("inline-flex items-center text-sm justify-center w-full rounded-md  hover:bg-gray-200 px-4 py-2  font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-orange-500", styles[color], menuButtonClassName)}>
                {label}
                <FiChevronDown className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
              </Menu.Button>:
              <Menu.Button disabled={disabled} className={classnames(styles[color], iconClassName, "disabled:opacity-30 flex shadow-none border-0 transition-all duration-100 outline-none rounded-lg text-xl p-2")}>
                <span className="sr-only">Open options</span>
                {tooltip?
                <Tooltip content={tooltip}>
                  <span className='relative'>{onlyIcon}</span>
                </Tooltip>:
                onlyIcon
                }
                {labelWithIcon? <span className="ml-2 text-sm">{labelWithIcon}</span>: null}
               {withChevron? <FiChevronDown className="w-5 h-5 ml-0 -mr-1" aria-hidden="true" />:null}
              </Menu.Button >
            }
          </div>
          <Transition
            show={context.open && !disabled? true: disabled? false: open}
            as={Fragment}
            enter={"transition ease-out duration-100"}
            enterFrom={orientation==="center"? "opacity-0 scale-95 ":"transform opacity-0 scale-95"}
            enterTo={orientation==="center"? "opacity-100 scale-100 ":"transform opacity-100 scale-100"}
            leave={"transition ease-in duration-75"}
            leaveFrom={orientation==="center"? " opacity-100 scale-100 ":"transform opacity-100 scale-100"}
            leaveTo={orientation==="center"? " opacity-0 scale-95 ":"transform opacity-0 scale-95"}
          >
            <Menu.Items
              unmount={false}
              className={classnames("z-50 max-w-screen px-1 absolute mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
                     orientation==='left'? 'left-0': (orientation==='center'? 'left-1/2 transform -translate-x-1/2': 'right-0'), 
                     vOrientation==="up"?"-translate-y-[calc(100%+56px)] origin-bottom-right ": " origin-top-right", menuItemsClassName)}
            >
              <div className={itemClassName} style={itemStyle}>
                {children}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export function Dropdown({...props}){
  return <ModalIsOpenContextProvider><DropdownWithoutContext {...props}/></ModalIsOpenContextProvider>;
}

Dropdown.Item = DropdownItem;
Dropdown.Border = DropdownBorder;
Dropdown.Info = DropdownInfo;
