import React, { useContext } from 'react';

// Components
import { CgComment } from 'react-icons/cg';
import { AddCommentTypes, CommentTypesList } from 'components/notes/comment-types';

// API
import { searchCommentTypes } from 'api/notes';

// Hooks
import { useTranslation } from 'react-i18next';
import { useSearch } from 'hooks/useSearch';
import { SearchContext, SearchContextProvider } from 'contexts/search';

function SettingsCommentTypes(){
  const {t} = useTranslation("common");

  const {filters} = useContext(SearchContext)

  const [types, {setResult}] = useSearch(searchCommentTypes, filters);

  return <div className="h-full min-h-screen md:min-h-0 ">
            <h1 className="info-section">{t('comment-types.title')}</h1>
            <AddCommentTypes setTypesList={setResult}/>
            <CommentTypesList typesList={types} setTypesList={setResult}/>
          </div>
}

function SettingsCommentTypesWithContext(){

  return  <SearchContextProvider doNotStore defaultState={{orderBy: "", orderDirection: ""}}>
            <SettingsCommentTypes/>
          </SearchContextProvider>
}
const route =  { path: "comments",
                 name: "Types de commentaires" ,
                 group: "data",
                 Icon: CgComment,
                 requireAuth: true,
                 scopes: ["Direction", "RST", "RSI", "Développeur"],
                 Element: SettingsCommentTypesWithContext };
export default route;
