import React from 'react';

// Components
import { Availabilities } from 'components/pairings/schedules'
import { PairingInfo } from 'components/pairings/info'
import { TutoringGrid } from 'components/tutoring-follow-up/tutoring_grid';
import { FullFlexNav } from 'components/core/layouts/flex';
import { Container } from 'components/core/container';

// Hooks
import { useContext } from 'react';

// Contexts
import {PairingContext} from 'contexts/pairing';


export default function Overview(){
  const {pairing} = useContext(PairingContext);
  if (!pairing.student){
    return <div className="md:flex h-full w-full">
          </div>
  }
  return <div className="py-6">
          <Container >
            <TutoringGrid studyCode="tutorat" numWeeks={4} pairing={pairing} showTotalInCards={true}/>
          </Container>
          <FullFlexNav className="p-6 block xl:flex">
          <FullFlexNav.Bar className="flex-shrink-0">
            <PairingInfo/>

          </FullFlexNav.Bar>
          <FullFlexNav.Content>
           <Availabilities student={pairing.student} 
                tutor={pairing.tutor} 
                period={pairing.period}
                commonAvailabilities={pairing.common_availability} 
                studentAvailabilities={pairing.student.schedule_availabilities} 
                tutorAvailabilities={pairing.tutor.schedule_availabilities}/>
          </FullFlexNav.Content>
         </FullFlexNav>
         </div>
}
