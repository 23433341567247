import React from 'react';
// Components
import { SubjectsList } from 'components/settings/subjects/list'
import { ClassesList } from 'components/settings/classes/list'
import { FiBookmark } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';

function SettingsSchoolSubjects(){
  const {t} = useTranslation("common");
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('school-subjects')}</h1>
              <div className="space-y-8">
                <SubjectsList/>
                <ClassesList/>
            </div>
          </div>
}
const route =  { path: "school-subjects", 
                 name: "Matières scolaires" , 
                 group: "data",
                 Icon: FiBookmark,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "RSI", "Développeur"],
                 Element: SettingsSchoolSubjects };
export default route;
 