import React, { useEffect } from 'react';

// Modules
import ReactQuill from 'react-quill';
import CustomToolbar from "./customtoolbar";

// CSS
import 'styles/editor-style.css';
import { Button } from '../button';
import { FiMoreHorizontal } from 'react-icons/fi';
import classNames from 'classnames';

export function Editor({ text, 
    setText, 
        placeholder = "", label="", editorStyles = "", name = "", id = "", minHeight = "", ...props }) {

    const handleChange = (html) => { setText(html) };
    const [toolbarOpen, setToolbarOpen] = React.useState(false);
    if (minHeight != "") {
        let root = document.documentElement;
        root.style.setProperty('--min-editor-height', minHeight);
    }

    let qlLink = document.querySelector(".ql-link");

    let qlLinkEdit = document.querySelector(".ql-tooltip[data-mode=link] .ql-action");

    useEffect(() => {
        const linkPlaceholder = "https://www.exemple.com";
        function addEventSetCustomPlaceholder(element) {
            if (element) {
                element.addEventListener("click", () => {
                    let linkTooltip = document.querySelector(".ql-tooltip[data-mode=link] input[type=text]");
                    if (linkTooltip) {
                        linkTooltip.setAttribute("placeholder", linkPlaceholder);
                    }
                })
            }
        }
        addEventSetCustomPlaceholder(qlLink);
        addEventSetCustomPlaceholder(qlLinkEdit);
    }, [qlLink, qlLinkEdit]);

    const modules = {
        toolbar: { container: "#toolbar" }
    }

    
    return (
        <>
            <label className='block text-sm font-medium text-gray-700'>{label}</label>
            
            <div id='toolbar' className={classNames()}>
                <div className={classNames(!toolbarOpen?"hidden": "p-2 !rounded-md")}>
                    <CustomToolbar />
                </div>
            </div>
            {toolbarOpen && <div className='h-1 bg-white'/>}

            <div className='flex gap-3'>
                <ReactQuill
                    id={id}
                    {...props}
                    name={name}
                    placeholder={placeholder}
                    className={`flex-1 ${editorStyles} ${minHeight != "" ? "min-h-editor" : ""}`}
                    value={text}
                    onChange={handleChange}
                    modules={modules}
                />
                <div className=' mr-1'> 
                    <Button color="active" size="smToolbarIcon" onClick={() => setToolbarOpen(!toolbarOpen)} className=''><FiMoreHorizontal/></Button>
                </div>
                
            </div>
        </>
    )
}
