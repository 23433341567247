import React from 'react';

// Hooks
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';

// API
import { createNote } from 'api/notes';

// Utils
import { recursiveCamelize } from 'utils/casing';

// Components
import { SearchNoteCode } from 'components/tools/search_note_code';
import { SelectDate } from 'components/tools/select_date';
import { SearchPairings } from 'components/tools/search_pairing';
// import { SearchCoach } from 'components/tools/search_coach';
import { SearchStudents } from 'components/tools/student_search';
import { Button } from 'components/core/button';
import { Error, InfoSectionHeading } from 'components/core/typo'
import { SearchTutors } from 'components/tools/search_tutor';
import { CompactNoteCell } from 'components/notes/list';
import { CheckBox } from 'components/tools/checkbox';
import { Editor } from 'components/core/rich-editor/editor';
import { SearchCommentTypes } from 'components/tools/search-comment-types';
import { CommentTypeBadge } from 'components/tools/search-comment-types';
import { Dropdown } from 'components/core/dropdown';
import { FiBookmark, FiCalendar, FiClock } from 'react-icons/fi';
import { TextInput } from 'components/core/inputs';

export function CreateNote({ defaultStudent, defaultTutor, defaultPairing, defaultStudySession, defaultCode, defaultGroup, defaultPeriod, onResult }) {
    const { t } = useTranslation("common");
    const [code, setCode] = useState(defaultCode || null);
    const [comment, setComment] = useState();
    const [commentTypes, setCommentTypes] = useState([]);
    const [openedAt, setOpenedAt] = useState(new Date());
    const [shouldDiscuss, setShouldDiscuss] = useState(false);
    const [commentDisplayDate, setCommentDisplayDate] = useState(new Date());
    const [closedAt, setClosedAt] = useState();
    const [student, setStudent] = useState(defaultStudent || (defaultPairing && defaultPairing.student));
    const [tutor, setTutor] = useState(defaultTutor || (defaultPairing && defaultPairing.tutor));
    const [pairing, setPairing] = useState(defaultPairing);
    const [studySession, setStudySession] = useState(defaultStudySession);
    const [shown, setShown] = useState(false)
    const [minutes, setMinutes] = useState();
    const [documentGroup,] = useState(defaultGroup);
    const [period,] = useState(defaultPeriod);

    const handleRemoveCommentType = (commentType) => {
        setCommentTypes(commentTypes.filter((ct) => ct.code !== commentType.code))
    }

    const params = useMemo(() => ({
        studySession: studySession,
        student: (student && student.ni) || (pairing && pairing.student && pairing.student.ni),
        tutor: (tutor && tutor.ni) || (pairing && pairing.tutor && pairing.tutor.ni),
        code: code && code.code, comment,
        commentCodes: commentTypes.map((ct) => ct.code),
        pairing: pairing && pairing.id,
        shouldDiscuss,
        commentDisplayDate,
        minutes:minutes || 0,
        // coach: coach&&coach.ni,
        openedAt,
        closedAt,
        documentGroup,
        period
    }),
        [student, tutor, code, studySession, openedAt, comment, minutes, commentDisplayDate, commentTypes, closedAt, pairing, shouldDiscuss, period, documentGroup]);
    const handleResult = (note) => {
        onResult?.(recursiveCamelize(note))
    }
    const [note, { loading, error, execute }] = useAPI(createNote, params, { immediate: false, onResult: handleResult})
    useEffect(() => {
        // waiting for component to be mounted (to avoid RichEditor render error)
        setShown(true)
    }, [])

    if (note) {
        return <div className="justify-center px-3 py-6">
            <p className="mb-6 text-2xl text-center">{t("note-has-been-created")}</p>
            <div className="p-3 bg-gray-100 rounded-xl">
                <CompactNoteCell {...note} select={null} />
            </div>
        </div>
    }

 
    return <>
        <div className="relative">
            <InfoSectionHeading mediumTitle={t("create-a-note")} />
            
            <div className="lg:grid lg:grid-cols-3 lg:gap-4">
                <div className='lg:col-span-2'>
                    <div className='flex items-start  '>
                    {/* Toolbar */}
                    <div className='flex items-center mt-0.5 mr-1 gap-2'>

                        <Dropdown onlyIcon={<FiBookmark/>} 
                        labelWithIcon={t("comment-type")}
                        tooltip={t("comment-type")} 
                        itemClassName="w-[300px]" 
                        orientation="left"
                        color={commentTypes && commentTypes.length>0? 'activeColor': 'default'}
                        >
                        <div className='pb-1'>
                        <SearchCommentTypes value={commentTypes} setValue={setCommentTypes} size="smWider" vOrientation="top"/>
                        </div>
                        <div className='flex flex-wrap mb-1'>
                            {
                                commentTypes &&
                                commentTypes.map((commentType, index) => (
                                    <CommentTypeBadge key={'selectCmtTypes'+index} className='mt-1 mr-1' removable={true} onRemove={() => handleRemoveCommentType(commentType)} text={commentType.name} color={commentType.color} />
                                ))
                            }
                            </div>
                            
                        </Dropdown>

                        <Dropdown onlyIcon={<FiClock/>} labelWithIcon={t("comment-minutes.label")} 
                        color={minutes? 'activeColor': 'default'}
                        tooltip={t("comment-minutes.label")} itemClassName="w-[300px]" orientation="left">
                        {setMinutes && <div className="pt-1"><TextInput value={minutes!=null? minutes: ""} 
                        size="sm" type="number" color={"gray"} min="0" step="1" xsDescription={t("comment-minutes.label")} 
                        onChange={e=>setMinutes(parseInt(e))}
                        placeholder={t("comment-minutes.placeholder")}/></div>}
                        </Dropdown>

                        <Dropdown onlyIcon={<FiCalendar/>} 
                        labelWithIcon={t("date-of-comment")}
                        color={commentDisplayDate? 'activeColor': 'default'}
                        tooltip={t("date-of-comment")} itemClassName="w-[300px]" orientation="left">
                        {setCommentDisplayDate && <div className="pt-1"><SelectDate xsDescription={t(`date-of-comment`)}  value={commentDisplayDate} setValue={setCommentDisplayDate} orientation="right" vOrientation="up" canRemoveSelection={false}/></div>}
                        </Dropdown>
                    </div>
                </div>

            
                    <label htmlFor="description" className="sr-only">
                        Description
                    </label>
                    {
                        // Wait for component to be mounted to avoid RichEditor render error
                        shown && <div className='h-full mt-3'>
                            <Editor editorStyles="text-black h-80"
                                toolbarStyles='mt-0'
                                name="description"
                                id="description"
                                placeholder={t("note-comment-placeholder")}
                                text={comment}
                                setText={setComment} />
                            
                        </div>
                    }
                </div>
                <div className='lg:col-span-1 lg:bg-white pt6 lg:pt-0 lg:border-l lg:pl-3 rounded-md lg:rounded-none'>
                    <div className="grid w-full grid-cols-1 gap-3 px-2 py-2  sm:p-3 rounded-xl ">
                        <div className="flex items-center justify-between space-x-2">
                            <div className="flex items-center space-x-2">
                                <SelectDate value={openedAt} setValue={setOpenedAt} label={t("opened-at")} orientation="left" />
                                <SelectDate value={closedAt} setValue={setClosedAt} label={t("ended-at")} orientation="left" />
                            </div>
                        </div>
                        {
                            studySession &&
                            <div className="bg-white text-gray-500 px-3 py-0.5 shadow rounded-md flex justify-between items-center">
                                <p>{`${studySession.code && studySession.code.name} du ${studySession.period} avec ${studySession.student && studySession.student.name} (id: ${studySession && studySession.id})`}</p>
                                <Button color="delete" size="sm" onClick={() => setStudySession()}>{t("remove")}</Button>
                            </div>
                        }
                        <SearchNoteCode showGroupFilter={true} size="lg" value={code} setValue={setCode} targetWidth={"full"} color="default" />
                        <SearchStudents size="lg" value={student || (pairing && pairing.student)} setValue={setStudent} disabled={studySession || pairing} targetWidth={"full"} color="default" />
                        <SearchTutors size="lg" targetWidth="full" value={tutor || (pairing && pairing.tutor)} setValue={setTutor} disabled={studySession || pairing} color="default" />
                        <div className="grid content-end grid-cols-1 gap-6">
                            <SearchPairings value={pairing}
                                setValue={setPairing}
                                size="lg"
                                student={student && student.ni}
                                tutor={tutor && tutor.ni}
                                active={true}
                                disabled={(!student && !tutor) || studySession}
                                color="default"
                                targetWidth={"full"} />
                            {/* <SearchCoach value={coach}
                    setValue={setCoach}
                    size="lg"
                    active={true}
                    label={t("attach-to-a-coach")}
                    color="white"
                    targetWidth={"full"}/>*/}

                            <CheckBox value={shouldDiscuss} setValue={setShouldDiscuss} name={t("should-discuss-case")} orientation="left" />

                        </div>

                    </div>

                    <div className="flex items-center justify-between py-2 my-2 ">

                        <Button onClick={execute}
                            loading={loading}
                            disabled={(!student && !tutor) || !code}
                            color="black"
                            size="lg"
                            block
                        >{t('create-note')}</Button>
                        {error ? <Error.Text {...error} /> : null}
                    </div>
                </div>

            </div>



        </div>
    </>
}
