import { format } from 'd3-format';

export function cronToString(d){
  if (!d) return "Aucune période";
  const fc = d.split(" ");
  const minutes = parseInt(fc[0]);
  const hour = parseInt(fc[1]);
  const dayInt = parseInt(fc[4]);
  const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt]
  return `${day} à ${format("02")(hour)}:${format("02")(minutes)}`
}

export function cronDecompose(d){
  if (!d) return "Aucune période";
  const fc = d.split(" ");
  const minutes = parseInt(fc[0]);
  const hour = parseInt(fc[1]);
  const dayInt = parseInt(fc[4]);
  const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt]
  return {minutes, hour, day, dayInt}
}

export function hasDispo(from_cron, to_cron, days, h0, h1, minutesRequired){
  /*Check if the cron has a availability in the interval h0, h1 for
  the days in the list days.
    Args:
    from_cron: string , cron string
    to_cron: string, cron string
    days: list of days of the weeks ('Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche')
    h0: start interval in format string (HH:MM)
    h1: end interval in format string (HH:MM)
    minutesRequired: int, number of minutes required in the interval
  */
  const {minutes: fromMinutes, hour: fromHours, day: fromDay} = cronDecompose(from_cron);
  const {minutes: toMinutes, hour: toHours, day: toDay} = cronDecompose(to_cron);
  const fromTime = fromHours*60 + fromMinutes;
  const toTime = toHours*60 + toMinutes;
  if (fromDay !== toDay){
    // Must be same day
    return false;
  } 
  if (days.indexOf(fromDay)===-1){
    return false;
  }
  const h0Split = h0.split(":").map(d=>parseInt(d));
  const h1Split = h1.split(":").map(d=>parseInt(d));
  const h0Time = h0Split[0]*60 + h0Split[1];
  const h1Time = h1Split[0]*60 + h1Split[1];

  const t0 = Math.max(fromTime, h0Time);
  const t1 = Math.min(toTime, h1Time);
  return t1-t0 >= minutesRequired;
}

export function cronsCompact(d){
  // d is a list of objects from from_cron and to_cron (both on the same day)
  // We want to display
  if (!d || d.length===0) return "Aucune période";
  const texts  = d.map(s=>cronsIntervalToString([s.from_cron, s.to_cron]).slice(0, -1));
  // Order by day of the week
  const week = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  const ordered = texts.sort((a,b)=>{
    const dayA = a.split(" ")[0];
    const dayB = b.split(" ")[0];
    return week.indexOf(dayA) - week.indexOf(dayB);
  });
  return ordered.join(", ");
}


export function cronsIntervalToString(d){
  if (!d || d.length===0) return "Aucune période";
  if (!Array.isArray(d)) return "Format invalide";
  const fc = d[0].split(" ");
  const minutes = parseInt(fc[0]);
  const hour = parseInt(fc[1]);
  const dayInt = parseInt(fc[4]);
  const day = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][dayInt];

  const tc = d[1].split(" ");
  const tminutes = parseInt(tc[0]);
  const thour = parseInt(tc[1]);
  const tdayInt = parseInt(tc[4]);
  const tday = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"][tdayInt];
  if (tday===day){
    return `${day} de ${format("02")(hour)}:${format("02")(minutes)} à ${format("02")(thour)}:${format("02")(tminutes)} `
  }
  return `${day} de ${format("02")(hour)}:${format("02")(minutes)} au ${tday} ${format("02")(thour)}:${format("02")(tminutes)} `
}