/* eslint no-unused-vars: "off" */
import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"

const globalScoreDefinitions = {
    "-" : [0, 29],
    "+-" : [30, 39],
    "+" : [40, 100]
}

const scoresDefinitions = {
    1 : [0, 19],
    2 : [20, 30],
    3 : [30, 39],
    4 : [40, 49],
    5 : [50, 100],
}


export function Success({...props}) {    
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.success.title")}
            projectedStatKey="nbSuccess" 
            infoDescription={t("students-metrics.success.description")}
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions}
            className="border-t-4 border-green-500"
            scoreClassName="!text-green-500"
            {...props}
        />
    </>
}
