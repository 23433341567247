import React from 'react';
import classnames from 'classnames';

// Hooks
import { useState, useMemo } from 'react'

// Components
import { SearchField } from 'components/tools/search_field'; 
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'components/core/dropdown';
import { Button } from 'components/core/button';
import { TextInput } from 'components/core/inputs'
import { TextWarning } from 'components/core/typo';
import { SelectPeriods } from 'components/tools/select_period';

// API
import { searchSportTeams } from 'api/teams';
import { useSearch } from 'hooks/useSearch';
import { FiCheck, FiTrash } from 'react-icons/fi';

export function SearchTeams({value, setValue, extraParams, label, disabled, color, size, showPeriodFilter, searchParams, multiple, ...props}){
  const { t } = useTranslation('common');
  const [params, setParams] = useState();
  const [period, setPeriod] = useState();
  const queryParams = useMemo(()=>({...params, period, ...extraParams}), [extraParams, params, period])
  const [teams, {loading}] = useSearch(searchSportTeams, queryParams, searchParams)
  return <div className='flex items-end space-x-2 '>
          <SearchField values={teams}
                      loading={loading}
                      value={value}
                      setValue={setValue}
                      label={label}
                      disabled={disabled}
                      multiple={multiple}
                      color={color}
                      targetWidth={showPeriodFilter&& "full"}
                      size={size}
                      placeholder={t("teams")}
                      indexingField={"slug"}
                      formatSelectedValue={(d=>d? (multiple? "": d.name): "")}
                      formatSearchResult={((team, {selected, active})=>
                        <div className='flex items-center justify-between '>
                          <div>
                            <span className={classnames('block truncate', (active && !multiple && "font-semibold") , (selected) && "font-semibold", (selected && multiple)?"text-gary-800" :"")}>
                            {team.name} {team.active_period && team.active_period.name}
                            </span>
                            <span className={classnames(' block truncate', 
                            (selected && !multiple)?"font-semibold text-white":
                            (selected && multiple)?"text-gray-600":
                            (!multiple && active)? "text-gray-100":
                            "text-gray-500")}>
                              {team.sport && team.sport.name} | {team.school && team.school.short_name}
                            </span>
                          </div>
                          {selected && multiple && <FiCheck className='inline-flex w-4 h-4 text-orange-600'/>}
                        </div>)}
                     onParamsChange={setParams}
                     {...props}
          />
          {showPeriodFilter && <SelectPeriods value={period} setValue={setPeriod} className="mt-3" color="side"/>}
      </div>
}

export function SelectMultipleTeams({value, setValue}){
  const [team, setTeam] = useState();
  const [description, setDescription] = useState(null);
  const { t } = useTranslation('common');
  return <div className="grid  gap-3">
            {value&& value.map(d=>
              <Dropdown menuClassName="w-full whitespace-nowrap" key={d.team.slug} label={`${d.team.name} ${d.team.active_period.name}`}>
                <Dropdown.Item name={d.description} description={t("description")}/>
                <Dropdown.Item icon={<FiTrash/>} name={t("remove")} color="danger" onClick={()=>setValue(e=>e.filter(el=>el.team.slug!==d.team.slug))}/>
              </Dropdown>
              )}
            <div className="col-span-full">
              <Dropdown menuClassName="w-full whitespace-nowrap" label={t("new-team")} itemClassName="p-3 space-y-3 w-80">
                <SearchTeams value={team} setValue={setTeam}/>
                <TextInput 
                  label={t("description")} 
                  placeholder={t("team-member-type")}
                  required
                  size="sm"
                  onKeyDown={(e) => { if (e.code === "Space") { e.stopPropagation(); } }}
                  onChange={setDescription}/>
                <Button color="black" block size="sm" disabled={!team} onClick={()=>{setValue(e=>e?[...e, {team, description}]: [{team, description}]); setDescription(); setTeam()}}>
                  {t("add-team")}
                </Button>
              </Dropdown>
            </div>
         </div>
}

export function SelectMultipleTeamsAsCoach({value, setValue}){
  const [team, setTeam] = useState();
  const [description, setDescription] = useState();
  const { t } = useTranslation('common');
  return <div className="grid max-w-sm grid-cols-2 gap-3">
            {value&& value.map(d=>
              <Dropdown key={d.team.slug} label={d.team.name} menuItemsClassName="whitespace-nowrap">
                <Dropdown.Item name={d.team.name} description={d.description}/>
                <Dropdown.Item name={t("remove")} color="danger" onClick={()=>setValue(e=>e.filter(el=>el.team.slug!==d.team.slug))}/>
              </Dropdown>
              )}
            <div className="col-span-full">
              <Dropdown menuClassName="w-full" label={t("new-team")} itemClassName="p-3 space-y-3 w-80">
                <SearchTeams value={team} setValue={setTeam}/>
                <Dropdown label={description || t("role")} >
                  <Dropdown.Item name={t("coach")} onClick={()=>setDescription(t("coach"))}/>
                  <Dropdown.Item name={t("assistant-coach")} onClick={()=>setDescription(t("assistant-coach"))}/>
                </Dropdown>
               
               {team && team.coach && description===t("coach") && <TextWarning code={t("team-has-already-a-coach")} payload={team.coach}/>}
               {team && team.assistant_coach && description===t("assistant-coach") && <TextWarning code={t("team-has-already-an-assistant-coach")} payload={team.assistant_coach}/>}
                <Button color="black" block size="sm" disabled={!team || !description} onClick={()=>{setValue(e=>e?[...e, {team, description}]: [{team, description}]); setDescription(); setTeam()}}>
                  {t("add-team")}
                </Button>
              </Dropdown>
            </div>
         </div>
}