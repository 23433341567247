import React from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { CardContainer, Container } from 'components/core/container';
import { Tabs } from 'components/core/tabs';
import { Button } from 'components/core/button';
import { FiArrowLeft, FiInfo } from 'react-icons/fi';
import { SlideOver } from 'components/core/slide_over';
import { StudentInfo } from 'components/student/info';
import { TextWarningStructured } from 'components/core/typo';

// Hooks
import { useContext, useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation, generatePath, matchPath } from 'react-router-dom';

// Contexts
import { StudentContextProvider, StudentContext } from 'contexts/student';
import { locale, dateParse } from 'utils/locale';

function Header(){
  const { t } = useTranslation("common");
  const { student } = useContext(StudentContext);
  if (!student.name){
    return <Container className="py-6">
              <Skeleton className="w-screen max-w-sm h-12" />
            </Container>
  }
  return <Container className="py-3 ">
          <Button href="/students" color="inlineLink"  className="md:hidden"><FiArrowLeft className="mr-1"/>{t("students")}</Button>
          <h1 className="font-medium text-2xl">{student.name}</h1>
          <p className="text-base text-gray-500">
            <span>{student.school_year && student.school_year.name}</span> | <span>{student.school && student.school.name}</span>
          </p>
          </Container>
}

function StudentTabs(){
  const { student, refresh} = useContext(StudentContext);
  const params = useParams();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(()=>{
    if (open){ refresh();}
  }, [open])

  const { t } = useTranslation("common");
  const tabs = useMemo(()=>[
      {
        name: t("student-nav.overview"),
        href: generatePath("/students/:ni", params),
        current: matchPath("/students/:ni/", location.pathname),
        end: true
      },
      {
        name: t("student-nav.notes"),
        href: generatePath("/students/:ni/notes", params),
        current: matchPath("/students/:ni/notes/*", location.pathname),
        end: false
      },
      {
        name: t("student-nav.documents"),
        href: generatePath("/students/:ni/documents", params),
        current: matchPath("/students/:ni/documents", location.pathname),
        end: false
      },
      {
        name: t("student-nav.settings"),
        href: generatePath("/students/:ni/settings/", params),
        current: matchPath("/students/:ni/settings/", location.pathname),
        end: false
      },
    ], [location]);

  return <Container className="border-b flex items-center justify-between">
          <Tabs tabs={tabs}/>
          <Button color="toolbarIcon" size="toolbarIcon" onClick={()=>setOpen(!open)}><FiInfo/></Button>
          <SlideOver open={open} setOpen={setOpen}>
            {student && <CardContainer><StudentInfo student={student}/></CardContainer>}
          </SlideOver>
          </Container>
}

function StudentIsClosedCard(){
  const { t } = useTranslation("common");
  const { student } = useContext(StudentContext);
  if (student && student.ended_using_service && new Date(student.ended_using_service) < new Date()){
    return <Container className="py-2">
            <TextWarningStructured title={t("student-has-left-service.title")} description={t("student-has-left-service.description")}/>
            </Container>
  }
  if (student && student.ended_using_service && new Date(student.ended_using_service) > new Date()){
    return <Container className="py-2">
            <TextWarningStructured
            title={t("student-will-left-service.title")}
            description={t("student-will-left-service.description", {date: locale.format("%d %B")(dateParse(student.ended_using_service))})}/>
            </Container>
  }
  return null
}

export function StudentLayout({ children }){
  return <StudentContextProvider>
          <Header/>
          <StudentTabs/>
          <StudentIsClosedCard/>
          { children }
         </StudentContextProvider>

}