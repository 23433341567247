import { fetchCall } from 'api';

export function getCoordoStats({fields, start, end, date}) {
    const options = {
        method: "GET",
        endpoint: "/stats/coordinator-stats",
        query: {
            fields,
            start,
            end,
            date
        }
    }
    return fetchCall(options)
}
