import React, { useContext, useEffect, useMemo } from "react";

// Compnents
import { InfoContainer, Info } from 'components/core/info';
import { Button } from 'components/core/button';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { CheckBox } from 'components/tools/checkbox';
import { SelectCronPeriod } from 'components/tools/select_cron_period';
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { Error, RawTexError } from 'components/core/typo'
import { SearchUser } from "components/tools/search_user";

// Hooks
import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
// import { useAPI } from 'hooks/useAPI';

// Utils
import { cronsIntervalToString } from 'utils/crons';

//API
// import { createPairing } from 'api/pairings';
import { SelectSchoolSubjectsDropdown } from 'components/tools/select_school_subjects';
import { PairingContext } from "contexts/pairing";
import { updatePairing } from "api/pairings";
import { useAPI } from "hooks/useAPI";
import { Skeleton } from "components/core/skeleton";
import { SelectDate } from "components/tools/select_date";
import { dateParse } from "utils/locale";
import { DeleteButton } from "./modify";


export function ConfirmPairingPage() {
    const { pairing, execute: reloadPairing } = useContext(PairingContext);
    const [draftPairing, setDraftPairing] = React.useState(pairing);
    const params = useMemo(() => ({
        confirmed: new Date().toISOString().slice(0, 10),
        id: draftPairing.id,
        studentConfirmed: draftPairing.studentConfirmed,
        tutorConfirmed: draftPairing.tutorConfirmed,
        period: draftPairing.period,
        startedAt: draftPairing.startedAt,
        preferedType: draftPairing.preferedType && draftPairing.preferedType.code,
        subjects: draftPairing.subjects,
        assignedUser: draftPairing.assigned_user
    }), [draftPairing]);

    const [result, { loading, error, execute }] = useAPI(updatePairing, params, { immediate: false });
    const { t } = useTranslation('common');
    useEffect(() => {
        setDraftPairing(pairing);
    }, [pairing])
    if (!draftPairing.student) {
        return <Skeleton className="h-80" />
    }
    if (result) {
        return <div className="h-full flex flex-col justify-center items-center bg-gray-100">
            <div className="bg-white my-3 shadow-sm rounded-md p-6 w-full max-w-xl">
                <h2 className="text-2xl font-medium">{t('pairing-is-confirmed.title')}</h2>
                <p className="text-gray-500 mb-2">{t("pairing-is-confirmed.info")}</p>

                <Button onClick={() => reloadPairing()} block color="active" className="mt-5" size="lg">
                    {t("pairing-is-confirmed.button")}
                </Button>
            </div>
        </div>

    }
    return <div className="min-h-full flex flex-col justify-center items-center bg-gray-100">
        <div className="bg-white my-3 shadow-sm rounded-md p-6 w-full max-w-xl">
            <h1 className="text-2xl font-medium">{t('pairing-is-not-confirmed.title')}</h1>
            <p className="text-gray-500 mb-2">{t("pairing-is-not-confirmed.info")}</p>
            <hr className="my-3" />
            {!result && draftPairing &&
                <>
                    <div className="flex w-full space-x-2">
                        <InfoContainer containerClassName="flex-1">
                            <Info.Field value={draftPairing.student.name} label={t("student")} />
                        </InfoContainer>
                        <InfoContainer containerClassName="flex-1">
                            <Info.Field value={draftPairing.tutor.name} label={t("tutor")} />
                        </InfoContainer>
                    </div>
                    <InfoContainer modify={true} className="space-y-0">
                        <ModifiableSelectField
                            Field={CheckBox}
                            label={t("student-has-confirmed-pairing")}
                            modify={true}
                            yesNo={true}
                            marker="select"
                            value={draftPairing.studentConfirmed || true}
                            setValue={(studentConfirmed) => setDraftPairing(d => ({ ...d, studentConfirmed }))} />
                        <ModifiableSelectField
                            Field={CheckBox}
                            label={t("tutor-has-confirmed-pairing")}
                            modify={true}
                            yesNo={true}
                            marker="select"
                            value={draftPairing.tutorConfirmed || true}
                            setValue={(tutorConfirmed) => setDraftPairing(d => ({ ...d, tutorConfirmed }))} />
                        <ModifiableSelectField
                            Field={SearchUser}
                            label={t("assigned-coordo")}
                            modify={true}
                            value={draftPairing.assigned_user || pairing.assigned_user}
                            setValue={(assignedUser) => { setDraftPairing(d => ({ ...d, assigned_user: assignedUser })) }} />
                        <ModifiableSelectField
                            Field={SelectDate}
                            label={t("started-at")}
                            canRemoveSelection={false}
                            modify={true}
                            value={draftPairing.startedAt && dateParse(pairing.started_at)}
                            setValue={(startedAt) => setDraftPairing(d => ({ ...d, startedAt }))} />
                        <ModifiableSelectField
                            Field={SelectCronPeriod}
                            label={t("weekly-period")}
                            subLabel={t("shared") + ((draftPairing.common_availability || []).length === 0 ? 'Aucune disponibilité commune' : (draftPairing.common_availability || []).map(s => `${cronsIntervalToString(s)}`).join(', '))}
                            modify={true}
                            value={draftPairing.period}
                            setValue={(period) => setDraftPairing(d => ({ ...d, period }))} />
                        <ModifiableSelectField
                            Field={SelectPairingType}
                            label={t("pairing-type")}
                            subLabel={t("shared") + ((draftPairing.student.on_site_preference && draftPairing.tutor.on_site_preference) ? t("on-site-preference") :
                                (draftPairing.student.online_preference && draftPairing.tutor.online_preference) ? t("online-preference") :
                                    (draftPairing.student.hybrid_preference && draftPairing.tutor.hybrid_preference) ? t("hybrid-preference") :
                                        t("undefined")
                            )}
                            removeHybrid={false}
                            modify={true}
                            value={draftPairing.preferedType}
                            setValue={(preferedType) => setDraftPairing(d => ({ ...d, preferedType }))} />
                        {/* Schools subjects */}
                        <ModifiableSelectField
                            Field={SelectSchoolSubjectsDropdown}
                            label={t("pairing-subjects")}
                            subLabel={t("shared") + (draftPairing.common_subjects || []).map(s => `${s.name}`).join(', ')}
                            modify={true}
                            block={true}
                            noBorder={true}
                            value={draftPairing.subjects || []}
                            setValue={(subjects) => setDraftPairing(d => ({ ...d, subjects }))} />
                        {draftPairing.subjects && draftPairing.subjects.length > 2 && <RawTexError>{t("pairing-subjects-limit")}</RawTexError>}
                    </InfoContainer>
                    <Button loading={loading} onClick={execute} block color="active" className="mt-5" size="lg"
                        disabled={!draftPairing.preferedType || !draftPairing.period || !draftPairing.startedAt || (draftPairing.subjects && draftPairing.subjects.length > 2)}>
                        {t("confirm-pairing.button")}
                    </Button>
                    {error ? <Error.Text {...error} /> : null}
                </>}
            {result &&
                <div>
                    <p>{t("pairing-created")}</p>
                </div>
            }
        </div>

        <div className="w-full max-w-xl mt-5">
            <DeleteButton id={draftPairing.id} />
        </div>
    </div>
}