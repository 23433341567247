import React from 'react';

// Components
import { TextWarningStructured } from 'components/core/typo';
import { useTranslation } from 'react-i18next';

// Hooks
import { useUser } from 'hooks/useUser';

export function ShouldSetMFACard(){
    const {t} = useTranslation("common");
    return <TextWarningStructured className={"max-w-xl mx-3 my-2"} title={t("should-set-mfa.title")} description={t("should-set-mfa.description")} to="/settings/security" button={t("should-set-mfa.btn")}/>
}

export function ShouldSetMFACardWrapper(){
    const [user] = useUser();
    if (user && !user.phone_verified){
        return <ShouldSetMFACard/>
    }
    return null;
}
    
