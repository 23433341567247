/* eslint-disable */
import React from 'react';

// API
import { retrieveTutoringStats } from 'api/stats'

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useMemo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Button } from 'components/core/button';
// import { FiCalendar } from 'react-icons/fi';
import { Table } from 'components/core/table';
// import { Tooltip } from 'components/core/tooltip';
import { SelectDateInterval } from 'components/tools/select_date';
import { GlobalStats } from 'components/tutoring-follow-up/global-stats';

// Utils
// import { locale, dateParse } from 'utils/locale';
import classnames from 'classnames'
import { format } from 'd3-format';
import { timeDay } from 'd3-time';
// import { cronToString } from 'utils/crons';
// Contexts
import { SearchTutoringStatsContext, SearchTutoringStatsContextProvider } from 'contexts/search_tutoring_stats'
import { SelectStatsFiltering } from 'components/tools/select_stats_filtering';
import { SelectPeriods } from 'components/tools/select_period';
import { useUser } from 'hooks/useUser';


export function StudentCell({ student, tutor }) {
    // Total cell
    if (!tutor) {
        return <span>Total</span>
    }
    return <div className="flex flex-col my-1 whitespace-nowrap">
        <Button target="_blank" color="hiddenLink" className="font-medium" href={`/students/${student.ni}`}>{student.name}</Button>
        <Button target="_blank" color="hiddenLink" className={"opacity-50"} href={`/tutors/${tutor.ni}`}>{tutor.name}</Button>
    </div>
}


function SuccessCell({ successfull, choked, cancelled }) {
    const total = successfull + choked + cancelled;
    return <div className="flex items-center justify-left gap-x-6 px-6 text-center">
        <span className={classnames(total === 0 && "text-gray-300")}>{successfull}</span>
        <span className={classnames("px-2 rounded-md ",
            (total > 0 && successfull / total >= 0.8) && "bg-green-100 text-green-700",
            (total > 0 && successfull / total < 0.8) && "bg-red-100 text-red-700",
            total === 0 ? " text-gray-300" : "")}>
            {format(".0%")(total > 0 ? successfull / total : 0)}
        </span>
    </div>
}

function ChokedCell({ successfull, choked, cancelled }) {
    const total = successfull + choked + cancelled;
    return <div className="flex items-center justify-left gap-x-6 px-6 text-center">
        <span className={classnames(total === 0 && "text-gray-300")}>{choked}</span>
        <span className={classnames("px-2 rounded-md ",
            total > 0 && choked / total <= 0.05 && "bg-green-100 text-green-700",
            total > 0 && choked / total > 0.05 && "bg-red-100 text-red-700",
            total === 0 && " text-gray-300")}>
            {format(".0%")(total > 0 ? choked / total : 0)}
        </span>
    </div>
}

function CancelledCell({ successfull, choked, cancelled }) {
    const total = successfull + choked + cancelled;
    return <div className="flex items-center justify-left gap-x-6 px-6 text-center">
        <span className={classnames(total === 0 && "text-gray-300")}>{cancelled}</span>
        <span className={classnames("px-2 rounded-md ",
            total > 0 && cancelled / total <= 0.15 && "bg-green-100 text-green-700",
            total > 0 && cancelled / total > 0.15 && "bg-red-100 text-red-700",
            total === 0 && " text-gray-300")}>
            {format(".0%")(total > 0 ? cancelled / total : 0)}
        </span>
    </div>
}


function TechnicalProblemCell({ successfull, choked, cancelled, technical_problem }) {
    const total = successfull + choked + cancelled + technical_problem;
    const subTotal = technical_problem;
    return <div className="flex items-center justify-left gap-x-6 px-6 text-center">
        <span className={classnames(total === 0 && "text-gray-300")}>{subTotal}</span>
        <span className={classnames("px-2 rounded-md", subTotal && "bg-blue-100 text-blue-700",
            total === 0 ? "text-gray-300" :
                subTotal === 0 && " text-gray-500")}>
            {format(".0%")(total > 0 ? subTotal / total : 0)}
        </span>
    </div>
}



function SubjectCell({ num_study_sessions, num_minutes }) {
    if (!num_study_sessions) return <p className="text-center text-gray-300">0</p>
    // If num_study_sessions is integer, format to integer, else to .2f
    const isInteger = num_study_sessions % 1 === 0;
    return <><p className="text-center">{format(isInteger ? ".0f" : ".2f")(num_study_sessions)}</p>
        <p className={classnames("text-center text-gray-500")}>
            {num_minutes ? `${format("02")(Math.floor(num_minutes / 60))}h${format("02")(Math.floor(num_minutes % 60))}` : "0h"}
        </p>
    </>
}


export function TotalHours({ subjects, successfull }) {
    const total = successfull;
    const numMinutes = subjects.reduce((prev, d) => prev + d.num_minutes, 0);
    const isInteger = total % 1 === 0;
    return <><p className="text-center">{format(isInteger ? ".0f" : ".2f")(total)}</p>
        <p className={classnames("text-center text-gray-500")}>
            {numMinutes ? `${format("02")(Math.floor(numMinutes / 60))}h${format("02")(Math.floor(numMinutes % 60))}` : "0h"}
        </p>
    </>

}

function TutoringStatsTable({ stats }) {
    const { t } = useTranslation('common');
    const headers = useMemo(() => {
        if (!stats) return null;
        var h = [
            {
                title: t("students"),
                field: (d => d ? d : "student"),
                FormatComponent: StudentCell,
                className: "text-left bg-white",
                itemClassName: (d => d.student.name === "Total" ? "font-bold sticky  left-0 bg-white " : "sticky left-0 group-odd:bg-gray-50 group-even:bg-white ring-1 ring-gray-200")
            },
            {
                title: t("stats.successfull"),
                field: (d => d ? d : "successfull"),
                FormatComponent: SuccessCell,
                className: "text-center text-green-700 bg-green-100 border",
                itemClassName: (d => d.student.name === "Total" ? "font-bold px-0-important " : "px-0-important  ")
            },
            {
                title: t("stats.choked"),
                field: (d => d ? d : "choked"),
                FormatComponent: ChokedCell,
                className: "text-center text-red-700 bg-red-100",
                itemClassName: (d => d.student.name === "Total" ? "font-bold px-0-important border-x border-gray-300 " : "px-0-important border-x border-gray-300")
            },
            {
                title: t("stats.cancelled"),
                field: (d => d ? d : "cancelled"),
                FormatComponent: CancelledCell,
                className: "text-center text-yellow-700 bg-yellow-100",
                itemClassName: (d => d.student.name === "Total" ? "font-bold px-0-important border-r border-gray-300 " : "px-0-important border-r border-gray-300")
            },
            {
                title: t("stats.technical_problem"),
                field: (d => d ? d : "technical_problem"),
                FormatComponent: TechnicalProblemCell,
                className: "text-center text-blue-700 bg-blue-100 border-r-2 border-black",
                itemClassName: (d => d.student.name === "Total" ? "font-bold px-0-important border-r-2 border-black " : "px-0-important border-r-2 border-black")
            },
            {
                title: t("stats.total_hours"),
                field: (d => d ? d : "total_hours"),
                FormatComponent: TotalHours,
                className: "text-center px-2  border-r border-gray-300 bg-white",
                itemClassName: (d => d.student.name === "Total" ? "font-bold px-0-important  border-r border-gray-300 " : "px-0-important  border-r border-gray-300")
            },
        ];
        if (stats && stats.length > 0) {
            stats[0].subjects.forEach((e, i) => {
                const header = {
                    title: ` ${e.subject.name.slice(0, 3)}`,
                    field: (d => d ? d.subjects[i] : `subject-${e.subject.slug}`),
                    itemClassName: "px-2 border-gray-300 border-r",
                    className: "text-center px-2 border-x w-24 trim-3 bg-white",
                    format: (d => <SubjectCell {...d} />),
                }
                h.push(header)

                // const hoursHeader = {
                //   title: `Heures ${e.subject.name.slice(0,3)}` ,
                //   field: (d=>d? d.subjects[i]: `hours-subject-${e.subject.slug}`),
                //   itemClassName: "px-0-important border-r border-gray-300",
                //   className: "text-center px-2 border-x w-24 trim-3 bg-white",
                //   format: (d=><HourSubjectCell {...d}/>),
                // }
                // h.push(hoursHeader)
            })

        }

        return h;
    }, [stats]);

    const statsWithTotal = useMemo(() => {
        if (!stats || stats.length === 0) return [];

        const subjects = stats[0].subjects.map((e, i) =>
        ({
            num_study_sessions: stats.reduce((prev, d) => prev + d.subjects[i].num_study_sessions, 0),
            num_minutes: stats.reduce((prev, d) => prev + d.subjects[i].num_minutes, 0)
        }));
        const total = {
            student: { name: "Total", ni: "total" },
            successfull: stats.reduce((prev, n) => prev + n.successfull, 0),
            choked: stats.reduce((prev, n) => prev + n.choked, 0),
            cancelled: stats.reduce((prev, n) => prev + n.cancelled, 0),
            technical_problem: stats.reduce((prev, n) => prev + n.technical_problem, 0),
            subjects
        }
        return [...stats, total]
    }, [stats])

    return <div className="relative h-full overflow-auto">
        <Table headers={headers}
            data={statsWithTotal}
            rowClassName={d => d.student.ni === "total" ? "" : "hover:bg-gray-100 odd:bg-gray-50 group "}
            headerRowClassName={"sticky top-0 z-0"}
            indexingKey={(d => `${d.pairing ? d.pairing.id : '__total__'}`)} />
    </div>
}

export function TutoringStatsWithContext({ preset, team, weekdays}) {
    const { t } = useTranslation('common');
    const { filters, dispatchFilters } = useContext(SearchTutoringStatsContext)
    const [user,] = useUser();
    const params = useMemo(() => {
        var options = ({
            weekdays,
            stepDays: 7, ...filters,
            fromDate: filters.fromDate || timeDay.offset(new Date(), -14),
            toDate: filters.toDate || new Date()
        });

        if (!filters.statsFilteringOption || (filters.statsFilteringOption.value === "preset")) {
            options = { ...options, preset, team, activePairings: true }
        }
        else if (filters.statsFilteringOption.value === "your-pairings") {
            options = { ...options, team, activePairings: true, assignedTo: user?.email }
        }
        else {
            options = { ...options, period: filters.statsExtraPeriod }
        }
        return options;
    }
        , [team, preset, weekdays, filters])
    const validateParams = useCallback(({ fromDate, toDate }) => fromDate && toDate, [])
    const [stats] = useSearch(retrieveTutoringStats, params, { validateParams, limit: 2000 });

    return <div className='h-full flex flex-col'>
        <GlobalStats />
        <div className="flex items-center justify-between px-4 my-3">
            <SelectDateInterval missingToDateIsNow={true} orientation="left" value={{ fromDate: filters.fromDate || timeDay.offset(new Date(), -14), toDate: filters.toDate}}
                setValue={value => dispatchFilters({ type: "dates", value: value?.(filters) || {...filters, fromDate: timeDay.offset(new Date(), -14), toDate: new Date()} })} />
            <div className='flex items-center space-x-3'>
                {filters.statsFilteringOption && filters.statsFilteringOption.value === "all" && <SelectPeriods value={filters.statsExtraPeriod} setValue={(value) => dispatchFilters({ type: "statsExtraPeriod", value })} />}
                <SelectStatsFiltering preset={filters?.preset || preset} value={filters.statsFilteringOption} setValue={(value) => dispatchFilters({ type: "statsFilteringOption", value })} />
            </div>
        </div>
        {
            !stats ? <Skeleton className="w-full h-80" /> :
                stats.length === 0 ?
                    <EmptyState title={t("empty-state.no-pairings-title")}
                        description={t("empty-state.no-tutoring-stats-description")}
                    /> :
                    <TutoringStatsTable
                        stats={stats} />

        }
    </div>
}

export function TutoringStats(props) {
    return <SearchTutoringStatsContextProvider storageKey="tutoring-stats">
        <TutoringStatsWithContext {...props} />
    </SearchTutoringStatsContextProvider>
}


