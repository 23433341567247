import React from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";

// Components
import { Link, NavLink } from "react-router-dom";
import { Spinner } from "components/core/spinner";

// Definitions
export const sizes =   {
  xs: 'px-2.5 py-1 text-xs rounded-md',
  sm: 'px-3 py-2 text-sm rounded-md',
  smTight2: 'px-2 py-1 text-sm rounded-md',
  smWide: 'px-6 py-2 text-sm rounded-md',
  smTight: 'px-2 py-0.5 text-sm rounded-md',
  md: 'px-4 py-2 text-sm rounded-md',
  mdTight: 'px-2 py-2 text-sm rounded-md',
  mdWide: 'px-8 py-2 text-sm rounded-md',
  lg: 'px-4 py-2 text-base rounded-md',
  xl: 'px-6 py-3 text-base rounded-md',
  link: "",
  padLink: "px-2",
  nextToForm: 'px-6 py-3 text-base rounded-md',
  icon: 'text-xl rounded',
  sphere: 'w-10 h-10 text-center rounded-full aspect-square',
  toolbarIcon: 'text-xl p-2 rounded-md',
  smToolbarIcon: 'text-lg !p-1 rounded-md',
  nav: 'h-full px-4 py-2 text-base',
  sideNav: 'px-4 md:pl-3 2xl:px-4 py-2 2xl:text-base whitespace-nowrap w-full flex items-center',
  subSideNav: 'px-2 py-2 text-base whitespace-nowrap w-full flex items-center',
  flag: 'px-1 py-1 rounded-md text-base whitespace-nowrap w-full flex items-center'
};

export const colors =   {
  contrastWhite: 'shadow border-0 bg-white text-orange-600 hover:text-orange-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
  contrastWhiteGray: 'shadow border-0 bg-white text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
  contrastWhiteGrayInactive: 'opacity-40 shadow border-0 bg-gray-200 text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
  cardNav: 'font-medium border-0 text-orange-600 hover:text-orange-800 hover:bg-gray-100  transition-all duration-100 outline-none',
  warning: 'shadow-sm border-0 bg-yellow-100 text-yellow-600 hover:text-yellow-800 hover:bg-yellow-200 transition-all duration-100 outline-none ',
  default: 'shadow-none border-0 bg-gray-100 text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-all duration-100 outline-none ',
  active: 'shadow-none border-0 text-white bg-orange-600 hover:text-white hover:bg-orange-500 outline-none ',
  info: 'shadow-none border-0 text-blue-600 bg-blue-100 hover:text-blue-700 hover:bg-blue-50 outline-none ',
  gray: 'shadow-none border-0 text-gray-600 bg-gray-100 hover:text-gray-700 hover:bg-gray-50 outline-none ',
  green: 'shadow-none border-0 text-green-600 bg-green-100 hover:text-green-700 hover:bg-green-50 outline-none ',
  toolbarIcon: "text-orange-600 hover:text-orange-700 hover:bg-orange-100 rounded-md",
  delete: 'shadow-none border-0 text-red-600 hover:text-red-700 hover:bg-red-50 outline-none ',
  deleteContrast: 'shadow-none border-0 bg-red-500 text-white hover:bg-red-600  outline-none ',
  black: 'flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900',
  warningCard:  'shadow border-0 bg-white text-yellow-600 hover:text-yellow-100 hover:bg-yellow-700 transition-all duration-100 outline-none ',
  infoCard:  'shadow border-0 bg-white text-vlue-600 hover:text-vlue-100 hover:bg-vlue-700 transition-all duration-100 outline-none ',
  paginate: 'border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-100',
  hiddenLink: 'shadow-none border-0 hover:underline',
  inlineLink: 'text-orange-500 shadow-none border-0 hover:underline',
  errorLink: 'text-red-700 underline shadow-none border-0 hover:underline hover:opacity-70',
  warningLink: 'text-yellow-700 underline shadow-none border-0 hover:underline hover:opacity-70',
  baseLink: 'text-gray-500 shadow-none border-0 hover:text-orange-500 hover:underline',
  nav: "text-gray-500 font-medium",
  activeNav: "text-gray-900 hover:text-gray-700  border-b border-gray-500",
  sideNav: "text-gray-400 hover:text-gray-200 hover:bg-gray-700 ",
  activeSideNav: "text-white hover:text-orange-50  bg-orange-600 md:border-r-2 md:border-r-orange-600 2xl:border-0",
  subSideNav: "text-gray-500 rounded-md  bg-white hover:text-gray-800 hover:bg-gray-50",
  activeSubSideNav: "text-gray-900  rounded-md bg-gray-100",
  cell: "bg-white hover:bg-gray-200",
  activeCell: "bg-orange-500 hover:bg-orange-500",
  activeFlag: "bg-red-400 text-white hover:bg-red-400",
  flag: "bg-gray-50 text-gray-300 hover:bg-gray-100 hover:text-gray-600",
  purple: "bg-purple-100 text-purple-600 hover:bg-purple-200",
  none: ""
};

const layouts =   {
  block: 'w-full flex items-center justify-center',
  blockLeft: 'w-full flex items-center justify-left',
  cell: 'w-full block',
  smBlock: 'w-full flex justify-center sm:inline-flex sm:items-center sm:w-auto',
  base: 'inline-flex items-center',
  none: ''
};

export function Button({ block, layout, color, size, children, disabled, externalLink, loading, className, nav, href, ...props }){
  var _className = classnames("border-0 z-0",
                            sizes[size],
                            block? layouts.block: layout? layouts[layout]:layouts.base,
                            colors[color],
                            disabled? 'opacity-30 cursor-default': '',
                            className)
  if (href){
    if (externalLink){
      return <a href={href} className={_className} disabled={disabled || loading} {...props}>
          {loading? <Spinner size={'sm'}/> : children }
          </a>
    }
    if (nav){
        const cn = ({isActive}) => classnames("border-0 z-0",
                                    sizes[size],
                                    block? layouts.block: layout? layouts[layout]:layouts.base,
                                    colors[color(isActive)],
                                    disabled? 'opacity-30 cursor-default': '',
                                    className)
        return <NavLink to={href} className={cn} disabled={disabled || loading} {...props}>
              {children}
          </NavLink>
    }
    return <Link to={href} className={_className} disabled={disabled || loading} {...props}>
          {loading? <Spinner size={'sm'}/> : children }
          </Link>
  }
  return <button className={_className} disabled={disabled || loading} {...props}>
          {loading? <Spinner size={'sm'} className={"!text-white"}/> : children }
          </button>
}

Button.propTypes = {
  block: PropTypes.bool, // block, base
  size: PropTypes.string, // xs, sm, md, lg, xl, 2xl
  loading: PropTypes.bool,
  className: PropTypes.string,
}
