import React from 'react';

// Components
import { TrophyIcon } from '@heroicons/react/24/outline';
import {TournamentRulesList } from 'components/tournament-rules/tournament-rules';

// API

// Hooks
import { useTranslation } from 'react-i18next';

import { SearchContextProvider } from 'contexts/search';

function SettingsTournamentRules(){
  const {t} = useTranslation("common");

  return <div className="h-full min-h-screen md:min-h-0 ">
            <h1 className="info-section">{t('tournament-rules.title')}</h1>
           <TournamentRulesList/>
          </div>
}

function SettingsTournamentRulesWithContext(){

  return  <SearchContextProvider doNotStore>
            <SettingsTournamentRules/>
          </SearchContextProvider>
}
const route =  { path: "tournament-rules",
                 name: "Règles de tournois" ,
                 group: "data",
                 Icon: TrophyIcon,
                 requireAuth: true,
                 scopes: ["Direction", "RST", "RSI", "Développeur"],
                 Element: SettingsTournamentRulesWithContext };
export default route;
