import React from 'react';

// Components
import { Dropdown } from "components/core/dropdown";
import { Button } from "components/core/button";

// Utils

// Icons
import { FiUsers as MatchedIcon } from "react-icons/fi";
import { FiBookOpen as BookIcon } from "react-icons/fi";

export function Pairings({student, loading}){
    const pairings = student?.pairings || [];
    const pairing = pairings[0] || {};
    const {tutor} = pairings[0] || {};
    const {subjects} = pairings[0] || {};
    if (!tutor || loading) return <></>
    return <>
        <Dropdown vOrientation="up" menuItemsClassName="!-translate-y-[32px] translate-x-[30px] bottom-0 right-full" itemClassName="w-[200px]" iconClassName="!p-1.5 !rounded-md" onlyIcon={<MatchedIcon className="text-base" />} >
            <Dropdown.Info className={"!px-1"}>
                <Button href={`/pairings/${pairing?.id}`} color="cardNav" size="link" className="flex flex-col gap-1 w-full rounded p-2">
                    <div className="text-sm text-gray-500 flex gap-1 w-full"><MatchedIcon className='mt-1 shrink-0'/>{tutor?.name}</div>
                    {
                        subjects?.length > 0 && <div className="text-sm text-gray-500 flex gap-1 w-full"><BookIcon className='mt-1 shrink-0'/>{subjects?.map(subject => subject?.name).join(", ")}</div>
                    }
                </Button>
            </Dropdown.Info>
        </Dropdown>
    </>
}