/* eslint no-unused-vars: "off" */

import React from "react";

// Components
import { StatsCard, StatsCardTitle, StatsCardInfo } from 'components/coordo-stats/stats-card';
import { Skeleton } from "components/core/skeleton";
import { CoordoStatsContextProvider } from 'contexts/coordo-stats';

// Utils
import * as Plot from '@observablehq/plot';
import * as d3 from "d3";
import classNames from "classnames";

// Hooks
import { useCoordoStats } from "contexts/coordo-stats"
import { useEffect, useRef } from "react";
import { useElementSize } from "hooks/useElementSize";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";

export function CommunicationsWithContext({ ...props }) {
    return <CoordoStatsContextProvider fields={"communications"}>
        <Communications {...props} />
    </CoordoStatsContextProvider>
}

const PARENT_COMMUNICATION_CODE = "Communication avec parent"
const TEACHER_COMMUNICATION_CODE = "Communication avec enseignant"
const INTERVENANT_COMMUNICATION_CODE = "Communication avec intervenant"

function Communications({ className = "" }) {
    const { stats, loading } = useCoordoStats();
    const { t } = useTranslation();
    const { communications, coordoSchoolStats, damStats } = stats || {};
    const damCommunications = damStats?.communications || {};
    const coordoCommunications = communications || {};
    const schoolCommunications = coordoSchoolStats?.communications || {};
    const schoolName = coordoSchoolStats?.schoolName || "École";
    const start = coordoCommunications?.start
    const end = coordoCommunications?.end
    const startYear = start ? new Date(start).getFullYear() : null
    const endYear = end ? new Date(end).getFullYear() : null

    const lastWeekCommunications = stats?.lastWeekCommunications || {}

    // Production data
    const data = {

        coordo: {
            parent: coordoCommunications.parent || 0,
            teacher: coordoCommunications.teacher || 0,
            intervenant: coordoCommunications.intervenant || 0,
            total: coordoCommunications.total || 0
        },
        school: {
            parent: schoolCommunications.parent || 0,
            teacher: schoolCommunications.teacher || 0,
            intervenant: schoolCommunications.intervenant || 0,
            total: schoolCommunications.total || 0
        },
        dam: {
            parent: damCommunications.parent || 0,
            teacher: damCommunications.teacher || 0,
            intervenant: damCommunications.intervenant || 0,
            total: damCommunications.total || 0
        },
    }

    // Options
    const colors = {
        parent: "#003f5c",
        teacher: "#bc5090",
        intervenant: "#ffa600"
    }

    const labels = {
        dam: t("communications-metrics.labels.dam"),
        school: t("communications-metrics.labels.school", { school:schoolName }),
        coordo: t("communications-metrics.labels.coordo")
    }
    const keys = {
        parent: t("communications-metrics.keys.parent"),
        teacher: t("communications-metrics.keys.teacher"),
        intervenant: t("communications-metrics.keys.intervenant")
    }

    return <>
        {
            <>
                <StatsCard title={loading ? t("communications-metrics.title") : undefined} className={classNames(className)}>
                    {!loading && <div className="flex justify-between">
                        <StatsCardTitle>{t("communications-metrics.title-with-period", {period:`${startYear}-${endYear}`})}</StatsCardTitle>
                        <StatsCardInfo title={"Statistiques des communications"}>
                            {t("communications-metrics.description")}
                        </StatsCardInfo>
                    </div>
                    }
                    {
                        loading ? <Skeleton className="min-h-48" />
                            :
                            <>
                                
                                <h3 className="text-gray-500 font-medium">{t("from-last-x-days", {days:"7"})}</h3>
                                <div className="grid grid-cols-4">
                                    
                                    <div className={"!rounded-md h-full w-fit flex flex-col shadow-none !p-0"}>
                                        <p className=''>{t("communications-metrics.keys.parent")}</p>
                                        <p className='text-4xl'>{lastWeekCommunications?.parent}</p>
                                    </div>
                                    <div className={"!rounded-md h-full w-fit flex flex-col shadow-none !p-0"}>
                                        <p className=''>{t("communications-metrics.keys.teacher")}</p>
                                        <p className='text-4xl'>{lastWeekCommunications?.teacher}</p>
                                    </div>
                                    <div className={"!rounded-md h-full w-fit flex flex-col shadow-none !p-0"}>
                                        <p className=''>{t("communications-metrics.keys.intervenant")}</p>
                                        <p className='text-4xl'>{lastWeekCommunications?.intervenant}</p>
                                    </div>
                                    <div className={"!rounded-md h-full w-fit flex flex-col shadow-none !p-0"}>
                                        <p className=''>Total</p>
                                        <p className='text-4xl'>{lastWeekCommunications?.total}</p>
                                    </div>
                                   
                                </div>

                                <h3 className="text-gray-500 font-medium mt-5">{t("from-school-period-start")}</h3>
                                <div className="mb-3">
                                    <BarStats data={data} labels={labels} keys={keys} colors={colors} />
                                </div>
                            </>
                    }
                </StatsCard>
            </>
        }
    </>
}

function BarStats({ data, legend = true, labels, keys, colors }) {
    const defaultColor = "#000000";

    const firstData = data[Object.keys(data)[0]];

    return <>
        {/* Legend */}
        {
            legend && <div className="flex flex-wrap gap-4">
                {
                    Object.keys(firstData).map((subKey, i) => {
                        const color = colors[subKey] || colors.default || defaultColor;
                        if (subKey === 'total') return null;
                        return (
                            <div key={i} className="flex flex-row items-center justify-center gap-2">
                                <div className="w-4 h-4 rounded" style={{ backgroundColor: color }}></div>
                                <p>{keys[subKey]}</p>
                            </div>
                        );
                    })
                }
            </div>
        }

        <div className="w-full flex flex-col gap-2">
            {Object.keys(data).map((key, index) => (
                <div key={index} className="w-full relative">
                    <h4 className="text-gray-500">{labels[key]}</h4>
                    <div className="w-full relative h-[25px] flex rounded overflow-hidden bg-gray-200" style={{ position: 'relative' }}>
                        {Object.keys(data[key]).map((subKey, i) => {
                            const value = data[key][subKey];
                            const total = data[key].total || 1;
                            const percent = (value / total) * 100;
                            const color = colors[subKey] || colors.default || defaultColor;
                            if (percent === 0) return null;
                            if (subKey === 'total') return null;
                            return (
                                <div
                                    key={i}
                                    style={{
                                        width: `${percent}%`,
                                        backgroundColor: color,
                                    }}
                                    className={classNames("h-full", percent === 0 ? "min-w-[30px]" : "min-w-fit px-1")}
                                >
                                    <div className="h-full w-full flex items-center lining-nums tabular-nums justify-center text-white text-sm whitespace-nowrap ">
                                        {value} {percent == 0 ? null : " | " + percent.toFixed(0) + "%"}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    </>;
}


function convertDataToPoints(data) {
    const points = [];

    for (const category in data) {
        const categoryData = data[category];
        const total = categoryData.total || 1;

        for (const key in categoryData) {
            if (key !== 'total') {
                const value = categoryData[key];
                points.push({
                    key: key,
                    raw: value,
                    fx: 1,
                    fy: 0,
                    value: value / total,
                    category: category
                });
            }
        }
    }

    return points;
}

function getColorForCategory(category, colors = {}) {

    if (!colors.default) colors.default = "#000000";

    return colors[category] || colors.default;
}

function Legend({ categories, labels, colors = {} }) {
    return <div className="flex gap-4">
        {categories.map((category, index) => {
            return <div key={index} className="flex flex-row items-center justify-center gap-2">
                <div className="w-4 h-4" style={{ backgroundColor: getColorForCategory(category, colors) }}></div>
                <p>{labels[category] || category}</p>
            </div>
        })}
    </div>
}


export function RadarChart({ data, legend = false, keyLabels = {}, legendLabels = {}, colors = {}, className = "" }) {
    const plotRef = useRef(null);

    const { width } = useElementSize(plotRef);
    const deboucedWidth = useDebounce(width, 100);

    useEffect(() => {
        if (!data || !plotRef.current) return;

        // Transform data to points format and normalize values
        const points = convertDataToPoints(data);

        const longitude = d3.scalePoint(new Set(Plot.valueof(points, "key")), [180, -180]).padding(0.5).align(1)

        const plot = Plot.plot({
            height: (width || 100),
            width: width,
            style: {
                overflow: "visible",
                fontSize: "13px",
            },
            projection: {
                type: "azimuthal-equidistant",
                rotate: [0, -90],
                // Note: 1.22° corresponds to max. percentage (1.0), plus some room for the labels
                domain: d3.geoCircle().center([0, 90]).radius(1.22)()
            },
            facet: {
                data: points,
                y: "fy",
                axis: null
            },
            marks: [
                // grey discs
                Plot.geo([1.0, 0.8, 0.6, 0.4, 0.2], {
                    geometry: (r) => d3.geoCircle().center([0, 90]).radius(r)(),
                    stroke: "black",
                    fill: "black",
                    strokeOpacity: 0.2,
                    fillOpacity: 0.02,
                    strokeWidth: 0.5
                }),

                // white axes
                Plot.link(longitude.domain(), {
                    x1: longitude,
                    y1: 90 - 0.8,
                    x2: 0,
                    y2: 90,
                    stroke: "white",
                    strokeOpacity: 0.5,
                    strokeWidth: 2.5
                }),

                // axes labels, initials
                Plot.text(longitude.domain(), {
                    fx: 0, fy: 0,
                    x: longitude,
                    y: 90 - 1.09,
                    text: d => keyLabels[d] || d,
                    lineWidth: 5,
                    fontSize: 14,
                }),

                // areas
                Plot.area(points, {
                    x1: ({ key }) => longitude(key),
                    y1: ({ value }) => 90 - value,
                    x2: 0,
                    y2: 90,
                    fill: ({ category }) => getColorForCategory(category, colors),
                    fillOpacity: 0.05,
                    stroke: ({ category }) => getColorForCategory(category, colors),
                    curve: "cardinal-closed",
                }),

                // points
                Plot.dot(points, {
                    x: ({ key }) => longitude(key),
                    y: ({ value }) => 90 - value,
                    fill: ({ category }) => getColorForCategory(category, colors),
                    stroke: "white"
                }),

                // interactive labels
                Object.keys(data).length > 1 ?
                    Plot.text(
                        points,
                        Plot.pointer({
                            x: ({ key }) => longitude(key),
                            y: ({ value }) => 90 - value,
                            text: (d) => `${d.raw}\n(${Math.round(100 * d.value)}%)`,
                            textAnchor: "start",
                            dx: 4,
                            fill: "currentColor",
                            stroke: "white",
                            maxRadius: 10,
                            fontSize: 16
                        })
                    )
                    :
                    Plot.text(
                        points,
                        {
                            x: ({ key }) => longitude(key),
                            y: ({ value }) => 90 - value,
                            text: (d) => `${d.raw}\n(${Math.round(100 * d.value)}%)`,
                            textAnchor: "start",
                            dx: 4,
                            fill: "currentColor",
                            stroke: "white",
                            maxRadius: 10,
                            fontSize: 16
                        }
                    )
                ,


            ]
        })

        plotRef.current.appendChild(plot);
        return () => plot.remove();
    }, [data, deboucedWidth]);

    return <>
        <div>
            {legend && <Legend categories={Object.keys(data)} labels={legendLabels} colors={colors} />}
            <div ref={plotRef} className={classNames(Object.keys(data).length > 1 && "hover-area-highlight", className)}></div>
        </div>
    </>
}
