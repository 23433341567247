import React from 'react';
import classnames from 'classnames';

// Components
import { Transition } from '@headlessui/react';
import { Dropdown } from 'components/core/dropdown';
import { DoubleMonthView } from 'components/tools/double_month_view';
import { Button } from 'components/core/button';
import { EmptyState } from 'components/core/empty';
import { Fragment } from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

// Utils
import { locale } from 'utils/locale';
import { format } from 'd3-format';
const fullDayFormatWithTime = locale.format("Le %A, %d %B %Y à %H:%M");
const fullDayFormat = locale.format("Le %A, %d %B %Y");
const baseDayFormat = locale.format("%d %B %Y");

export function SelectDate({label, description, xsDescription, includeDescriptionInDropdown, value, dateFormat, showLabelInDropdown, onlyIcon, includeTime, numMonths=1, setValue, canRemoveSelection=true,  ...props}){
  const { t } = useTranslation("common");
  const displayedValue = value? (dateFormat? locale.format(dateFormat)(value):(includeTime?fullDayFormatWithTime:fullDayFormat)(value)) : null;
  return <><Dropdown 
            label={onlyIcon? null: displayedValue? displayedValue: (label || t("select-date"))} 
            onlyIcon={onlyIcon}
            menuItemsClassName='min-w-2xl'
            color={value? onlyIcon? 'activeColor': 'selected': 'inactive'} {...props}>
          {showLabelInDropdown?
            displayedValue?
            <Dropdown.Info size="lg" title={displayedValue} description={(label || t("select-date"))} />:
            <Dropdown.Info size="lg" title={(label || t("select-date"))} />:
            null
          }
          <div className="w-screen max-w-sm p-3 mx-auto">

            <DoubleMonthView 
              layout="full"
              numMonths={numMonths} 
              events={[{date:value, color: '#de2d26'}]} 
              onClick={(d)=>{
                if (!includeTime) setValue(d);
                else {
                  if (value){
                    const next = d;
                    next.setHours(value.getHours())
                    next.setMinutes(value.getMinutes())
                    setValue(next);
                    return;
                  }
                  setValue(d);
                }
              }}/>
          </div>
          {includeTime &&
             <div className="flex items-center px-3 py-2 mb-2 bg-orange-50 border-orange-500 border-2 rounded-lg justify-between">
              <label>Heure </label>
              <input className="input-time" 
                    type="time" 
                    id="startAt"
                    name="startAt" 
                    min="06:00" 
                    max="22:00" 
                    defaultValue={value &&`${format("02")(value.getHours())}:${format("02")(value.getMinutes())}`}
                    onChange={(e)=>{
                      const minutes = parseInt(e.currentTarget.value.split(":")[1]);
                      const hours = parseInt(e.currentTarget.value.split(":")[0]);
                      if (value){
                        const d = new Date(value);
                        d.setHours(hours);
                        d.setMinutes(minutes);

                        setValue(d)
                      }
                    }}
                    required/>
            </div>
          }
          {value && canRemoveSelection && <Dropdown.Item name={t("remove-selection")} onClick={()=>setValue()}/>}
            {includeDescriptionInDropdown && <p className="max-w-sm text-gray-500 text-sm mb-2 rounded-md py-1 px-2">{xsDescription}</p>}
         </Dropdown>
          {description && <p className="text-gray-500 text-sm">{description}</p>}
          {xsDescription && <p className="text-gray-500 text-xs">{xsDescription}</p>}

         </>
}

export function SelectDateInterval({label, value, setValue, missingToDateIsNow, canRemoveSelection=true, events, intervals, ...props}){
  const { t } = useTranslation("common");
  const handleClick = useCallback((val)=>{
    setValue(prev=>{
      if (!prev || !prev.fromDate) return {fromDate: val, toDate: null};
      if (!prev.toDate){
        if (prev.fromDate>=val){
          return {fromDate: val, toDate: null};
        }
        return {fromDate: prev.fromDate, toDate: val};
      }
      return {fromDate: val, toDate: null};
    })
  }, [setValue])

  return <Dropdown 
            label={value? `${baseDayFormat(value.fromDate)} ${value.toDate? "au "+baseDayFormat(value.toDate): (missingToDateIsNow? "à aujourd'hui" :"-")}`: (label || t("select-interval"))} 
            menuItemsClassName='min-w-2xl'
            style={value?'selected': 'inactive'} {...props}>
          <div className="w-screen max-w-sm p-3 mx-auto">
            <DoubleMonthView layout="full" numMonths={1} intervals={intervals} interval={missingToDateIsNow? (value && !value.toDate? {fromDate: value.fromDate, toDate: new Date()} :value): value} events={events} onClick={handleClick}/>
          </div>
          {value && canRemoveSelection && <Dropdown.Item name={t("remove-selection")} onClick={()=>setValue()}/>}
         </Dropdown>
}

export function SelectDateList({value, setValue, canRemoveSelection=true, ...props}){
  const { t } = useTranslation("common");

  const handleClick = useCallback((val)=>{
    setValue(prev=>{
      if (!prev || prev.length===0) return [val];
      return [...prev, val]
    })
  }, [setValue])
  return <Dropdown 
            label={`${value? value.length: "0"} date${value && value.length>1? "s":""}`}
            menuItemsClassName='min-w-2xl'
            style={value?'selected': 'inactive'} {...props}>
          <div className="w-screen max-w-sm p-3 mx-auto">
            <p className="mb-3 text-gray-500">{t("select-date-list-instructions")}</p>
            <DoubleMonthView 
                layout="full" 
                numMonths={1} 
                interval={value && value.length>1 && {fromDate: value[0], toDate: value[value.length-1]}} 
                minDateSelection={value && value.length>0 && value[value.length-1]}
                events={value && value.map(d=>({date: d, color: "black"}))} 
                onClick={handleClick}/>
          </div>
          <div className="space-y-2 px-3">
            {(!value || value.length===0) && <EmptyState description={t("no-dates-selected")}/>}
            {value && value.length>0 &&
              <Fragment>
                {value.map((d,i)=><p key={i} className="bg-gray-100 px-2 py-1 mr-2 rounded-md inline-flex">{baseDayFormat(d)}</p>)}
              </Fragment>
            }
          </div>
          {value && canRemoveSelection && <Dropdown.Item name={t("remove-selection")} onClick={()=>setValue([])}/>}
         </Dropdown>
}



export function SelectDateUncollapsed({value, setValue, collapseOnSelection=true, className}){
  const { t } = useTranslation("common");

  return <div className={classnames("w-full", className)}>
          <Transition
            show={collapseOnSelection? !value: true}
            enter="transition-all duration-150"
            enterFrom="opacity-0 scale-50"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-150"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-0"
          >
          <div className="w-full max-w-sm mx-auto p-3">
            <DoubleMonthView layout="full" className="px-6 w-full min-w-max max-w-sm"  numMonths={1} events={[{date:value, color: "#de2d26"}]} onClick={setValue}/>
          </div>
      </Transition>
          {value && 
            <div className="w-full flex items-center bg-gray-100 rounded-md p-3 justify-between">
              <span className="font-medium ">{fullDayFormat(value)}</span>
              <Button color="delete" size="md" onClick={()=>setValue()}>{t("remove-selection")}</Button>
            </div>}
         </div>
}