export const allCountries = [
	{id: "CA", name : "Canada"},
	{id: "AD", name : "Andorre"},
	{id: "AE", name : "Émirats Arabes Unis"},
	{id: "AF", name : "Afghanistan"},
	{id: "AG", name : "Antigua-Et-Barbuda"},
	{id: "AI", name : "Anguilla"},
	{id: "AL", name : "Albanie"},
	{id: "AM", name : "Arménie"},
	{id: "AO", name : "Angola"},
	{id: "AP", name : "Région Asie/Pacifique"},
	{id: "AQ", name : "Antarctique"},
	{id: "AR", name : "Argentine"},
	{id: "AS", name : "Samoa Américaines"},
	{id: "AT", name : "Autriche"},
	{id: "AU", name : "Australie"},
	{id: "AW", name : "Aruba"},
	{id: "AX", name : "Îles Åland"},
	{id: "AZ", name : "Azerbaïdjan"},
	{id: "BA", name : "Bosnie-Herzégovine"},
	{id: "BB", name : "Barbad"},
	{id: "BD", name : "Bangladesh"},
	{id: "BE", name : "Belgique"},
	{id: "BF", name : "Burkina Faso"},
	{id: "BG", name : "Bulgarie"},
	{id: "BH", name : "Bahreïn"},
	{id: "BI", name : "Burundi"},
	{id: "BJ", name : "Bénin"},
	{id: "BL", name : "Saint-Barthélemy"},
	{id: "BM", name : "Bermudes"},
	{id: "BN", name : "Brunei Darussalam"},
	{id: "BO", name : "État Plurinational De Bolivie"},
	{id: "BQ", name : "Bonaire, Saint-Eustache Et Saba"},
	{id: "BR", name : "Brésil"},
	{id: "BS", name : "Bahamas"},
	{id: "BT", name : "Bhoutan"},
	{id: "BV", name : "Île Bouvet"},
	{id: "BW", name : "Botswana"},
	{id: "BY", name : "Biélorussie"},
	{id: "BZ", name : "Belize"},
	{id: "CC", name : "Îles Cocos"},
	{id: "CD", name : "République Démocratique Du Congo"},
	{id: "CF", name : "République Centrafricaine"},
	{id: "CG", name : "Congo"},
	{id: "CH", name : "Suisse"},
	{id: "CI", name : "Côte D'Ivoire"},
	{id: "CK", name : "Îles Cook"},
	{id: "CL", name : "Chili"},
	{id: "CM", name : "Cameroun"},
	{id: "CN", name : "Chine"},
	{id: "CO", name : "Colombie"},
	{id: "CR", name : "Costa Rica"},
	{id: "CU", name : "Cuba"},
	{id: "CV", name : "Cap-Vert"},
	{id: "CW", name : "Curaçao"},
	{id: "CX", name : "Île Christmas"},
	{id: "CY", name : "Chypre"},
	{id: "CZ", name : "République Tchèque"},
	{id: "DE", name : "Allemagne"},
	{id: "DJ", name : "Djibouti"},
	{id: "DK", name : "Denmark"},
	{id: "DM", name : "Dominique"},
	{id: "DO", name : "République Dominicaine"},
	{id: "DZ", name : "Algérie"},
	{id: "EC", name : "Équateur"},
	{id: "EE", name : "Estonie"},
	{id: "EG", name : "Égypte"},
	{id: "EH", name : "Sahara Occidental"},
	{id: "ER", name : "Érythrée"},
	{id: "ES", name : "Espagne"},
	{id: "ET", name : "Éthiopie"},
	{id: "EU", name : "Europe"},
	{id: "FI", name : "Finlande"},
	{id: "FJ", name : "Fidji"},
	{id: "FK", name : "Îles Malouines"},
	{id: "FM", name : "États Fédérés De Micronésie"},
	{id: "FO", name : "Îles Féroé"},
	{id: "FR", name : "France"},
	{id: "GA", name : "Gabon"},
	{id: "GB", name : "Royaume-Uni"},
	{id: "GD", name : "Grenade"},
	{id: "GE", name : "Géorgie"},
	{id: "GF", name : "Guyane"},
	{id: "GG", name : "Guernesey"},
	{id: "GH", name : "Ghana"},
	{id: "GI", name : "Gibraltar"},
	{id: "GL", name : "Groenland"},
	{id: "GM", name : "Gambie"},
	{id: "GN", name : "Guinée"},
	{id: "GP", name : "Guadeloupe"},
	{id: "GQ", name : "Guinée Équatoriale"},
	{id: "GR", name : "Grèce"},
	{id: "GS", name : "Géorgie Du Sud-Et-Les Îles Sandwich Du Sud"},
	{id: "GT", name : "Guatemala"},
	{id: "GU", name : "Guam"},
	{id: "GW", name : "Guinée-Bissau"},
	{id: "GY", name : "Guyana"},
	{id: "HK", name : "Hong Kong"},
	{id: "HM", name : "Îles Heard-Et-MacDonald"},
	{id: "HN", name : "Honduras"},
	{id: "HR", name : "Croatie"},
	{id: "HT", name : "Haïti"},
	{id: "HU", name : "Hongrie"},
	{id: "ID", name : "Indonésie"},
	{id: "IE", name : "Irlande"},
	{id: "IL", name : "Israël"},
	{id: "IM", name : "Île De Man"},
	{id: "IN", name : "Inde"},
	{id: "IO", name : "Territoire Britannique De L'océan Indien"},
	{id: "IQ", name : "Irak"},
	{id: "IR", name : "République Islamique D'Iran"},
	{id: "IS", name : "Islande"},
	{id: "IT", name : "Italie"},
	{id: "JE", name : "Jersey"},
	{id: "JM", name : "Jamaïque"},
	{id: "JO", name : "Jordanie"},
	{id: "JP", name : "Japon"},
	{id: "KE", name : "Kenya"},
	{id: "KG", name : "Kirghizistan"},
	{id: "KH", name : "Cambodge"},
	{id: "KI", name : "Kiribati"},
	{id: "KM", name : "Comores"},
	{id: "KN", name : "Saint-Christophe-et-Niévès"},
	{id: "KP", name : "République Populaire Démocratique De Corée"},
	{id: "KR", name : "République De Corée"},
	{id: "KW", name : "Koweït"},
	{id: "KY", name : "Îles Caïmans"},
	{id: "KZ", name : "Kazakhstan"},
	{id: "LA", name : "République Démocratique Populaire Lao"},
	{id: "LB", name : "Liban"},
	{id: "LC", name : "Sainte-Lucie"},
	{id: "LI", name : "Liechtenstein"},
	{id: "LK", name : "Sri Lanka"},
	{id: "LR", name : "Liberia"},
	{id: "LS", name : "Lesotho"},
	{id: "LT", name : "Lituanie"},
	{id: "LU", name : "Luxembourg"},
	{id: "LV", name : "Lettonie"},
	{id: "LY", name : "Libye"},
	{id: "MA", name : "Maroc"},
	{id: "MC", name : "Monaco"},
	{id: "MD", name : "République De Moldavie"},
	{id: "ME", name : "Monténégro"},
	{id: "MF", name : "Saint-Martin (Partie Française)"},
	{id: "MG", name : "Madagascar"},
	{id: "MH", name : "Îles Marshall"},
	{id: "MK", name : "Macédoine"},
	{id: "ML", name : "Mali"},
	{id: "MM", name : "Birmanie"},
	{id: "MN", name : "Mongolie"},
	{id: "MO", name : "Macao"},
	{id: "MP", name : "Îles Mariannes Du Nord"},
	{id: "MQ", name : "Martinique"},
	{id: "MR", name : "Mauritanie"},
	{id: "MS", name : "Montserrat"},
	{id: "MT", name : "Malte"},
	{id: "MU", name : "Maurice"},
	{id: "MV", name : "Maldives"},
	{id: "MW", name : "Malawi"},
	{id: "MX", name : "Mexique"},
	{id: "MY", name : "Malaisie"},
	{id: "MZ", name : "Mozambique"},
	{id: "NA", name : "Namibie"},
	{id: "NC", name : "Nouvelle-Calédonie"},
	{id: "NE", name : "Niger"},
	{id: "NF", name : "Île Norfolk"},
	{id: "NG", name : "Nigéria"},
	{id: "NI", name : "Nicaragua"},
	{id: "NL", name : "Pays-Bas"},
	{id: "NO", name : "Norvège"},
	{id: "NP", name : "Népal"},
	{id: "NR", name : "Nauru"},
	{id: "NU", name : "Niue"},
	{id: "NZ", name : "Nouvelle-Zélande"},
	{id: "OM", name : "Oman"},
	{id: "PA", name : "Panama"},
	{id: "PE", name : "Pérou"},
	{id: "PF", name : "Polynésie Française"},
	{id: "PG", name : "Papouasie-Nouvelle-Guinée"},
	{id: "PH", name : "Philippines"},
	{id: "PK", name : "Pakistan"},
	{id: "PL", name : "Pologne"},
	{id: "PM", name : "Saint-Pierre-Et-Miquelon"},
	{id: "PN", name : "Pitcairn"},
	{id: "PR", name : "Porto Rico"},
	{id: "PS", name : "Territoires Palestiniens Occupés"},
	{id: "PT", name : "Portugal"},
	{id: "PW", name : "Palaos"},
	{id: "PY", name : "Paraguay"},
	{id: "QA", name : "Qatar"},
	{id: "RE", name : "Réunion"},
	{id: "RO", name : "Roumanie"},
	{id: "RS", name : "Serbie"},
	{id: "RU", name : "Fédération De Russie"},
	{id: "RW", name : "Rwanda"},
	{id: "SA", name : "Arabie Saoudite"},
	{id: "SB", name : "Îles Salomon"},
	{id: "SC", name : "Seychelles"},
	{id: "SD", name : "Soudan"},
	{id: "SE", name : "Suède"},
	{id: "SG", name : "Singapour"},
	{id: "SH", name : "Sainte-Hélène"},
	{id: "SI", name : "Slovénie"},
	{id: "SJ", name : "Svalbard Et Jan Mayen"},
	{id: "SK", name : "Slovaquie"},
	{id: "SL", name : "Sierra Leone"},
	{id: "SM", name : "Saint-Marin"},
	{id: "SN", name : "Sénégal"},
	{id: "SO", name : "Somalie"},
	{id: "SR", name : "Suriname"},
	{id: "SS", name : "Soudan Du Sud"},
	{id: "ST", name : "Sao Tomé-Et-Principe"},
	{id: "SV", name : "République Du Salvador"},
	{id: "SX", name : "Saint-Martin (Partie Néerlandaise)"},
	{id: "SY", name : "République Arabe Syrienne"},
	{id: "SZ", name : "Swaziland"},
	{id: "TC", name : "Îles Turks-Et-Caïcos"},
	{id: "TD", name : "Tchad"},
	{id: "TF", name : "Terres Australes Françaises"},
	{id: "TG", name : "Togo"},
	{id: "TH", name : "Thaïlande"},
	{id: "TJ", name : "Tadjikistan"},
	{id: "TK", name : "Tokelau"},
	{id: "TL", name : "Timor-Leste"},
	{id: "TM", name : "Turkménistan"},
	{id: "TN", name : "Tunisie"},
	{id: "TO", name : "Tonga"},
	{id: "TR", name : "Turquie"},
	{id: "TT", name : "Trinité-Et-Tobago"},
	{id: "TV", name : "Tuvalu"},
	{id: "TW", name : "Taïwan"},
	{id: "TZ", name : "République-Unie De Tanzanie"},
	{id: "UA", name : "Ukraine"},
	{id: "UG", name : "Ouganda"},
	{id: "UM", name : "Îles Mineures Éloignées Des États-Unis"},
	{id: "US", name : "États-Unis"},
	{id: "UY", name : "Uruguay"},
	{id: "UZ", name : "Ouzbékistan"},
	{id: "VA", name : "Saint-Siège (État De La Cité Du Vatican)"},
	{id: "VC", name : "Saint-Vincent-Et-Les Grenadines"},
	{id: "VE", name : "Venezuela"},
	{id: "VG", name : "Îles Vierges Britanniques"},
	{id: "VI", name : "Îles Vierges Des États-Unis"},
	{id: "VN", name : "Viet Nam"},
	{id: "VU", name : "Vanuatu"},
	{id: "WF", name : "Wallis Et Futuna"},
	{id: "WS", name : "Samoa"},
	{id: "YE", name : "Yémen"},
	{id: "YT", name : "Mayotte"},
	{id: "ZA", name : "Afrique Du Sud"},
	{id: "ZM", name : "Zambie"},
	{id: "ZW", name : "Zimbabwe"},
	]