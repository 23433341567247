import React from 'react';

// Components
import { FiMail } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { MailTypesList } from 'components/mail-types/list';
import { Info } from 'components/core/info';
import { MailsCard } from 'components/tutors/mails';

function SettingsMailTypes(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('automatic-emails')}</h1>
            <MailTypesList/>
            <Info.Container modify={true} className={"mt-8"}>
              <MailsCard all/>
            </Info.Container>
          </div>
}
const route =  { path: "mail-types", 
                 name: "Courriels automatiques" , 
                 group: "tools",
                 Icon: FiMail,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "RSI", "Développeur"],
                 Element: SettingsMailTypes };
export default route;
