import React from 'react';
import classnames from 'classnames';

import {allCountries} from 'const/countries-fr';
// Components
import { SearchField } from 'components/tools/search_field'; 

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';

// const countriesUnsorted = Object.keys(countries).map((d)=>({id: d, name: countries[d].name}));
// Place Canada at the top of the list
// console.log(countries)
// const canada = {id: "CA", name: countries["CA"].name};
// const allCountries = [canada, ...countriesUnsorted.filter(d=>d.id !== "CA")];

export function SearchCountry({ value, setValue, label, to, disabled, color, targetWidth, size }){
  const { t } = useTranslation("common");
  const [search, setSearch] = useState();
  const controlledValue = useMemo(()=>!value? allCountries[0]: !value.id? Object.values(allCountries).find(d=>d.name === value): value, [value]);
  const countries = useMemo(()=>allCountries.filter(d=>d.name.toLowerCase().includes((search && search.search &&search.search.length>1 && search.search.toLowerCase())|| ""), [search]));
  return <SearchField values={countries}
                      loading={false}
                      value={controlledValue}
                      setValue={setValue}
                      label={label}
                      disabled={disabled}
                      color={color}
                      size={size}
                      to={to}
                      targetWidth={targetWidth}
                      placeholder={t("country")}
                      indexingField={"id"}
                      formatSelectedValue={(d=>d.name)}
                      formatSearchResult={((country, {selected, active})=>
                        <div>
                          <span className={classnames('block truncate', (active || selected) && 'font-semibold')}>{country.name}</span>
                        </div>)}
                     onParamsChange={setSearch}
  />

}
