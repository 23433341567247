import { fetchCall } from 'api';
import { isString, isBool } from 'utils/var_check';

/*
  Search students
*/
export function searchStudents({ search,
    preset,
    schoolYear,
    studySessionVerificationFlag,
    returnSiblings,
    returnPreferedSubjects,
    returnActivePairings,
    returnDocuments,
    returnFails,
    leftService,
    serviceIsEnded,
    availableForPairing,
    hasBeenMet,
    team,
    failedClassesClosedBefore,
    failedClassesOpenAfter,
    period,
    paired,
    activeSinceFrom,
    activeSinceTo,
    fails,
    fields,
    validForPairing,
    school,
    page, limit, orderBy, orderDirection }) {
    const options = {
        method: "GET",
        endpoint: "/students",
        query: {
            search,
            page,
            studySessionVerificationFlag,
            preset: preset && preset.id,
            school: school && school.slug,
            schoolYear: schoolYear && schoolYear.slug,
            team: team && (isString(team) ? team : team.slug),
            leftService: leftService && (isBool(leftService) ? leftService : leftService.value),
            hasBeenMet: hasBeenMet && (isBool(hasBeenMet) ? hasBeenMet : hasBeenMet.value),
            serviceIsEnded: serviceIsEnded && (isBool(serviceIsEnded) ? serviceIsEnded : serviceIsEnded.value),
            period: period && (isString(period) ? period : period.slug),
            fails: fails && (isBool(fails) ? fails : fails.value),
            paired: paired && (isBool(paired) ? paired : paired.value),
            activeSinceFrom: activeSinceFrom && activeSinceFrom.toISOString(),
            activeSinceTo: activeSinceTo && activeSinceTo.toISOString(),
            fields,
            returnSiblings,
            returnFails,
            returnPreferedSubjects,
            returnDocuments,
            failedClassesClosedBefore: failedClassesClosedBefore && failedClassesClosedBefore.toISOString().slice(0, 10),
            failedClassesOpenAfter: failedClassesOpenAfter && failedClassesOpenAfter.toISOString().slice(0, 10),
            validForPairing,
            returnActivePairings,
            availableForPairing: availableForPairing && (isBool(availableForPairing) ? availableForPairing : availableForPairing.value),
            limit,
            orderBy,
            orderDirection
        }
    }
    return fetchCall(options)
}

/*
  Retrieve a student
*/
export const retrieveStudent = ({ ni, fields }) => {
    const options = {
        method: "GET",
        endpoint: `/students/${ni}`,
        query: { fields }
    }
    return fetchCall(options)
};

/*
  Delete a student
*/
export const deleteStudent = ({ ni }) => {
    const options = {
        method: "DELETE",
        endpoint: `/students/${ni}`,
    }
    return fetchCall(options)
};

/*
  Close all active students
*/
export const closeAllActiveStudents = ({ endedAt, reason }) => {
    const options = {
        method: "POST",
        endpoint: "/close-all-active-students",
        body: { endedAt: endedAt.toISOString().slice(0, 10), reason }
    }
    return fetchCall(options)
};


/*
  Update a student
*/
export function updateStudent({ ni,
    name,
    firstname,
    lastname,
    email,
    email2,
    phone,
    cellphone,
    motivation,
    schoolProfile,
    countryBorn,
    parentCountryBorn,
    francization,
    workHours,
    parentMotivation,
    futureDreams,
    participateToHc,
    scheduleAvailabilities,
    professionalComments,
    particularNeeds,
    studySessionVerificationFlag,
    preferedCommunication,
    school,
    tutoringMeetingAndValidation,
    demandForTutoring,
    schoolYear,
    folderNumber,
    reasonForEnd,
    startedUsingService,
    groupClass,
    yearRetained,
    gender,
    onlinePreference,
    onSitePreference,
    hybridPreference,
    prioritaryForTutoring,
    socialNetworkName,
    notebookNumber,
    endedUsingService }) {
    const options = {
        method: "POST",
        endpoint: `/students/${ni}`,
        body: {
            name,
            firstname,
            lastname,
            email,
            email2,
            phone,
            cellphone,
            motivation,
            schoolProfile,
            countryBorn,
            parentCountryBorn,
            francization,
            workHours,
            parentMotivation,
            futureDreams,
            participateToHc,
            scheduleAvailabilities,
            professionalComments,
            studySessionVerificationFlag,
            particularNeeds,
            preferedCommunication,
            prioritaryForTutoring,
            school,
            schoolYear,
            folderNumber,
            groupClass,
            gender,
            reasonForEnd,
            socialNetworkName,
            tutoringMeetingAndValidation,
            demandForTutoring: demandForTutoring == undefined ? undefined : demandForTutoring ? demandForTutoring.toISOString().slice(0, 10) : null,
            yearRetained,
            notebookNumber,
            onlinePreference,
            onSitePreference,
            hybridPreference,
            startedUsingService,
            endedUsingService
        }
    }
    return fetchCall(options)
}




/*
  Create a student
*/
export function createStudent({ email,
    firstname,
    lastname,
    email2,
    cellphone,
    motivation,
    phone,
    particularNeeds,
    schoolProfile,
    countryBorn,
    parentCountryBorn,
    preferedCommunication,
    school,
    francization,
    schoolYear,
    prioritaryForTutoring,
    teams,
    siblings,
    schoolFailed,
    folderNumber,
    groupClass,
    preferences,
    socialNetworkName,
    scheduleAvailabilities,
    notebookNumber,
    onlinePreference,
    demandForTutoring,
    onSitePreference,
    workHours,
    parentMotivation,
    futureDreams,
    participateToHc,
    hybridPreference,
    startedUsingService,
    endedUsingService }) {
    const options = {
        method: "POST",
        endpoint: `/students`,
        body: {
            email,
            firstname,
            lastname,
            email2,
            francization,
            workHours,
            parentMotivation: parentMotivation && parentMotivation.value,
            futureDreams,
            participateToHc,
            demandForTutoring: demandForTutoring == undefined ? undefined : demandForTutoring ? demandForTutoring.toISOString().slice(0, 10) : null,
            cellphone,
            prioritaryForTutoring,
            motivation: motivation && motivation.value,
            phone,
            schoolProfile: schoolProfile && schoolProfile.slug,
            countryBorn: countryBorn && countryBorn.name,
            parentCountryBorn: parentCountryBorn && parentCountryBorn.name,
            particularNeeds,
            scheduleAvailabilities,
            preferedCommunication: preferedCommunication && preferedCommunication.slug,
            school: school && school.slug,
            schoolYear: schoolYear && schoolYear.slug,
            teams,
            schoolClassFailed: schoolFailed,
            siblings: siblings && siblings.map(d => ({ student: d.student.ni, description: d.description })),
            preferences: preferences && preferences.filter(d => d.description && d.description != "none"),
            folderNumber,
            groupClass,
            onlinePreference,
            onSitePreference,
            hybridPreference,
            socialNetworkName,
            notebookNumber,
            startedUsingService,
            endedUsingService
        }
    }
    return fetchCall(options)
}

/*
  Toogle verification flag
*/
export const toggleVerificationFlag = ({ ni }) => {
    const options = {
        method: "POST",
        endpoint: `/students/${ni}/toggle-verification-flag`,
    }
    return fetchCall(options)
};

/*
  Retrieve a student active pairing
*/
export const retrieveStudentActivePairing = ({ ni }) => {
    const options = {
        method: "GET",
        endpoint: `/students/${ni}/active-pairing`,
    }
    return fetchCall(options)
};

/*
  Search pairings
*/
export const searchPairings = ({ ni, page, limit, orderBy, orderDirection }) => {
    const options = {
        method: "GET",
        endpoint: `/students/${ni}/pairings`,
        query: { page, limit, orderBy, orderDirection }
    }
    return fetchCall(options)
};

/*
  Retrieve a student study sessions
*/
export const retrieveStudentStudySessions = ({ ni, page, limit, orderBy, orderDirection }) => {
    const options = {
        method: "GET",
        endpoint: `/students/${ni}/study-sessions`,
        query: { page, limit, orderBy, orderDirection }
    }
    return fetchCall(options)
};

/*
  Search a list of failed classes
*/
export const searchStudentFailedClasses = ({ ni, archived, page, limit, orderBy, orderDirection }) => {
    const options = {
        method: "GET",
        endpoint: `/students/${ni}/failed-classes`,
        query: { archived, page, limit, orderBy, orderDirection }
    }
    return fetchCall(options)
};


/*
  Search a list of failed classes
*/
export const searchStudentFailedYears = ({ student, page, limit, orderBy, orderDirection }) => {
    const options = {
        method: "GET",
        endpoint: `/school-year-failed`,
        query: { student, page, limit, orderBy, orderDirection }
    }
    return fetchCall(options)
};
/** Add school year failed */
export const addSchoolYearFailed = ({ student, schoolYear, period, description }) => {
    const options = {
        method: "POST",
        endpoint: `/school-year-failed`,
        body: { student: student && student.ni, schoolYear: schoolYear && schoolYear.slug, period: period && period.slug, description }
    }
    return fetchCall(options)
};

/*Delete school year failed */
export const deleteSchoolYearFailed = ({ id }) => {
    const options = {
        method: "DELETE",
        endpoint: `/school-year-failed/${id}`,
    }
    return fetchCall(options)
};


/*
  Retrieve subject preferences
*/
export const retrieveSubjectPreferences = ({ ni }) => {
    const options = {
        method: "GET",
        endpoint: `/students/${ni}/subject-preferences`,
    }
    return fetchCall(options)
};
/*
  Update subject preferences
*/
export const updateSubjectPreferences = ({ ni, subject, description }) => {
    const options = {
        method: "POST",
        endpoint: `/students/${ni}/subject-preferences`,
        body: { subject: subject && subject.slug, description }
    }
    return fetchCall(options)
};


/*
Validate bulk insert
 */
export const validateBulkInsert = ({ students, school, period }) => {
    const options = {
        method: "POST",
        endpoint: `/validate-bulk-insert-students`,
        body: { students, school, period }
    }
    return fetchCall(options)
}

/*
Bulk insert
 */
export const bulkInsertStudents = ({ students, school, period }) => {
    const options = {
        method: "POST",
        endpoint: `/bulk-insert-students`,
        body: { students, school, period }
    }
    return fetchCall(options)
}

/*
  Open student
*/
export const openStudent = ({ ni, period }) => {
    const options = {
        method: "POST",
        endpoint: `/students/${ni}/open`,
        body: { period: period && (isString(period) ? period : period.slug) }
    }
    return fetchCall(options)
};

/*
  Close student
*/
export const closeStudent = ({ ni, endedAt, reason }) => {
    const options = {
        method: "POST",
        endpoint: `/students/${ni}/close`,
        body: { endedAt: endedAt.toISOString().slice(0, 10), reason }
    }
    return fetchCall(options)
};

/*
 Students meeting infos
*/
export const getStudentsMeetingsInfos = ({ team }) => {

    const options = {
        method: "GET",
        endpoint: `/list-students-with-meetings-info`,
        query: { teamId: team }
    }
    return fetchCall(options)
}