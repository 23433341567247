import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"

const scoresDefinitions = {
    0 : [0, 3],
    1 : [4, 9],
    2 : [10, 15],
    3 : [16, 19],
    4 : [20, 49],
    5 : [50, 100],
}

const globalScoreDefinitions = {
    "-" : [20, 100],
    "+-" : [11, 19],
    "+" : [0, 10]
}


export function SocioAffectifs({...props}) {
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.socio-affectifs.title")}
            projectedStatKey="nbSocioAffectifs"
            infoDescription={t("students-metrics.socio-affectifs.description")}
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions} 
            className="border-t-4 border-blue-500"
            scoreClassName="!text-blue-500"
            negative
            {...props}
        />
    </>
}

