import React from "react"

// Components
import { StudentsMetricsCard } from "components/coordo-stats/students-metrics"

// Hooks
import { useTranslation } from "react-i18next"

const scoresDefinitions = {
    0 : [0, 10],
    1 : [11, 19],
    2 : [20, 29],
    3 : [30, 45],
    4 : [46, 49],
    5 : [50, 100],
}

const globalScoreDefinitions = {
    "-" : [40, 100],
    "+-" : [30, 39],
    "+" : [0, 29]
}

export function Fails({...props}) {
    const { t } = useTranslation();
    return <>
        <StudentsMetricsCard 
            title={t("students-metrics.fails.title")}
            projectedStatKey="nbFails"
            infoDescription={t("students-metrics.fails.description")} 
            scoresDefinitions={scoresDefinitions} 
            globalScoreDefinitions={globalScoreDefinitions} 
            negative
            className="border-t-4 border-red-500"
            scoreClassName="!text-red-500"
            {...props}
        />
    </>
}

