import React, { useEffect, useCallback, useMemo } from 'react';

// Components
import { Skeleton } from 'components/core/skeleton';
import { SingleLineField, ModifiableSelectField, ModifiableMultipleSelectField } from 'components/tools/modifiable_fields';
import { SelectSchool } from 'components/tools/schools_list';
import { SearchCountry } from 'components/tools/search_country';
import { SelectSchoolYear } from 'components/tools/school_year_list';
import { SelectPreferedComm } from 'components/tools/prefered_communication_list';
import { SelectDate } from 'components/tools/select_date';
import { NewSibling } from 'components/tools/new_sibling';
import { NewTeamMember } from 'components/tools/new_team_member';
import { Info } from 'components/core/info';
import { Error, TextErrorStructured, InfoSectionHeading } from 'components/core/typo'
import { Button } from 'components/core/button';
import { FiLock, FiSmile } from 'react-icons/fi';
import { SelectPeriods } from 'components/tools/select_period';
import { SelectSettings } from 'components/end-year-assistant/coachs/settings';
import { FieldsSection } from 'components/core/fields-section';
import toast from 'react-hot-toast';

// Utils
import { locale, dateParse } from 'utils/locale';
import classnames from 'classnames';
import { getFullCurrentPeriod } from 'utils/period';

// Contexts
import { StudentContext } from 'contexts/student';
import { StudentsContext } from 'contexts/students';

// Hooks
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAPI } from 'hooks/useAPI';

// API
import { deleteSibling } from 'api/siblings';
import { deleteTeamMember } from 'api/teams';
import { updateStudent, deleteStudent, openStudent, closeStudent } from 'api/students';
import { SelectSchoolProfile } from 'components/tools/select_school_profile';
import { CheckBox } from 'components/tools/checkbox';
import { SelectBool } from 'components/tools/select_bool';
import { SelectMotivation } from 'components/tools/select_motivation';
import { isString } from 'utils/var_check';
import { SelectGender } from 'components/tools/select_gender';


function DeleteButton({ ni }) {
  const { t } = useTranslation('common');
  const [lock, setLock] = useState(true);
  const navigate = useNavigate();

  const handleDelete = useCallback(() => {
    navigate(`/students`);
    toast.success(t("delete-student.success"), { duration: 3000 })
  }, [])

  const [, { execute, loading, error }] = useAPI(deleteStudent,
    { ni },
    { onResult: handleDelete, immediate: false })

  return <div className="relative">
    {lock && <div className="absolute z-10 flex items-center justify-center w-full h-full text-center">
      <div>
        <h3 className="pb-3 text-lg text-gray-600">{t("delete-student.title")}</h3>
        <Button color="deleteContrast" size="lg" onClick={() => setLock(false)}><FiLock /> {t("delete-student.unlock-section")}</Button>
      </div>
    </div>}
    <div className={classnames(" rounded-lg p-3 w-full space-y-3", lock ? "opacity-50 blur-sm" : "border-2 border-red-500")}>
      <div>
        <h3 className="font-medium text-red-600">{t("delete-student.title")}</h3>
        <p className="text-gray-500">{t("delete-student.description")}</p>
      </div>
      <Button disabled={lock} loading={loading} onClick={execute} block color="deleteContrast" size="lg" className="whitespace-nowrap">{t("delete-student.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}

export function StudentSettings({ student, setStudent }) {
  const modify = true;
  const { t } = useTranslation('common');
  const [, { error, execute }] = useAPI(updateStudent, {}, { immediate: false, onResult: setStudent })

  const ni = student.ni;
  if (!student.name) {
    return <Skeleton.List numElements={5} />
  }
  return <div className="h-full min-h-screen md:min-h-0 ">
    <h1 className="info-section">{t('personal-info')}</h1>
    {error ? <Error.Text {...error} /> : null}
    <Info.Container modify={modify}>

      <FieldsSection title={t('section-contact')} description={t('section-contact-description')} >
        <div className="grid gap-3 rounded-lg md:gap-6 md:grid-cols-2">
          <SingleLineField
            label={modify && t("firstname")}
            modify={modify}
            value={student.firstname}
            setValue={(firstname) => execute({ ni, firstname })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("firstname").toLowerCase()}`} />}
          </SingleLineField>
          <SingleLineField
            label={modify && t("lastname")}
            modify={modify}
            value={student.lastname}
            setValue={(lastname) => execute({ ni, lastname })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("lastname").toLowerCase()}`} />}
          </SingleLineField>
        </div>
        <SingleLineField
          label={modify && t("email")}
          modify={modify}
          value={student.email}
          setValue={(email) => execute({ ni, email })}>
          {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("email").toLowerCase()}`} />}
        </SingleLineField>
        <SingleLineField
          label={modify && t("email2")}
          modify={modify}
          value={student.email2}
          setValue={(email2) => execute({ ni, email2 })}>
          {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("email2").toLowerCase()}`} />}
        </SingleLineField>

        <div className="grid gap-3 rounded-lg md:gap-6 md:grid-cols-2">
          <SingleLineField
            label={modify && t("phone")}
            modify={modify}
            value={student.phone}
            setValue={(phone) => execute({ ni, phone })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("phone").toLowerCase()}`} />}
          </SingleLineField>
          <SingleLineField
            label={modify && t("cellphone")}
            modify={modify}
            value={student.cellphone}
            setValue={(cellphone) => execute({ ni, cellphone })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("cellphone").toLowerCase()}`} />}
          </SingleLineField>
        </div>
        <SingleLineField
          label={modify && t("social-network-name")}
          modify={modify}
          value={student.social_network_name}
          setValue={(socialNetworkName) => execute({ ni, socialNetworkName })}>
          {(val) => <Info.Field value={val}
            noValueLabel={`${t("none")} ${t("social-network-name").toLowerCase()}`}
            label={t("social-network-name")}
          />}
        </SingleLineField>
        <div className='pt-3 '>
          <ModifiableSelectField
            noBorder={true}
            Field={SelectGender}
            label={modify && t("gender")}
            modify={modify}
            value={student.gender}
            setValue={(v) =>{execute({ ni, gender: v && v.slug })}}>
            {(val) => <Info.Field value={val && (t((val && val.slug) || val))}
              noValueLabel={`${t("none")} ${t("gender").toLowerCase()}`}
              label={t("gender")}
            />}
          </ModifiableSelectField>

        </div>
        <div className='pt-3 '>
          <ModifiableSelectField
            noBorder={true}
            Field={SelectPreferedComm}
            label={modify && t("prefered-communication")}
            modify={modify}
            value={student.prefered_communication}
            setValue={(v) =>{isString(student.prefered_communication)?null :execute({ ni, preferedCommunication: v && v.slug })}}>
            {(val) => <Info.Field value={val && (t((val && val.slug) || val))}
              noValueLabel={`${t("none")} ${t("prefered-communication").toLowerCase()}`}
              label={t("prefered-communication")}
            />}
          </ModifiableSelectField>

        </div>

      </FieldsSection>

      <FieldsSection title={t('section-folder')} description={t('section-folder-description')} >

        <div className="grid gap-3 rounded-lg md:gap-6 md:grid-cols-2">
          <SingleLineField
            label={modify && t("folder-number")}
            modify={modify}
            value={student.folder_number}
            setValue={(folderNumber) => execute({ ni, folderNumber })}>
            {(val) => <Info.Field value={val}
              noValueLabel={`${t("none")} ${t("folder-number").toLowerCase()}`}
              label={t("folder-number")}
            />}
          </SingleLineField>
          <SingleLineField
            label={modify && t("notebook-number")}
            modify={modify}
            value={student.notebook_number}
            setValue={(notebookNumber) => execute({ ni, notebookNumber })}>
            {(val) => <Info.Field value={val}
              noValueLabel={`${t("none")} ${t("notebook-number").toLowerCase()}`}
              label={t("notebook-number")}
            />}
          </SingleLineField>
          <SingleLineField
            label={modify && t("group-class")}
            modify={modify}
            value={student.group_class}
            setValue={(groupClass) => execute({ ni, groupClass })}>
            {(val) => <Info.Field value={val}
              noValueLabel={`${t("none")} ${t("group-class").toLowerCase()}`}
              label={t("group-class")}
            />}
          </SingleLineField>


        </div>
      </FieldsSection>

      <FieldsSection title={t('section-school')} description={t('section-school-description')} >
          <ModifiableSelectField
            noBorder={true}
            Field={SelectSchool}
            disabledClear
            label={modify && t("school")}
            modify={modify}
            value={student.school}
            setValue={(school) => execute({ ni, school: school && school.slug })} >
            {(val) => <Info.Field value={val && val.name}
              noValueLabel={`${t("none", { context: 'female' })} ${t("school").toLowerCase()}`}
              label={t("school")}
            />}
          </ModifiableSelectField>

          <ModifiableSelectField
            noBorder={true}
            Field={SelectSchoolYear}
            label={modify && t("school-year")}
            modify={modify}
            disabledClear
            value={student.school_year}
            setValue={(year) => execute({ ni, schoolYear: year && year.slug })} >
            {(val) => <Info.Field value={val && val.name}
              noValueLabel={`${t("none")} ${t("school-year").toLowerCase()}`}
              label={t("school-year")}
            />}
          </ModifiableSelectField>

          <ModifiableSelectField
            noBorder={true}
            Field={SelectSchoolProfile}
            label={modify && t("school-profile")}
            modify={modify}
            disabledClear
            value={student.school_profile}
            setValue={(profile) => execute({ ni, schoolProfile: profile && profile.slug })} >
            {(val) => <Info.Field value={val && val.name}
              noValueLabel={`${t("none")} ${t("school-profile").toLowerCase()}`}
              label={t("school-profile")}
            />}
          </ModifiableSelectField>

        
        <ModifiableSelectField
          noBorder={true}
          Field={SelectMotivation}
          label={modify && t("motivation.label")}
          modify={modify}
          disabledClear
          value={student.motivation}
          setValue={(motivation) => execute({ ni, motivation: motivation && motivation.value })} >
        </ModifiableSelectField>
      </FieldsSection>

      <FieldsSection title={t('section-family')} description={t('section-family-description')} >
        <ModifiableSelectField
          noBorder={true}
          Field={SearchCountry}
          label={modify && t("country-born")}
          modify={modify}
          disabledClear
          value={student.country_born}
          setValue={(country) => execute({ ni, countryBorn: country && country.name })} >
          {(val) => <Info.Field value={val && val.name}
            noValueLabel={`${t("none")} ${t("country-born").toLowerCase()}`}
            label={t("country-born")}
          />}
        </ModifiableSelectField>
        <ModifiableSelectField
          noBorder={true}
          Field={SearchCountry}
          label={modify && t("parent-country-born")}
          modify={modify}
          disabledClear
          value={student.parent_country_born}
          setValue={(country) => execute({ ni, parentCountryBorn: country && country.name })} >
          {(val) => <Info.Field value={val && val.name}
            noValueLabel={`${t("none")} ${t("parent-country-born").toLowerCase()}`}
            label={t("parent-country-born")}
          />}
        </ModifiableSelectField>
        <ModifiableSelectField
          noBorder={true}
          Field={SelectBool}
          label={modify && t("parent-motivation")}
          modify={modify}
          disabledClear
          Icon={FiSmile}
          value={student.parent_motivation}
          setValue={(parentMotivation) => execute({ ni, parentMotivation: parentMotivation && parentMotivation.value })} >
          {(val) => <Info.Field value={val}
            noValueLabel={`${t("none")} ${t("parent-motivation").toLowerCase()}`}
            label={t("parent-motivation")}
          />}
        </ModifiableSelectField>
        <ModifiableSelectField
          noBorder={true}
          Field={CheckBox}
          label={t("francization")}
          modify={true}
          value={student.francization}
          setValue={(francization) => { execute({ ni, francization }) }} />

      </FieldsSection>

      <FieldsSection title={t('section-participation')} description={t('section-participation-description')} >
      <ModifiableSelectField
          noBorder={true}
          Field={CheckBox}
          label={t("participate-to-hc")}
          modify={true}
          value={student.participate_to_hc}
          setValue={(participateToHc) => { execute({ ni, participateToHc }) }} />

        <ModifiableSelectField
          noBorder={true}
          Field={SelectDate}
          label={modify && t("started-using-service")}
          modify={modify}
          value={student.started_using_service && dateParse(student.started_using_service)}
          setValue={(startedUsingService) => execute({ ni, startedUsingService: startedUsingService || null })} >
          {(val) => <Info.Field value={val && locale.format("%d %B %Y")(val)}
            noValueLabel={`${t("none")} ${t("started-using-service").toLowerCase()}`}
            label={t("started-using-service")}
          />}
        </ModifiableSelectField>
        <ModifiableSelectField
          noBorder={true}
          Field={SelectDate}
          label={modify && t("ended-using-service")}
          modify={false}
          value={student.ended_using_service && dateParse(student.ended_using_service)}
          setValue={(endedUsingService) => { execute({ ni, endedUsingService: endedUsingService || null }); setStudent(d => ({ ...d, ended_using_service: endedUsingService })) }} >
          {(val) => <Info.Field value={val && locale.format("%d %B %Y")(val)}
            noValueLabel={`${t("none", { context: 'female' })} ${t("ended-using-service").toLowerCase()}`}
            label={t("ended-using-service")}
          />}
        </ModifiableSelectField>
        
        <div className='pt-6'>
          <SingleLineField
            label={modify && t("reason-for-end-service")}
            modify={student.ended_using_service}
            value={student.reason_for_end}
            multiLine={true}
            setValue={(reasonForEnd) => execute({ ni, reasonForEnd })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("none", { context: 'female' })} ${t("reason-for-end-service").toLowerCase()}`} />}
          </SingleLineField>
        </div>

      </FieldsSection>

      <FieldsSection title={t('section-needs')} description={t('section-needs-description')} >
      <SingleLineField
            label={modify && t("work-hours")}
            description={t("work-hours-description")}
            modify={modify}
            value={student.work_hours}
            setValue={(workHours) => execute({ ni, workHours })}>
            {(val) => <Info.Field value={val} noValueLabel={t("no-work-hours")} />}
          </SingleLineField>
        <div className="pt-6">
          <SingleLineField
            label={modify && t("future-dreams")}
            modify={true}
            value={student.future_dreams}
            multiLine={true}
            setValue={(futureDreams) => execute({ ni, futureDreams })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("no-future-dreams")}`} />}
          </SingleLineField>

        </div>
        <div className="pt-6">
          <SingleLineField
            label={modify && t("particular-needs")}
            modify={modify}
            value={student.particular_needs}
            multiLine={true}
            setValue={(particularNeeds) => execute({ ni, particularNeeds })}>
            {(val) => <Info.Field value={val} noValueLabel={`${t("none")} ${t("particular-needs").toLowerCase()}`} />}
          </SingleLineField>
        </div>

      </FieldsSection>
    </Info.Container>


    <Info.Container modify={true} className="py-12">
      <DeleteButton ni={student.ni} />
    </Info.Container>

  </div>
}

export function StudentSettingsSiblings({ student, setStudent }) {
  const modify = true;
  const { t } = useTranslation('common');

  if (!student.name) {
    return <Skeleton.List numElements={5} />
  }
  return <div className="min-h-screen md:min-h-0">
    <h1 className="info-section">{t('siblings')}</h1>
    <ModifiableMultipleSelectField
      modify={modify}
      value={student.siblings}
      setValue={(siblings) => setStudent(d => ({ ...d, siblings }))}
      Field={NewSibling}
      fieldProps={{ sourceStudent: student }}
      onDelete={d => deleteSibling({ id: d.id })}
      indexField={"id"}>
      {(values) =>
        values && values.length > 0 ?
          values.map(val =>
            <Info.Field key={val.id}
              value={val.target.name}
              to={`/students/${val.target.ni}`}
              label={val.description}
            />) :
          <Info.Container><Info.Field noValueLabel={`${t("none", { context: 'female' })} ${t("siblings").toLowerCase()}`} /></Info.Container>
      }
    </ModifiableMultipleSelectField>
  </div>
}


export function StudentSettingsTeams({ student, setStudent }) {
  const modify = true;
  const { t } = useTranslation('common');

  if (!student.name) {
    return <Skeleton.List numElements={5} />
  }
  return <div className="min-h-screen md:min-h-0">
    <h1 className="info-section">{t('teams')}</h1>
    <ModifiableMultipleSelectField
      modify={modify}
      value={student.teams}
      setValue={(teams) => setStudent(d => ({ ...d, teams }))}
      Field={NewTeamMember}
      fieldProps={{ defaultStudent: student }}
      onDelete={d => deleteTeamMember({ id: d.id })}
      indexField={"id"}>
      {(values) =>
        values && values.length > 0 ?
          values.map(val =>
            <Info.Field key={val.id}
              value={`${val.team.name} ${val.team.active_period}`}
              to={`/teams/${val.team.slug}`}
              label={val.description}
            />) :
          <Info.Container><Info.Field noValueLabel={t('empty-state.no-teams-title')} /></Info.Container>
      }
    </ModifiableMultipleSelectField>


  </div>
}


export function StudentCloseSettings() {
  const { t } = useTranslation('common');
  const { student, setStudent } = useContext(StudentContext);
  const { updateStudent: setUpdatedStudent } = useContext(StudentsContext);
  const [settings, setSettings] = useState({ ni: student.ni, reason: t("settings-students.close.reason-of-end-default"), endedAt: new Date() });
  const [result, { execute, loading, error }] = useAPI(closeStudent,
    settings, { onResult: value => {setStudent(value); setUpdatedStudent(value)}, immediate: false })

  const studentIsClosed = student && student.ended_using_service && new Date(student.ended_using_service) < new Date();
  useEffect(() => {
    if (result) {
      toast.success(t("settings-students.close.success"), { duration: 3000 })
    }
  }, [result])

  return <div>
    {studentIsClosed == true && <TextErrorStructured title={t("settings-students.close.already-closed")} />}
    <InfoSectionHeading mediumTitle={t("settings-students.close.title")} longDescription={t("settings-students.close.description")} />

    <SelectSettings value={settings} setValue={setSettings} />
    <div className={classnames("rounded-lg p-3 w-full space-y-3 border-2 border-red-500 mt-6")}>
      <div>
        <h3 className="font-medium text-red-600">{t("settings-students.close.title")}</h3>
        <p className="text-gray-500">{t("settings-students.close.summary.warning")}</p>
      </div>
      <Button loading={loading} onClick={execute} disabled={!settings.endedAt || !settings.reason || studentIsClosed} block color="deleteContrast" size="lg">{studentIsClosed ? t("settings-students.close.already-closed-short") : t("settings-students.close.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}

export function StudentOpenSettings() {

  const { t } = useTranslation('common');
  const { student, setStudent } = useContext(StudentContext);
  const { updateStudent: setUpdatedStudent } = useContext(StudentsContext);
  const [period, setPeriod] = useState(getFullCurrentPeriod());
  const settings = useMemo(()=>({ni: student.ni, period}), [student, period]);
  const [result, { execute, loading, error }] = useAPI(openStudent,
    settings, { onResult: (value) => {setStudent(value); setUpdatedStudent(value)}, immediate: false })
  const modify = true;
  const studentIsClosed = student && student.ended_using_service && new Date(student.ended_using_service) < new Date();
  
  useEffect(() => {
    if (result) {
      toast.success(t("settings-students.open.success"), { duration: 3000 })
    }
  }, [result])

  return <div>
    {!studentIsClosed && <TextErrorStructured title={t("settings-students.open.already-opened")} />}
    <InfoSectionHeading mediumTitle={t("settings-students.open.title")} longDescription={t("settings-students.open.description")} />
    <ModifiableSelectField Field={SelectPeriods} label={modify && t("settings-students.open.periods")} modify={modify} setValue={setPeriod} value={period} />
    <div className={classnames("rounded-lg p-3 w-full space-y-3 border-2 border-red-500 mt-6")}>
      <div>
        <h3 className="font-medium text-red-600">{t("settings-students.open.title")}</h3>
        <p className="text-gray-500">{t("settings-students.open.summary.warning")}</p>
      </div>
      <Button loading={loading} onClick={execute} disabled={!studentIsClosed || !period} block color="deleteContrast" size="lg">{!studentIsClosed ? t("settings-students.open.already-opened-short") : t("settings-students.open.button")}</Button>
      {error ? <Error.Text {...error} /> : null}
    </div>
  </div>
}