import React from 'react';

// API
import { createPairing, draftPairing } from 'api/pairings';

// Hooks
import { useAPI } from 'hooks/useAPI';
import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
 
// Components
import { Info } from 'components/core/info';
import { CheckBox } from 'components/tools/checkbox';
import { Button } from 'components/core/button';
import { SearchStudents } from 'components/tools/student_search';
import { SearchTutors } from 'components/tools/search_tutor';
import { SelectCronPeriod } from 'components/tools/select_cron_period';
import { ModifiableSelectField } from 'components/tools/modifiable_fields';
import { SelectPairingType } from 'components/tools/select_pairing_type';
import { FiCheck, FiAlertTriangle, FiUsers, FiWifi, FiHelpCircle } from 'react-icons/fi';
import { Error, InfoSectionHeading, RawTextWarning } from 'components/core/typo'
import { FieldsSection } from 'components/core/fields-section';
import { SelectSchoolSubjectsDropdown } from 'components/tools/select_school_subjects';
import { Tooltip } from 'components/core/tooltip';
import { EmptyState } from 'components/core/empty';
import classnames from 'classnames';

function CommonAvailability({ common_availability }){
  const { t } = useTranslation('common');
  const [showDetails, setShowDetails] = useState(false);
  const correct = common_availability && common_availability.length>0;
  if (!common_availability) return <div/>;
  return <div >
         <div className="flex items-center justify-between gap-x-3">
          {correct?
                  <div className="flex items-center space-x-2 text-gray-700"><FiCheck className="mr-2 h-5 w-5 flex-shrink-0 text-green-500"/> {t("common-availabilities", {dispo: common_availability.map(d=>`${d.from}`).join(', ')})}</div>:
                  <div className="flex items-center space-x-2 text-yellow-500"><FiAlertTriangle className="mr-2 h-5 w-5 flex-shrink-0"/> {t("no-common-availabilities")}</div>
            }
            {!showDetails && <div className="ml-auto"><Button size="xs" color="default" onClick={()=>setShowDetails(true)}>{t("show-more")}</Button></div>}
          </div>
          {showDetails && 
            <div className="bg-white divide-y rounded-lg">
              {correct?
                  common_availability.map((d,i)=><Info.Field key={i} value={`${d.from} au ${d.to}`}/>):
                    <Info.Field noValueLabel={t("no-common-availabilities")}/>}
            </div>
          }
        </div>
}

function CommonSubjects({ student, tutor, common_subjects }){
  const { t } = useTranslation('common');
  const [showDetails, setShowDetails] = useState(false);
  const correct = common_subjects && common_subjects.filter(d=>d.tutor_preference.description!==null && d.student_preference.description!==null).length>0;
  if (!common_subjects) return <div/>;
  return <div>
         <div className="flex items-center justify-between gap-3">
          {correct?
                  <div className="flex items-center space-x-2 text-gray-700"><FiCheck className="mr-2 h-5 w-5 flex-shrink-0 text-green-500"/> {t("common-subjects")}</div>:
                  <div className="flex items-center space-x-2 text-red-600"><FiAlertTriangle className="mr-2 h-5 w-5 flex-shrink-0"/> {t("no-common-subjects")}</div>
            }
            {!showDetails && <div className="ml-auto"><Button size="xs" color="default" onClick={()=>setShowDetails(true)}>{t("show-more")}</Button></div>}
          </div>
          {showDetails && 
            <div className="bg-white divide-y rounded-lg">
              {correct?
                  common_subjects.map((d,i)=> <Info.Field key={i} value={d.name}>
                      <div className={classnames(d.student_preference.description!==null && d.tutor_preference.description!==null? "text-green-500" :"text-gray-500")}>
                        <div>{student.name}: <span className="text-gray-800 font-medium">{t("priority."+d.student_preference.description)}</span></div>
                        <div>{tutor.name}: <span className="text-gray-800 font-medium">{t("subject-description."+d.tutor_preference.description)}</span></div>
                      </div>
                    </Info.Field>):
                    <Info.Field noValueLabel={t("no-common-subjects")}/>}
            </div>
          }
        </div>
}

function SchoolPreference({ tutor, school_preferences }){
  const { t } = useTranslation('common');
  const [showDetails, setShowDetails] = useState(false);
  const correct = school_preferences && ((school_preferences.description==="dispo")||(school_preferences.description==="prioritaire"))
  if (!school_preferences) return <div/>;
  return <div >
         <div className="flex items-center justify-between gap-3">
          {correct?
                  <div className="flex items-center space-x-2 text-gray-700"><FiCheck className="mr-2 h-5 w-5 flex-shrink-0 text-green-500"/> 
                  {t("school-fit-tutor",{school: school_preferences.name, dispo: t("availability."+school_preferences.description)})}</div>:
                  <div className="flex items-center space-x-2 text-red-600"><FiAlertTriangle className="mr-2 h-5 w-5 flex-shrink-0"/> 
                  {t("school-doesnt-fit-tutor", {school: school_preferences.name, dispo: t("availability."+school_preferences.description)})}</div>
            }
            {!showDetails && <div className="ml-auto"><Button size="xs" color="default" onClick={()=>setShowDetails(true)}>{t("show-more")}</Button></div>}
          </div>
          {showDetails && 
            <div className="bg-white divide-y rounded-lg">
                <p>{tutor.name}: <span className="text-gray-800 font-medium">{t("availability."+school_preferences.description)}</span></p>
            </div>
          } 
        </div>
}

function SimilarType({ tutor, student }){
  const { t } = useTranslation('common');
  const [showDetails, setShowDetails] = useState(false);
  const correct = (tutor && student) && ((student.on_site_preference && tutor.on_site_preference)  ||
  (student.hybrid_preference) || 
  (tutor.hybrid_preference) ||
  (student.online_preference && tutor.online_preference))

  const format = !correct? null: (student.on_site_preference? t('on-site'): student.online_preference? t('online'): t('hybrid'));
  if (!tutor || !student) return <div/>;
  return <div>
         <div className="flex items-center justify-between gap-3">
          {correct?
                  <div className="flex items-center space-x-2 text-gray-700"><FiCheck className="mr-2 h-5 w-5 flex-shrink-0 text-green-500"/> {t("student-type-preference-fit-tutor", {format})}</div>:
                  <div className="flex items-center space-x-2 text-red-600"><FiAlertTriangle className="mr-2 h-5 w-5 flex-shrink-0"/> {t("student-type-preference-doesnt-fit-tutor")}</div>
            }
            {!showDetails && <div className="ml-auto"><Button size="xs" color="default" onClick={()=>setShowDetails(true)}>{t("show-more")}</Button></div>}
          </div>
          {showDetails && 
             <div className="flex items-center space-x-6">
                  <div className="bg-white rounded-lg p-3 flex-1">
                    <p>{student.name}</p>
                    <div>
                      {student.on_site_preference&& <div className="flex items-center text-sm space-x-2 text-gray-700"><FiUsers/> <p>{t("on-site")}</p></div>}
                      {student.online_preference&& <div className="flex items-center text-sm space-x-2 text-green-600"><FiWifi/> <p>{t("online")}</p></div>}
                      {student.hybrid_preference&& <div className="flex items-center text-sm space-x-2 text-pink-600"><FiUsers/><FiWifi/> <p>{t("hybrid")}</p></div>}
                      {!student.hybrid_preference&& !student.online_preference && !student.on_site_preference &&
                        <div className="flex items-center text-sm space-x-2 text-gray-500"><p>{t("no-preference")}</p></div>}
                    </div>
                  </div>
                  <div className="bg-white rounded-lg p-3 flex-1">
                    <p>{tutor.name}</p>
                    <div>
                      {tutor.on_site_preference&& <div className="flex items-center text-sm space-x-2 text-gray-700"><FiUsers/> <p>{t("on-site")}</p></div>}
                      {tutor.online_preference&& <div className="flex items-center text-sm space-x-2 text-green-600"><FiWifi/> <p>{t("online")}</p></div>}
                      {tutor.hybrid_preference&& <div className="flex items-center text-sm space-x-2 text-pink-600"><FiUsers/><FiWifi/> <p>{t("hybrid")}</p></div>}
                      {!tutor.hybrid_preference&& !tutor.online_preference && !tutor.on_site_preference &&
                        <div className="flex items-center text-sm space-x-2 text-gray-500"><p>{t("no-preference")}</p></div>}
                    </div>
                  </div>
                </div>
          } 
        </div>
}

function DraftPairingCell({common_availability, common_subjects, school_preferences, student, tutor}){
  return <>
          <CommonAvailability common_availability={common_availability}/>
          <CommonSubjects common_subjects={common_subjects} student={student} tutor={tutor}/>
          <SchoolPreference school_preferences={school_preferences}  tutor={tutor}/>
          <SimilarType tutor={tutor} student={student}/>
         </>
}

function TerminateCurrentPairings({warnings, 
                                    closeCurrentStudentPairing,
                                    setCloseCurrentStudentPairing,
                                    closeCurrentTutorPairing,
                                    setCloseCurrentTutorPairing,}){
  const { t } = useTranslation('common');
  if (!warnings) return <div/>;
  if (warnings.length===0) return <div/>;

  return <div className="space-y-3">
          {warnings.map(d=>
            <div className="divide-y-0" key={d.name}>
                <RawTextWarning>{t(`${d.name}.title`, {student:d.payload.student.name, tutor:d.payload.tutor.name})}</RawTextWarning>
               {d.name==="tutor-is-already-paired" && <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("tutor-is-already-paired.description", {tutor:d.payload.tutor.name})}
                    modify={true}
                    marker="select"
                    value={closeCurrentTutorPairing} 
                    setValue={setCloseCurrentTutorPairing}/>}
            {d.name==="student-is-already-paired" && <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("student-is-already-paired.description", {student:d.payload.student.name})} 
                    marker="select"
                    modify={true}
                    value={closeCurrentStudentPairing} 
                    setValue={setCloseCurrentStudentPairing}/>}
           </div>)
         }
        </div>
}

export function NewPairing({ defaultStudent, defaultTutor }){
  const { t } = useTranslation('common');
  const [student, setStudent] = useState(defaultStudent);
  const [tutor, setTutor] = useState(defaultTutor);
  const [studentConfirmed, setStudentConfirmed] = useState(false);
  const [preferedType, setPreferedType] = useState();
  const [period, setPeriod] = useState();
  const [subjects, setSubjects] = useState([]);
  const [tutorConfirmed, setTutorConfirmed] = useState(false);
  const [closeCurrentStudentPairing, setCloseCurrentStudentPairing] = useState(true);
  const [closeCurrentTutorPairing, setCloseCurrentTutorPairing] = useState(true);

  const pairingParams = useMemo(()=>({student: student &&student.ni, 
    tutor: tutor && tutor.ni, 
    preferedType: preferedType && preferedType.code,
    period,
    closeCurrentTutorPairing,
    closeCurrentStudentPairing,
    subjects,
    studentConfirmed, 
    tutorConfirmed}), 
  [student, tutor, preferedType, period, studentConfirmed, closeCurrentTutorPairing, closeCurrentStudentPairing, subjects, tutorConfirmed]);
  const [draft, draftRequest] = useAPI(draftPairing, pairingParams, {immediate:false});
  const [pairing, {loading, error, execute}] = useAPI(createPairing, pairingParams, {immediate:false});

  useEffect(()=>{
    if (pairing){location.reload()}
  }, [pairing]);

  useEffect(()=>{
    if (student && tutor){
      draftRequest.execute();
    }
  }, [student, tutor]);

  return <div className="h-full max-h-[96vh] overflow-y-auto relative ">
          <div className='sticky top-0 bg-gray-100 border-b z-10 pt-6 pb-3 px-6'>
            <InfoSectionHeading mediumTitle={t("new-pairing")}/>
          <Info.Container className="">
              <div className="md:flex md:space-x-3 items-center">
                <div className="flex-1">
                  <SearchStudents label={t("student")} size="md" value={student} setValue={setStudent} disabled={defaultStudent} color="default"/>
                </div>
                <div className="flex-1">
                  <SearchTutors label={t("tutor")} 
                  description={t("select-tutor-available-for-pairing-description")}
                  size="md" 
                  defaultParams={{readyForPairing: true}}
                  value={tutor} 
                  setValue={setTutor} 
                  disabled={defaultTutor} 
                  color="white"/>
                </div>
              </div>
          </Info.Container>
          </div>
          <div className='px-6 pb-6'>
            {!draft && <div className='pt-12'><EmptyState title={t("new-pairing-draft")}/></div>}
          {draft &&  <FieldsSection title={t("pairing-fit.title")} description={t("pairing-fit.description")} contentClassName="space-y-3" className="!py-3 mt-3">
          <DraftPairingCell {...draft}/>
            </FieldsSection>}
        
          {draft &&
            <div className="">
              <FieldsSection title={t("pairing-confirmation.title")} description={t("pairing-confirmation.description")} contentClassName="space-y-3" className="!py-3 mt-8">
                <ModifiableSelectField 
                    Field={CheckBox} 
                    mainClassName="!py-0"
                    label={t("student-has-confirmed-pairing")} 
                    modify={true}
                    marker="select"
                    value={studentConfirmed} 
                    setValue={setStudentConfirmed}/>
                <ModifiableSelectField 
                    Field={CheckBox} 
                    label={t("tutor-has-confirmed-pairing")} 
                    marker="select"
                    noBorder={true}
                    modify={true}
                    value={tutorConfirmed} 
                    setValue={setTutorConfirmed}/>
                </FieldsSection>
                <FieldsSection title={t("pairing-preferences.title")} description={t("pairing-preferences.description")} contentClassName="space-y-3" className="!py-3 mt-6">
                <ModifiableSelectField 
                    Field={SelectCronPeriod} 
                    label={t("weekly-period")} 
                    modify={true}
                    value={period} 
                    setValue={setPeriod}/>
                <ModifiableSelectField 
                    Field={SelectPairingType} 
                    label={<span className='flex space-x-1 items-center'><Tooltip position="left" color="light" content={<div className="flex  items-center space-x-6 pb-0">
                    <div className="text-sm rounded-lg py-1 px-3 flex-1">
                      <p>{draft.student.name}</p>
                      <div>
                        {draft.student.on_site_preference&& <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-gray-700"><FiUsers/> <p>{t("on-site")}</p></div>}
                        {draft.student.online_preference&& <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-green-600"><FiWifi/> <p>{t("online")}</p></div>}
                        {draft.student.hybrid_preference&& <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-pink-600"><FiUsers/><FiWifi/> <p>{t("hybrid")}</p></div>}
                        {!draft.student.hybrid_preference&& !draft.student.online_preference && !draft.student.on_site_preference &&
                          <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-gray-500"><p>{t("no-preference")}</p></div>}
                      </div>
                    </div>
                    <div className="text-sm  rounded-lg py-1 px-3 flex-1">
                      <p>{draft.tutor.name}</p>
                      <div>
                        {draft.tutor.on_site_preference&& <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-gray-700"><FiUsers/> <p>{t("on-site")}</p></div>}
                        {draft.tutor.online_preference&& <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-green-600"><FiWifi/> <p>{t("online")}</p></div>}
                        {draft.tutor.hybrid_preference&& <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-pink-600"><FiUsers/><FiWifi/> <p>{t("hybrid")}</p></div>}
                        {!draft.tutor.hybrid_preference&& !draft.tutor.online_preference && !draft.tutor.on_site_preference &&
                          <div className="flex items-center whitespace-nowrap text-sm space-x-2 text-gray-500"><p>{t("no-preference")}</p></div>}
                      </div>
                    </div>
                  </div>}><span className='relative inline'><FiHelpCircle/></span>
                  
                  </Tooltip><span className='inline'>{t("pairing-type")}</span></span>} 
                    removeHybrid={false}
                    modify={true}
                    value={preferedType} 
                    setValue={setPreferedType}/>
                {/*Display commun pairing*/}
                

                {/* Schools subjects */}
                <ModifiableSelectField 
                    Field={SelectSchoolSubjectsDropdown} 
                    label={t("pairing-subjects")} 
                    modify={true}
                    block={true}
                    noBorder={true}
                    value={subjects} 
                    setValue={setSubjects}/>
                        
              </FieldsSection>

            </div>
          }
              
            {draft &&  <FieldsSection title={t("pairing-existing.title")} description={t("pairing-existing.description")} contentClassName="space-y-3" className="!py-6"><TerminateCurrentPairings {...draft}
                closeCurrentStudentPairing={closeCurrentStudentPairing}
                setCloseCurrentStudentPairing={setCloseCurrentStudentPairing}
                closeCurrentTutorPairing={closeCurrentTutorPairing}
                setCloseCurrentTutorPairing={setCloseCurrentTutorPairing}
/></FieldsSection>}


          {draft&& <Button block size="lg" color="active" disabled={!student || !tutor || !preferedType} loading={loading} onClick={execute}>{t("create-pairing")}</Button>}
          {error? <Error.Text {...error}/>: null}
          </div>
        </div>
}