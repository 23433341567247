import React, { useState, useContext, useMemo } from 'react';

// Hooks
import { useSearch } from 'hooks/useSearch';
import { useDebounce } from 'hooks/useDebounce';
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';
import { orderWeekdays } from 'utils/locale';

// API
import { searchStudents } from 'api/students';

// Components
import { Command } from 'components/core/command';
import { Button } from 'components/core/button';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { Error } from 'components/core/typo';
import { FiTrash, FiUsers, FiWifi } from 'react-icons/fi';
import { getCurrentPeriod } from 'utils/period';
import { SearchContextProvider, SearchContext } from 'contexts/search';
import { FilterBar } from 'components/tools/filter_bar';

function PreferenceCell({on_site_preference, online_preference, hybrid_preference}){
    const { t } = useTranslation("autoPairing");
  
    return <div className="whitespace-nowrap">
            {on_site_preference&&
             <p className="flex items-center text-sm space-x-2 text-gray-700"><FiUsers/> <span>{t("preferences.onsite")}</span></p>}
            {online_preference&& 
              <p className="flex items-center text-sm space-x-2 text-green-600"><FiWifi/> <span>{t("preferences.online")}</span></p>}
            {hybrid_preference&& 
              <p className="flex items-center text-sm space-x-2 text-pink-600"><FiUsers/><FiWifi/> <span>{t("preferences.hybrid")}</span></p>}
            </div>
}

function StudentOption({student, active, selected}){

    const activeTeams = useMemo(()=>{
        if (!student.teams) return null;
        const currentPeriod = getCurrentPeriod()
        return student.teams.filter(d=>d.team.active_period===currentPeriod);
    }, [student]);
    
    const availabilities = useMemo(()=>{
        const weekdays = student.schedule_availabilities.map(s=>`${s.from.split("à")[0]}`.replace(" ", ""));
        // Get uniques
        const uniqueWeekDays = [...new Set(weekdays)];
        // Sort weekdays
        const sortedWeekDays = orderWeekdays(uniqueWeekDays)
        return sortedWeekDays.join(', ');

    }, [student]);
      
    return  <div className='grid grid-cols-4 gap-x-2 w-full'>
                <div className='col-span-1'>
                    <span className={classnames(selected &&"font-semibold")}>{student.name}</span>
                    <span className={classnames(!active&& 'opacity-80')}><PreferenceCell {...student}/></span>
                </div>
                <div className=' col-span-1'>
                    <p className={classnames(!active&& 'opacity-80')}>{(activeTeams || []).map(d=>(d.team.name)).join(', ')}</p>
                </div>
                <div className='col-span-1'>
                    <p className={classnames(!active&& 'opacity-80')}>{availabilities}</p>
                </div>
                <div className='col-span-1'>
                    <p className={classnames(!active&& 'opacity-80')}>
                        {(student.prefered_subjects || []).filter(e=>e.description).map(e=>`${e.name}`).join(', ')}
                    </p>
                </div>
            </div>
}

  
export function SearchStudentsCommandWithContext({ disabled, values, setValues }) {
    const { t } = useTranslation("autoPairing");
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState("");
    const {filters} = useContext(SearchContext);
    const debounceQuery = useDebounce(query, 500);

    const params = useMemo(() => ({ search: query, validForPairing: true, ...filters}), [debounceQuery, filters]);
    const fields = useMemo(() => (["ni", 
                                "name", 
                                "schedule_availabilities", 
                                "online_preference", 
                                "on_site_preference", 
                                "hybrid_preference",
                            "prefered_subjects"]), []);

    const [students, { loading, error }] = useSearch(searchStudents, params, { limit: 1000, fields});

    return <>
        <Button disabled={disabled} layout="lgBlock" className={"whitespace-nowrap"} size="md" color="gray" onClick={() => setOpen(d => !d)}><MagnifyingGlassIcon className='w-4 h-4 mr-2 text-gray-500 inline' />{t("search-students.label")}</Button>
            <Command.Root open={open} setOpen={setOpen} size="7xl">
            <Command.Search 
                setQuery={setQuery} 
                loading={loading} 
                value={values} 
                placeholder={t("search-students.placeholder")}
                onChange={setValues} 
                multiple by={(a,b)=>a.ni===b.ni}>
                {error ? <Error.Text {...error} /> : null}
                <FilterBar period school team availableForPairing preset color="default" disabledPeriod>
                    <Button color="gray" size="sm" onClick={()=>setValues(students)}>
                        {t("search-students.select-all")}
                    </Button>
                    <Button color="delete" disabled={!values || values.length===0} size="toolbarIcon" onClick={()=>setValues([])}>
                        <FiTrash />
                    </Button>
                </FilterBar>
                <Command.Options static className="text-sm text-gray-800 " size="lg">
                    {/* No results */}
                    {query !== '' && (students || []).length === 0 && (
                        <p className="p-4 text-sm text-gray-500">{t("search-students.no-results")}</p>
                    )}

                    {/* Show results */}
                    {(students || []).map((student) => (
                        <Command.Option key={student.ni} value={student}>
                            {({ active, selected }) => (
                                <StudentOption student={student} active={active} selected={selected}/>
                            )}
                        </Command.Option>
                    ))}
                </Command.Options>
                <Command.Help>{t("search-students.help")}</Command.Help>
            </Command.Search>
        </Command.Root>
    </>
}

export function SearchStudentsCommand({...props}){
    return <SearchContextProvider doNotStore={true} autoPeriod={true}>
                <SearchStudentsCommandWithContext {...props}/>
            </SearchContextProvider>
}