import { fetchCall } from 'api';
import { isString } from 'utils/var_check';

/*
  Create an event
*/  
export const createEvent = ({date, team, eventType, excludedDates, visitingTeam, receivingTeam, numMatches, evaluationPeriod, notes}) => {
  const options = {
    method: "POST", 
    endpoint: "/events",
    body: {date, 
          team, 
          eventType, 
          visitingTeam, 
          receivingTeam,
          numMatches, 
          fromDateCalculation: evaluationPeriod && evaluationPeriod.fromDate, 
          toDateCalculation: evaluationPeriod && evaluationPeriod.toDate,
          excludedDates, 
          notes}
  }
  return fetchCall(options)
};


/*
  Create a bulk events
*/  
export const createBulkEvent = ({fromDate, toDate, period, team, eventType}) => {
  const options = {
    method: "POST", 
    endpoint: "/bulk-events",
    body: {fromDate, toDate, period, team, eventType}
  }
  return fetchCall(options)
};

/*
  Update an event
*/  
export const updateEvent = ({id, date, transmitted, comment, team, excludedDates, eventType, visitingTeam, numMatches, receivingTeam, evaluationPeriod, notes}) => {
  const options = {
    method: "POST", 
    endpoint: `/events/${id}`,
    body: {date: date && date.toISOString(), 
          team, 
          eventType, 
          visitingTeam, 
          receivingTeam,
          numMatches,
          excludedDates, 
          comment, 
          transmitted,
          fromDateCalculation: evaluationPeriod && evaluationPeriod.fromDate, 
          toDateCalculation: evaluationPeriod && evaluationPeriod.toDate, 
          notes}
  }
  return fetchCall(options)
};

/*
  Delete an event
*/  
export const deleteEvent = ({id}) => {
  const options = {
    method: "DELETE", 
    endpoint: `/events/${id}`
  }
  return fetchCall(options)
};

/* Transmit Play time */
export const transmitPlayTime = ({id, coachNis}) => {
  const options = {
    method: "POST", 
    endpoint: `/events/${id}/transmit-play-time`,
    body: {coachNis}
  }
  return fetchCall(options)
}

/* Cancel Play time */
export const cancelPlayTime = ({id}) => {
  const options = {
    method: "POST", 
    endpoint: `/events/${id}/cancel-transmit-play-time`
  }
  return fetchCall(options)
}

/*
  Retrieve an event
*/  
export const retrieveEvent = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/events/${id}`
  }
  return fetchCall(options)
};

/*
  Retrieve play time
*/  
export const computePlayTime = ({id}) => {
  const options = {
    method: "GET", 
    endpoint: `/events/${id}/play-time`
  }
  return fetchCall(options)
};


/*
  Set play time description
*/  
export const setPlayTimeDescription = ({id, student, matchIdx, description}) => {
  const options = {
    method: "POST", 
    endpoint: `/events/${id}/play-time/${student}`,
    body: {description, matchIdx}
  }
  return fetchCall(options)
};

/**Get conversations*/
export const getEventConversation = ({id}) => {
  const options = {
    method: "GET",
    endpoint: `/events/${id}/conversations`,
  };
  return fetchCall(options);
}

/*
  Search events
*/  
export const searchEvents = ({team, preset, eventType, excludePractice,  page, limit, orderBy, orderDirection, fromDate, toDate}) => {
  const options = {
    method: "GET", 
    endpoint: `/events`,
    query: {fromDate: fromDate && new Date(fromDate).toISOString(), 
            toDate: toDate && new Date(toDate).toISOString(), 
            team: team && (isString(team)? team: team.slug), 
            preset: preset && (isString(preset)? preset: preset.id), 
            excludePractice,
            eventType,
            page, 
            limit, 
            orderBy, 
            orderDirection,}
  }
  return fetchCall(options)
};

