/* eslint-disable */
import React, { useMemo, useState } from "react"

// Components
import { Button } from "components/core/button"
import { Skeleton } from "components/core/skeleton"
import { Dropdown } from "components/core/dropdown"
import { CreateNoteInModal } from 'components/notes/create_modal';
import { TeamContext } from "contexts/team"

// Icons
import { MdOutlineHistory as HistoryIcon } from "react-icons/md";
import { FiPlus as AddIcon } from "react-icons/fi";

// Utils
import classNames from "classnames"
import { recursiveCamelize } from "utils/casing";

// Hooks
import { useStudentsMeetings } from "contexts/student-meetings"
import { useTranslation } from "react-i18next"
import { useContext } from "react"

function getDaysSinceLastMeeting(lastMeeting) {
    const today = new Date();
    today.setHours(23, 59, 59, 999);

    if (lastMeeting && lastMeeting.openedAt) {
        const lastMeetingDate = new Date(lastMeeting.openedAt);

        const differenceInTime = today - lastMeetingDate;
        const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

        return Math.floor(differenceInDays);
    }
    return undefined;
}

export function MeetingsCount({ student, meetings, loading }) {
    if (loading) return <></>
    return <>

        <div className="flex gap-1 items-center">
            <MeetingsAddAndHistory student={student} meetings={meetings} loading={loading} />
            <div className="text-base">
                {
                    !loading && meetings?.length || 0
                }
            </div>
        </div>

    </>
}

function MeetingsAddAndHistory({ student, meetings, loading }) {
    const sortedMeetings = meetings?.sort((a, b) => new Date(b.openedAt) - new Date(a.openedAt))
    const lastMeeting = sortedMeetings?.[0]
    const daysSinceLastMeeting = getDaysSinceLastMeeting(lastMeeting)
    const [openAddMeeting, setOpenAddMeeting] = useState(false)
    const { setStudents } = useStudentsMeetings();
    const { team, loading: loadingTeam } = useContext(TeamContext);

    const { start, end } = useMemo(() => {
        const v = recursiveCamelize(team);
        return { start: v?.activePeriod?.start, end: v?.activePeriod?.end }
    }, [team])

    const handleCreateMeeting = (note) => {
        const openedAt = new Date(note?.openedAt);
        if (openedAt < new Date(start) || openedAt > new Date(end)) {
            return;
        }
        setStudents(prev =>
            prev.map(s => {
                if (s.ni === student.ni) {
                    const updatedMeetings = [...(s.meetings || []), note];
                    return {
                        ...s,
                        meetings: updatedMeetings
                    };
                }
                return s;
            })
        );
    };
    if (loading || loadingTeam) return <></>
    return <div className="flex gap-1 items-center">
        <Button size={"icon"} color={"default"} className={"!p-1.5 !text-base"} onClick={() => setOpenAddMeeting(true)} ><AddIcon /></Button>
        <MeetingsDropdown student={student} meetings={sortedMeetings} loading={loading} daysSinceLastMeeting={daysSinceLastMeeting} />
        <CreateNoteInModal
            open={openAddMeeting}
            setOpen={setOpenAddMeeting}
            creatorProps={
                {
                    defaultStudent: student,
                    defaultCode: { code: "rencontre-ea-entraineur", name: "Rencontre ÉA-Coordo" },
                    onResult: handleCreateMeeting
                }
            }
        />
    </div>
}


export function Meetings({ meetings, loading, className }) {
    const { t } = useTranslation();
    const sortedMeetings = meetings?.sort((a, b) => new Date(b.openedAt) - new Date(a.openedAt))
    const lastMeeting = sortedMeetings?.[0]
    const lastMeetingMinutes = lastMeeting?.minutes
    const daysSinceLastMeeting = getDaysSinceLastMeeting(lastMeeting)

    if (loading) return <div className={classNames(className)}></div>
    return <div className={classNames(className)}>
        <div className="flex gap-1 items-center">
            <div className="text-base flex gap-1 items-center">
                <Button color={lastMeeting ? "hiddenLink" : "none"} href={lastMeeting ? `?noteId=${lastMeeting?.id}` : null}>
                    {
                        daysSinceLastMeeting === undefined ?
                            <><span className="text-gray-400 @[700px]:block hidden cursor-default">-</span><span className="text-gray-500 @[700px]:hidden">-</span></> :
                            daysSinceLastMeeting === 0 ? <span className="">{t("today")}</span> :
                                daysSinceLastMeeting === 1 ? <span className="">{t("yesterday")}</span> :
                                    <div className="flex gap-1">
                                        <span className="@[700px]:block hidden"></span>
                                        <span> {daysSinceLastMeeting} {t("days").toLowerCase()}</span>
                                    </div>

                    }
                </Button>
                {
                    lastMeetingMinutes && <span className="text-gray-500 text-sm">{`(${lastMeetingMinutes}min)`}</span>
                }
            </div>
        </div>

    </div>
}

function DropDownButton() {

    return <div className="flex gap-1 items-center">
        <HistoryIcon />
    </div>
}

export function MeetingsDropdown({ meetings, loading }) {
    const { t } = useTranslation();
    const totalMinutes = meetings?.reduce((acc, meeting) => acc + (meeting?.minutes || 0), 0)
    return <>
        <Dropdown vOrientation="up" menuItemsClassName="!-translate-y-[32px] bottom-0" itemClassName="w-[180px]" iconClassName="!p-1 !rounded-md" onlyIcon={<DropDownButton />}>
            <Dropdown.Info>
                {
                    meetings && meetings.length > 0 && <div className="text-gray-500">{t("meetings")}</div>
                }
                {
                    loading && <>
                        <Skeleton className="h-7" />
                        <Skeleton className="h-7" />
                        <Skeleton className="h-7" />
                    </>
                }
                {
                    !loading && (!meetings || meetings?.length === 0) && <div className="text-gray-500 text-sm">{t("no-meetings")}</div>
                }
                <div className="flex flex-col">
                    {
                        !loading && meetings?.map((meeting, i) => {
                            const formattedDate = new Date(meeting.openedAt).toLocaleDateString()
                            const minutes = meeting?.minutes
                            return <div key={i} className="text-gray-500 flex justify-between">
                                <Button color={"hiddenLink"} href={`?noteId=${meeting?.id}`} className="text-sm text-gray-500">{formattedDate}</Button>{minutes && <span className="">{`${minutes}min`}</span>}
                            </div>
                        })
                    }
                    {
                        !loading && meetings?.length > 0 && <div className="border-t border-black mt-1 pt-1 flex justify-between">
                            <div>{meetings?.length} {meetings?.length > 1 ? t("meetings").toLowerCase() : t("meeting").toLowerCase()}</div>
                            <div>{totalMinutes}min </div>
                        </div>
                    }
                </div>
            </Dropdown.Info>
        </Dropdown>
    </>
}