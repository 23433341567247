import React, { useMemo} from 'react';

// Components
import { Button } from 'components/core/button';
import { Error, RawTextInfo } from 'components/core/typo';
import { PreferenceCell } from './pairings';
import { Tooltip } from 'components/core/tooltip';

// API
import { testOptimization } from 'api/auto-pairing';

// Hooks
import { useTranslation } from 'react-i18next';
import { useAPI } from 'hooks/useAPI';
import { Table } from 'components/core/table';

import { cronsIntervalToString } from 'utils/crons';
import { Stats } from 'components/core/stats';
import { format } from 'd3';
import { FiInfo } from 'react-icons/fi';

function QualityView({quality}){
  return <div>
          {quality.map((d,i)=><p key={i}>{d}</p>)}
        </div>
}
export function QualityCell({quality, total_quality}){
  if (!quality) return <span/>
  return <Tooltip color="light" position="left" delay="100" content={<QualityView quality={quality}/>}>
          <span  className="flex items-center space-x-2 whitespace-nowrap my-1 relative">
            <span>{total_quality}</span>
            <FiInfo className="bg-gray-100 p-0.5 h-6 w-6 rounded text-blue-500"/>
          </span>
        </Tooltip>
}

export function TestAutoPairing({value}){
    const {t} = useTranslation("autoPairing");
    const disabled = useMemo(()=>!value.students || !value.tutors, [value]);
    const [result,{ execute, loading, error}] = useAPI(testOptimization, value, {immediate:false});
    
    const headers = useMemo(()=>[
        {
          title:"",
          field: (d=>d? d : "prioritary_for_tutoring"),
          FormatComponent: (d=>d.student.prioritary_for_tutoring? <span className='text-blue-500 bg-blue-100 px-2 py-0.5 rounded-md text-sm '>{'Prio.'}</span>: ''),
        },
        {
          title:"",
          field: (d=>d? d : "triade"),
          FormatComponent: (d=>d.triade? <span className='text-orange-500 bg-orange-100 px-2 py-0.5 rounded-md text-sm '>{'Triade'}</span>: ''),
          itemClassName: ""
        },
        {
          title: t("quality"),
          field: (d=>d? d : "quality"),
          format: (d=>d? d.total_quality: '-'),
          FormatComponent: QualityCell,
          itemClassName: "font-medium whitespace-nowrap "
        },
          {
            title: t("student.title"),
            field: (d=>d? d.student.name : "student"),
            itemClassName: "font-medium whitespace-nowrap "
          },
          {
            title: t("tutor"),
            field: (d=>d? d.tutor.name : "student"),
            itemClassName: "font-medium whitespace-nowrap "
          },
          {
            title: t("matched-subjects.title"),
            field: (d=>d?d.matched_subjects: "matched_subjects"),
            itemClassName: " py-1 max-w-md text-gray-500",
            className: "px-2",
            format: (d=>d.filter(s=>s.student_preference.description).map(s=>`${s.name} (${t("matched-subjects.tutor-"+s.tutor_preference.description)}/ ${t("matched-subjects.student-"+s.student_preference.description)})`).join(', '))
          },
          {
            title: t("matched-schedules.title"),
            field: (d=>d?d.matched_schedule: "matched_schedule"),
            itemClassName: " py-1 max-w-md text-gray-500",
            className: "px-2",
            format: (d=>d.map(s=>`${cronsIntervalToString(s)}`).join(', '))
          },
          {
            title: t("preferences.title"),
            field: (d=>d?d: "preferences"),
            itemClassName: " py-1 max-w-md text-gray-500",
            className: "px-2",
            format:(d=>((d.student.on_site_preference &&d.tutor.on_site_preference)? t("preferences.onsite"):
              (d.student.online_preference &&d.tutor.online_preference)? t("preferences.online"):
              (d.student.hybrid_preference &&d.tutor.hybrid_preference)? t("preferences.hybrid"):
              (d.student.hybrid_preference &&d.tutor.on_site_preference)? t("preferences.onsite"):
              (d.student.hybrid_preference &&d.tutor.online_preference)? t("preferences.online"):
              (d.tutor.hybrid_preference &&d.student.on_site_preference)? t("preferences.onsite"):
              (d.tutor.hybrid_preference &&d.student.online_preference)? t("preferences.online"):
              t("preferences.undefined")
              )),
            FormatComponent: PreferenceCell
          },
        ], []);

    return <>
            <RawTextInfo className={"mb-8"}>
                <span className='inline'>{t("new-settings.test-optim.label")}</span>
                <br/>
                <Button loading={loading} className="" color="info" size="md" disabled={disabled} onClick={()=>execute()}>{t("new-settings.test-optim.button")}</Button>
                {error && <Error.Text {...error}/>}
            </RawTextInfo>
            {result?
                <>
                    <Stats.Container className="mb-8 grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6">
                        <Stats.Element name={t("new-settings.stats.pairings")} value={result.pairings.length}/>
                        <Stats.Element name={t("new-settings.stats.students")} value={result.num_students}/>
                        <Stats.Element name={t("new-settings.stats.tutors")} value={result.num_tutors}/>
                        <Stats.Element name={t("new-settings.stats.success-rate")} unit="%" value={parseFloat(result.num_students)?
                                                                        format(".4")(100*parseFloat(result.pairings.length)/parseFloat(result.num_students)): "- %"}/>
                        <Stats.Element name={t("new-settings.stats.unmatched-students")} value={result.num_unmatched_students}/>
                        <Stats.Element name={t("new-settings.stats.unmatched-tutors")} value={result.num_unmatched_tutors}/>
                    </Stats.Container>
                    <Table headers={headers} 
                    data={result.pairings}/>
                </>:null}
           </>
}