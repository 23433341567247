import React, { useEffect } from 'react';
import classnames from 'classnames';
import { Button, colors, sizes } from 'components/core/button'
import { useTranslation } from 'react-i18next';
import { FiX, FiAlertTriangle, FiAlertCircle as ErrorIcon, FiInfo } from 'react-icons/fi';
import toast from 'react-hot-toast';

export function TextError({code, detail, payload, to, button, className}){
  const { t } = useTranslation(["errors"])

  useEffect(()=>{
    if(code){
      toast.error(t(code || detail, {...payload}), {duration: 5000})
    }
  }, [code])

  return <p className={classnames("text-red-600 bg-red-50 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2"/>{t(code || detail, {...payload})}
          {to && <Button href={to} size={"padLink"} color="errorLink">{button}</Button>}
        </p>
}

export function TextWarning({code, detail, payload, className}){
  const { t } = useTranslation(["errors"])
  return <p className={classnames("text-yellow-700 bg-yellow-100 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2"/>{t(code || detail, {...payload})}</p>
}

export function RawTextWarning({children, className}){
  return <p className={classnames("text-yellow-700 bg-yellow-100 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2 !h-4 !w-4 aspect-square"/>{children}</p>
}
export function RawTexError({children, className}){
  return <p className={classnames("text-red-700 bg-red-100 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2 !h-4 !w-4 aspect-square"/>{children}</p>
}
export function RawTextInfo({children, title, className, size="sm"}){
  return <p className={classnames("text-blue-900 bg-blue-50 rounded-md p-3", size==="sm"? "text-sm": "", className)}>
    <FiInfo className="inline mr-2"/>
      {title && <span className='font-medium'>{title}</span>}
      {children}
    </p>
}

export function TextWarningStructured({title, description, to, button, className}){
  return <p className={classnames(" bg-yellow-100 rounded-md p-3  flex ", className)}>
    <FiAlertTriangle className="inline w-8 mt-1 mr-2 text-yellow-900"/>
    <span className='block'>
      <span className="font-medium text-yellow-900 ">{title}</span>
      <span className="block text-yellow-700">{description}</span>
    {button && to &&<Button href={to} color="warningLink" size="link">{button}</Button>}
      </span>
  </p>
}

export function TextErrorStructured({title, description, to, button, className}){
  return <p className={classnames(" bg-red-100 rounded-md p-3  flex ", className)}>
    <ErrorIcon className="inline w-8 mt-1 mr-2 text-red-900"/>
    <span className='block'>
      <span className="font-medium text-red-900 ">{title}</span>
      <span className="block text-red-700">{description}</span>
    {button && to &&<Button href={to} color="warningLink" size="link">{button}</Button>}
      </span>
  </p>
}

export function RawTextSuccess({children, className}){
  return <p className={classnames("text-green-700 bg-green-50 rounded-md p-3 text-sm", className)}><FiAlertTriangle className="inline mr-2"/>{children}</p>
}

export function DetailError({title, description, withReturn, children, className}){
  const { t } = useTranslation('common');
  return <div className={classnames("text-gray-600 text-sm text-center", className)}>
          <h3 className="mt-2 text-lg text-gray-400">{title || t('general_error_title')}</h3>
          <div className="max-w-xl mx-auto mt-1 text-sm text-center text-gray-400">{description}</div>
          {withReturn?
              <Button href="/" color="gray" size="lg" block className="mt-4">
                {t('return_home')}
              </Button>:null
          }
          {children}
         </div>
}

export function Badge({name, onDelete, color, size, className, ...props}){
  return <span className={classnames("inline-flex items-center space-x-2 mr-2 mb-1", colors[color], sizes[size], className)}>{name}<Button onClick={onDelete} {...props}><FiX/></Button></span>
}

export function InfoSectionHeading({ title, mediumTitle, smallTitle, subtitle, description, noMargin, longDescription, children, className }){
  return <div className={classnames("md:flex items-center justify-between", !noMargin && "py-3", className)}>
            <div>
              {title&&<h2 className="text-3xl font-medium text-gray-900">{title}</h2>}
              {mediumTitle&&<h2 className="text-2xl font-medium text-gray-800 ">{mediumTitle}</h2>}
              {smallTitle&&<h2 className="text-lg font-medium text-gray-800 ">{smallTitle}</h2>}
              {subtitle&&<h3 className="text-lg font-medium text-orange-600">{subtitle}</h3>}
              {description?
                <p className="max-w-md mt-0 text-sm text-gray-500">
                  {description}
                </p>:null}
              {longDescription?
                <p className="max-w-2xl mt-0 text-gray-500">
                  {longDescription}
                </p>:null}
            </div>
            <div >
              {children}
            </div>
        </div>
}

export function CardSectionHeading({ title, description, children, className, noMargin }){
  return <div className={classnames(" md:flex items-center justify-between", !noMargin && "py-3", className)}>
            <div>
              {title&&<h2 className="text-2xl font-medium text-gray-900">{title}</h2>}
              {description?
                <p className="mt-0 text-sm text-gray-500">
                  {description}
                </p>:null}
            </div>
            <div >
              {children}
            </div>
        </div>
}


export function SectionHeading({ title, description, children, className }){
  return <div className={classnames("py-3 px-6 bg-gray-100 border-b md:flex items-center justify-between", className)}>
            <div>
              <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
              {description?
                <p className="mt-2 text-sm text-gray-500">
                  {description}
                </p>:null}
            </div>
            <div >
              {children}
            </div>
        </div>
}



export const Error = {Text: TextError, Detailed: DetailError};
