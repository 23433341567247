/*
  This list is intended for managing the document groups, not for selection.
*/

import React from 'react';

// Hooks
import { useTranslation } from 'react-i18next';
import { useMemo, useContext, useState } from 'react';
import { useSearch } from 'hooks/useSearch';

// Contexts
import { SearchContextProvider, SearchContext } from 'contexts/search';

// Components
import { Skeleton } from 'components/core/skeleton';
import { EmptyState } from 'components/core/empty';
import { Error } from 'components/core/typo'
import { Paginate } from 'components/core/paginate';
import { Button } from 'components/core/button';
import { Info } from 'components/core/info';
import { Table } from 'components/core/table';
import { Fragment } from 'react';
import { SlideOver } from 'components/core/slide_over';
import { CreateDocumentGroupPeriod } from 'components/document-group-periods/create';
// import { ModifyDocumentGroup } from 'components/document-groups/modify';

// API
import { searchDocumentGroupPeriods } from 'api/document_groups';
import { ModifyDocumentGroupPeriod } from './modify';

function CreateButton({onChange}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("add")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <CreateDocumentGroupPeriod onCompleted={()=>{setOpen(false); onChange()}}/>
          </SlideOver>
        </Fragment>
}

function ModifyButton({value, setValue, onDelete}){
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  return <Fragment>
          <Button onClick={()=>setOpen(true)} size="xs" color="gray">{t("modify")}</Button>
          <SlideOver open={open} setOpen={setOpen} size="xl">
            <ModifyDocumentGroupPeriod value={value} setValue={setValue} onDelete={()=>{setOpen(false); onDelete(value)}}/>
          </SlideOver>
        </Fragment>
}

export function ControlledDocumentGroupPeriodsList({groupPeriods, setGroupPeriods, setPage, paging, error, execute}){
  const { filters, dispatchFilters } = useContext(SearchContext);
  const { t } = useTranslation('common');
  const headers = useMemo(()=>[
    {
     title: t("id"),
     field: (d=>d? d.id : "id"),
     itemClassName: "!py-2 whitespace-nowrap "
    },
    {
     title: t("group"),
     field: (d=>d? d.group.name : "group"),
     itemClassName: "py-2 whitespace-nowrap"
    },
    {
     title: t("period"),
     field: (d=>d? d.period.name : "period"),
     itemClassName: "py-2 whitespace-nowrap"
    },
    {
      title: t("school"),
      field: (d=>d? d.school.short_name : "school"),
      itemClassName: "py-2 whitespace-nowrap"
     },
     {
       title: t("should_be_received_at"),
       field: (d=>d? d.should_be_received_at : "should_be_received_at"),
       itemClassName: "py-2 whitespace-nowrap"
      },
    {
     field: (d=>d? ({value:d, onDelete:((d=>setGroupPeriods(current=>current.filter(e=>e.code!==d.code)))), setValue: ((next)=>setGroupPeriods(current=>current.map(e=>e.id===next.id? next:e)))}) : "Modifier"),
     FormatComponent: ModifyButton
    },
  ], [groupPeriods])

  if (!groupPeriods){
    return <Skeleton.List numElements={1} itemClassName="h-8" className="space-y-2"/>
  }

  return <Info.Container modify={true}  sideHeader={<Fragment><CreateButton onChange={execute}/></Fragment>}>
            {groupPeriods.length===0?
              <EmptyState
                className="bg-gray-100 rounded-md"
                title={t("empty-state.no-documents-codes-title")}/>
              :
              <Table headers={headers}
                    data={groupPeriods}
                    indexingKey="code"
                    order={{by:filters.orderBy, direction:filters.orderDirection}}
                    onHeaderClick={(value)=>dispatchFilters({type: 'ordering', value})}/>
              }
              {paging&& <Paginate setPage={setPage} {...paging}/>}
              {error? <Error.Text {...error}/>: null}
          </Info.Container>
}

function DocumentGroupPeriodsListWithContext(){
  const { filters } = useContext(SearchContext);
  const [groupPeriods, {loading, error, paging, setPage, setResult, execute}] = useSearch(searchDocumentGroupPeriods, filters);
  return <ControlledDocumentGroupPeriodsList groupPeriods={groupPeriods} setGroupPeriods={setResult} setPage={setPage} loading={loading} error={error} paging={paging} execute={execute}/>
}

export function DocumentGroupPeriodsList(){
  return <SearchContextProvider doNotStore defaultState={{orderBy:"period", orderDirection: "asc"}}>
            <DocumentGroupPeriodsListWithContext/>
        </SearchContextProvider>
}