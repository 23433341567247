import React from 'react';

// Components
import { FiCalendar } from 'react-icons/fi';

// Hooks
import { useTranslation } from 'react-i18next';
import { MeetingDatesList } from 'components/meeting-dates/list';

function SettingsMettingDates(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('meeting-dates')}</h1>
            <p className='mb-3 text-base text-gray-500'>{t('meeting-date-description')}</p>
            <MeetingDatesList/>
          </div>
}
const route =  { path: "meeting-dates", 
                 name: "Comptabilisation des rencontres" , 
                 group: "data",
                 Icon: FiCalendar,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "RSI", "Développeur"],
                 Element: SettingsMettingDates };
export default route;
