import React from 'react';

// Components
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FiCheck } from 'react-icons/fi';


// Hooks
import { useTranslation } from 'react-i18next';

// Utils
import classnames from 'classnames';

const styles = {
  default: {
    button: 'bg-gray-100 disabled:bg-gray-50 disabled:opacity-70 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-200 sm:px-3 py-2 px-2',
    iconActive: 'text-gray-900 ',
    iconInactive: 'text-gray-300 ',
  },
  red: {
    button: 'bg-red-100 disabled:bg-gray-50 disabled:opacity-70 text-sm font-medium text-red-500 whitespace-nowrap hover:bg-red-200 sm:px-3 py-2 px-2',
    iconActive: 'text-red-500 ',
    iconInactive: 'text-red-300 ',
  },
  green: {
    button: 'bg-gray-100 disabled:bg-gray-50 disabled:opacity-70 text-sm font-medium text-green-500 whitespace-nowrap hover:bg-gray-200 sm:px-3 py-2 px-2',
    iconActive: 'text-green-500 ',
    iconInactive: 'text-green-500 ',
  },
  side: {
    button: 'bg-gray-100 disabled:bg-gray-50 disabled:opacity-70 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3 py-2.5 px-2',
    iconActive: 'text-gray-900 ',
    iconInactive: 'text-gray-300 ',
  },
  white: {
    button: 'bg-white shadow disabled:bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3 py-2 px-2',
    iconActive: 'text-gray-900 ',
    iconInactive: 'text-gray-300 ',
  }
}
export function ListSelection({ value, 
                                setValue, 
                                disabled, 
                                values, 
                                label, 
                                Icon, 
                                getKey, 
                                hideSelectedValue,
                                format, 
                                formatSelection, 
                                formatClassName,
                                selectionClassName,
                                optionClassName,
                                optionsClassName,
                                itemDescription,
                                children,
                                left,
                                badge,
                                info,
                                multiple,
                                emptyView,
                                withClearSelection,
                                color='default'}){
  const { t } = useTranslation("common");

  return <Listbox as="div" value={value} onChange={setValue} className="flex-shrink-0" disabled={disabled} multiple={multiple}>
            {({ open }) => (
              <>
                <Listbox.Label className="sr-only">{label}</Listbox.Label>
                <div className="md:relative">
                  <Listbox.Button className={classnames("md:relative inline-flex items-center rounded-md ", styles[color].button)}>
                    {Icon!=null? 
                      value == null ? (
                        <Icon className={classnames("flex-shrink-0 h-5 w-5 sm:-ml-1", styles[color].iconInactive)} aria-hidden="true" />
                      ) : (
                        <Icon className={classnames("flex-shrink-0 h-5 w-5 sm:-ml-1", styles[color].iconActive)} aria-hidden="true"  />
                      ): null}

                    <span
                      className={classnames(
                        value == null ? '' : 'text-gray-900',
                        'truncate hidden md:inline',
                        hideSelectedValue && "hidden",
                        Icon!=null && "ml-2",
                        selectionClassName
                      )}
                    >
                      {value == null ? label : formatSelection?formatSelection(value):format(value)}
                    </span>
                    {badge?<span className="absolute top-0 right-0 translate-x-1/2 -translate-y-1/2 bg-red-500 text-xs text-white px-1 py-0.5 rounded-lg">{badge}</span>: null}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className={classnames("absolute z-10 mt-1 w-full min-w-max max-w-screen bg-white shadow max-h-56 rounded-lg text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm", left? " left-0": " right-0", optionsClassName)}>
                      {withClearSelection && value && <Listbox.Option
                          className={({ active }) =>
                            classnames(
                              active ? 'bg-gray-100' : 'bg-white',
                              'cursor-default select-none relative py-2 px-3 text-red-600',
                              optionClassName

                            )
                          }
                          value={null}
                        >
                          <div className="flex items-center">
                            <span className="block font-medium truncate">{t('remove-selection')}</span>
                          </div>

                        </Listbox.Option>}

                       {values && values.length===0 && <Listbox.Option className={() =>
                            classnames(
                              'bg-white ',
                              'cursor-default select-none relative py-2 px-3 ',
                              optionClassName
                            )
                          }
                          disabled={true}>
                            {emptyView? emptyView: t("nothing-found")}
                          </Listbox.Option>
                        }
                      {(values||[]).map((d) => 
                        (<Listbox.Option
                          key={getKey(d)}
                          className={({ active, selected }) =>
                            classnames(
                              active ? 'bg-gray-100' : 'bg-white ',
                              'cursor-default select-none relative py-2 px-3 ',
                              selected && "font-medium",
                              optionClassName
                            )
                          }
                          value={d}
                        >
                        {({selected})=>
                          
                            children?
                              children(d):
                              <div className="flex items-center justify-between">
                                <div className="">
                                  <p className={classnames("block font-medium truncate", formatClassName && formatClassName(d))}>{format(d)}</p>
                                  {d&& d.description && <span className="inline-flex text-gray-500 truncate">{d.description}</span>}
                                  {d&& d.longDescription && <p className="block max-w-xs text-gray-500 ">{d.longDescription}</p>}
                                  {itemDescription && <div className="block text-gray-500 max-w-xs">{itemDescription(d)}</div>}
                                </div>
                                <div className=" h-4 w-4 flex items-center justify-center">
                                  {selected ?<FiCheck className="text-lg text-orange-500"/>: null}
                                </div>
                              </div>
                        }
                          
                        </Listbox.Option>
                      ))}

                    {info&&<div className='flex items-center px-3 py-3 text-sm border-t max-w-xs bg-gray-50 text-gray-500'>{info}</div>}

                      
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
}
