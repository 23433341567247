import React from "react";

// Components
import { Pill } from "components/core/pill";
import { Dropdown } from "components/core/dropdown";
import { Button } from "components/core/button";

// Utils
import classNames from "classnames";

// Hooks
import { useTranslation } from "react-i18next";

export function Asa({ asa, loading, className}) {
    const { t } = useTranslation();
    if (loading || !asa) return <div className={className}></div>
    return <div className={classNames(className,"")}>
       <Dropdown 
        vOrientation="up" 
        menuItemsClassName="!-translate-y-[32px] translate-x-[30px] bottom-0 right-full" 
        itemClassName="w-[200px]" 
        customButton={
        <Pill.Empty color="green" className="px-2">
            {asa?.length}
        </Pill.Empty>} > 
            <Dropdown.Info>
                <div className="text-gray-500">{t("asa")}</div>
                <div className="flex flex-col gap-1 text-gray-500">
                    {
                        asa?.map((asa, i) => {
                            const formattedDate = new Date(asa?.createdAt).toLocaleDateString()
                            return <Button key={i} color="hiddenLink" href={`?noteId=${asa?.id}`}>
                                <div className="text-sm flex gap-1">{formattedDate}</div>
                            </Button>
                        })
                    }
                </div>
            </Dropdown.Info>
        </Dropdown>
    </div>
}