import React from 'react';

// Utils
import classnames from 'classnames';

import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Tooltip } from './tooltip';

function TableHeader({ title, field, info, className, order, onHeaderClick, active }){
  const _field = (typeof field==='function')? field(): field;
  return (<th
            scope="col"
            className={classnames("py-3 text-left text-xs uppercase tracking-wider justify-items-end ",
              order &&order.by===_field?"font-bold text-gray-700": "font-medium text-gray-500 ", className)}
          >
            <span onClick={onHeaderClick? (()=>onHeaderClick(_field)):null}
                className={classnames(((order &&order.by===_field) || (active)) && "bg-gray-200", onHeaderClick? "text-orange-600 cursor-pointer hover:bg-gray-200 rounded-md px-1.5 py-1": "")}>
                {info?<Tooltip color="light" position="right" delay="0" content={<span className='normal-case'>{info}</span>}>
        <span className='relative '>{title} </span>
        </Tooltip>: title
        }
                {!order? null:
                  order.by!==_field? null:
                  order.direction==="desc"?
                      <span className="ml-2"><FiChevronDown className="inline-flex p-0.5 text-xl bg-gray-200 text-gray-500 rounded-full"/></span>:
                      <span className="ml-2"><FiChevronUp className="inline-flex p-0.5 text-xl bg-gray-200 text-gray-500 rounded-full"/></span>}
            </span>
          </th>)
}

function TableRow({headers, row, onClick, className}){
  return <tr onClick={onClick? (()=>onClick(row)): null} className={classnames(onClick? "cursor-pointer hover:bg-gray-100": "", className)}>
          {headers.map(({field, headerId, title, itemClassName, style, format, FormatComponent, parseNull, placeholder, onClick})=>{
            const value = (typeof field==='function')? field(row): row[field];
            return <td
                key={headerId || title}
                onClick={onClick? (()=>onClick(value)): null}
                className={classnames("px-2 py-0", onClick && "cursor-pointer hover:underline", (typeof itemClassName==='function')? itemClassName(value): itemClassName)}
                style={(typeof style==='function')? style(value): style}>
              {(parseNull || value!=null)? (FormatComponent? <FormatComponent {...value} />: format? format(value): value): (placeholder || "")}
            </td>}
          )}
          </tr>
}

export function Table({ data, 
                      headers, 
                      tableClassName, 
                      headerRowClassName, 
                      bodyClassName, 
                      repeatHeaders,
                      onHeaderClick, 
                      onRowClick, 
                      rowClassName, 
                      order, 
                      indexingKey, 
                      rowProps }){
  return <table className={classnames("min-w-full divide-y divide-gray-200 table-auto", tableClassName)}>
          <thead className={classnames(headerRowClassName, "")}>
            <tr>
              {headers.map(d=><TableHeader {...d} key={d.headerId || d.title} order={order} onHeaderClick={onHeaderClick || d.onHeaderClick}/>)}
            </tr>
          </thead>
          <tbody className={classnames("divide-y", bodyClassName)}>
            {!data? null:
              data.map((d, index)=>{
                return <TableRow className={ (typeof rowClassName==='function')? rowClassName(d): rowClassName}
                            key={(typeof indexingKey==='function')? indexingKey(d, index): d[indexingKey] }
                            headers={headers}
                            row={rowProps? {...d, ...rowProps, rowIndex: index}:d}
                            onClick={onRowClick}/>
              })}
               
          </tbody>
          {repeatHeaders &&
          <tfoot className={classnames(headerRowClassName, "")}>
          <tr>
            {headers.map(d=><TableHeader {...d} key={d.headerId || d.title} order={order} onHeaderClick={onHeaderClick}/>)}
          </tr>
        </tfoot>}
        </table>
}