import React from 'react';

// Components
import { TutorInfo, TutorAvailabilities} from 'components/tutors/info';
import { TutorPairingsList } from 'components/tutors/pairings';
import { TutoringGrid } from 'components/tutoring-follow-up/tutoring_grid';
import { FullFlexNav } from 'components/core/layouts/flex';
import { Container } from 'components/core/container';

// Hooks
import { useContext } from 'react';

// Contexts
import { TutorContext } from 'contexts/tutor';

export default function Overview(){
  const { tutor, loading} = useContext(TutorContext);
  return <div className="py-6">
          <Container>
              <TutoringGrid studyCode="tutorat" numWeeks={4} tutor={tutor} showTotalInCards={true}/>
          </Container>
          <FullFlexNav className="p-6 block xl:flex">
          <FullFlexNav.Bar className="flex-shrink-0">
            {tutor && <TutorInfo tutor={loading? {}: tutor}/>}
          </FullFlexNav.Bar>
          <FullFlexNav.Content>
                  <TutorPairingsList/>
            {tutor && <TutorAvailabilities tutor={loading? {}: tutor}/>}
           
          </FullFlexNav.Content>
         </FullFlexNav>
         </div>

}
