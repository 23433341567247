import React from 'react';

// Components
import { FiFileText } from 'react-icons/fi';
import { DocumentGroupsList } from 'components/document-groups/list';
import { DocumentGroupPeriodsList } from 'components/document-group-periods/list';

// Hooks
import { useTranslation } from 'react-i18next';

function SettingsDocumentGroups(){
  const {t} = useTranslation("common");
  
  return <div className="h-full min-h-screen md:min-h-0 "> 
            <h1 className="info-section">{t('document-groups')}</h1>
            <DocumentGroupsList/>
            <h1 className="info-section mt-12">{t('document-groups-periods')}</h1>
            <p className="text-gray-500 mb-3">{t('document-groups-periods-description')}</p>
            <DocumentGroupPeriodsList/>
          </div>
}
const route =  { path: "document-groups", 
                 name: "Types de document" , 
                 group: "data",
                 Icon: FiFileText,
                 requireAuth: true, 
                 scopes: ["Direction", "RST", "RSI", "Développeur"],
                 Element: SettingsDocumentGroups };
export default route;
